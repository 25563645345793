import React, { useState, useEffect, useCallback, FC, useRef } from 'react';
import { useApi, IApi, useAuth, useDeviceConfig, useApiLoader } from 'utils/API';
import { useTranslations } from 'utils/language';
import { Tab, UncontrolledTabs } from 'components/uncontrolled_tabs';
import Model from 'components/objects/model';
import Location from 'components/objects/location';
import PropertyList from 'components/property_list';
import { IAuthenticatedUser, IBinLocation, IDefaultBinLocation, IItem, IItemMovementHistoryEntry, IItemOrder, ManagedByValues } from 'interfaces';
import Serial from '../serial';
import Batch from '../batch';
import Bool from 'components/Bool';
import { compiledUserCompoundItemBarcodeParser, useConfig } from 'utils/Config';
import Translation from 'utils/Language/Translation';
import Warehouse from '../warehouse';
import { Button, ButtonGroup, Col, Container, Input, Row } from 'reactstrap';
import { addNotification, useNotifications } from 'utils/NotificationManager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import SimpleModal from 'components/SimpleModal';
import { beTableAsComponent } from 'components/BeTable';
import ProcessingButton from 'components/ProcessingButton';
import { EBarcodeFields, IIItemDao$ICompanion$ISetDefaultBinLocation } from 'interfaces/erp-interfaces';
import { choiceList, prompt } from 'utils/Prompts';
import Date, { DATE, DATE_TIME } from 'components/Date';
import { hasBatch, IContentsLocationTreeLine, isBatch, isSerial, toLocationTree } from 'component_utils/itemLocationTree';
import ExpandedIndicator from 'components/ExpandedIndicator';
import { getLeftPaddingForLevel } from 'styling/stylingVars';
import ImageNotFound from './404.png'
import { saveBase64UrlEncode, sleep, useRerender } from 'component_utils/utils';
import If from 'components/If';
import { RiNumber1 } from 'react-icons/ri';
import { FaCopy, FaEdit } from 'react-icons/fa';
import serialSfx from 'audio/serialSound.mp3';
import { sumBy } from 'lodash';
import RoleProtected from 'components/RoleProtected';
import { FloatType, FormInput, FormObject, FormSelect, FormState, FormToggle, Labeled } from 'component_utils/FormUtils';
import PictureBox, { PictureBoxRef } from 'components/PictureBox';

interface ItemImageProps {
  obj: IItem | null;
  imgProps: any;
  openOnClick?: boolean;
}
export const ItemImage = ({ obj, openOnClick, imgProps = {} }: ItemImageProps) => {
  if (!obj) {
    return null;
  }

  const onErr: React.ReactEventHandler<HTMLImageElement> = (e) => {
    if (e.currentTarget.src !== ImageNotFound) {
      console.log('Image could not be loaded, falling back to placeholder');
      e.currentTarget.src = ImageNotFound;
    }
  };

  const onLoad: React.ReactEventHandler<HTMLImageElement> = (e) => {
    if (e.currentTarget.src !== ImageNotFound && e.currentTarget.width === 1 && e.currentTarget.height === 1) {
      console.log('Image was a 1x1 image, consider it not available');
      e.currentTarget.src = ImageNotFound;
    }
  };

  const imageSource = obj?.imagePath || `/api/v1/item/${saveBase64UrlEncode(obj.code)}/image`;
  return (
    <SimpleModal
      fullscreen
      title={<Translation name="T.misc.itemImage" />}
      trigger={(open) => (
        <img alt="item" src={imageSource} onLoad={onLoad} onError={onErr} onClick={openOnClick && open} {...imgProps} />
      )}
    >
      <img
        alt="item"
        src={imageSource}
        style={{
          height: '100%',
          width: '100%',
          objectFit: 'contain',
        }}
        onLoad={onLoad}
        onError={onErr}
      />
    </SimpleModal>
  );
};

const ItemImageSetter: FC<{ itemCode: string; onImageChanged: (imageUrl: string) => any; }> = ({ itemCode, onImageChanged }) => {
  const pictureBoxRef = useRef<PictureBoxRef>();
  const api = useApi()

  return (
    <>
      <PictureBox
        hideCurrentFilesOverview
        closeAfterOnPicture
        ref={pictureBoxRef}
        files={[]}
        onAddFiles={async (files) => {
          const { blob, extension } = files[0]
          const fileName = itemCode + '.' + extension
          const fileUrl = '/userdata/static/item_images/' + fileName

          await api.fileManagement.uploadFile({
            path: ['item_images', fileName],
            contents: blob
          })
          await api.item.updateItem(itemCode, {
            imagePath: fileUrl
          })
          try {
            await fetch(fileUrl, {cache: "reload"})
          } catch (e) {}
        }}
        onRemoveFile={() => {}}
      />
      <ProcessingButton 
        block
        color="primary"      
        onClick={() => pictureBoxRef.current.open()}
      >
        <Translation name='T.objects.item.setPicture' />
      </ProcessingButton>
    </>
  )
}

const UpdateManagedBy: FC<{ item: IItem }> = ({ item }) => {
  const [state, setState] = useState({} as any)
  const api = useApi()
  return (
    <SimpleModal
      title={<Translation name="T.objects.item.actions.updateManagedBy"/>}
      onOpen={() => {
        setState({
          managedBy: item.managedBy,
          newBatchName: "BE_CONV",
          newSerialPrefix: "BE_CONV_"
        })
      }}
      trigger={open => (
        <ProcessingButton onClick={open} color='primary' block>
          <Translation name="T.objects.item.actions.updateManagedBy"/>
        </ProcessingButton>  
      )}
      buttons={(close) => [
        <ProcessingButton color="secondary" onClick={close} data-testid="close-pdf-processor" key="close">
          <Translation name="T.misc.cancel" />
        </ProcessingButton>,
        <ProcessingButton
          color="primary"
          onClick={async () => {
            await api.item.setManagedBy(item.code, state.managedBy, state.newBatchName, state.newSerialPrefix)
            addNotification('success', <Translation name="T.objects.item.managedByConv.succesfull"/>)
            close();
          }}
          data-testid="parse-pdf-document"
          key="parse"
        >
          <Translation name="T.misc.ok" />
        </ProcessingButton>,
      ]}
    >
      <FormState state={state} setState={setState} disableFormWrapper>
        <Labeled title={<Translation name="T.objects.item.managedByConv.managedBy"/>}>
          <FormSelect
            path='managedBy'
            options={ManagedByValues.map(it => ({ label: it, value: it }))}
          />
        </Labeled>

        <Labeled title={<Translation name="T.objects.item.managedByConv.newBatchName"/>}>
          <FormInput path='newBatchName'/>
        </Labeled>

        <Labeled title={<Translation name="T.objects.item.managedByConv.newSerialPrefix"/>}>
          <FormInput path='newSerialPrefix'/>
        </Labeled>
      </FormState>
    </SimpleModal>
  )
}

const LocationsTable = beTableAsComponent<IContentsLocationTreeLine>({
  tableId: 'itemLocations',
  subRowsKeys: ['batches', 'serials'],
  showRowsWithoutChildren: true,
  columns: [
    {
      Header: <Translation name="T.objects.binLocation.binCode" />,
      value: (row) => row.location.visualCode,
      Cell: ({ row, level, isExpanded }) => {
        let inner = null;
        let hasChildren = false;
        if (level === 0) {
          inner = <Location obj={row.location} inline={true} />;
          hasChildren = row.batches.length > 0;
        } else if (level === 1) {
          const batch = row as unknown as IContentsLocationTreeLine['batches'][0];
          inner = batch.batch ? <Batch inline obj={batch.batch} /> : batch.batchCode;
          hasChildren = batch.serials.length > 0;
        } else if (level === 2) {
          const serial = row as unknown as IContentsLocationTreeLine['batches'][0]['serials'][0];
          inner = <Serial inline obj={serial.serial} />;
          hasChildren = false;
        }

        return (
          <div style={{ paddingLeft: getLeftPaddingForLevel(level) }}>
            <ExpandedIndicator isExpanded={isExpanded} disabled={!hasChildren} />
            {inner}
          </div>
        );
      },
      filterable: true,
      expander: true,
      width: 100,
      Footer: ({ rows }) => {
        return <Translation name="T.objects.contents.uniqueLocations" params={{ number: rows.length }}/>
      }
    },
    {
      Header: <Translation name="T.objects.contents.onHandQuantity" />,
      value: row => row.onHandQty,
      className: 'text-right',
      Footer: ({ rows }) => {
        return sumBy(rows, it => it.onHandQty)
      }
    },
    {
      Header: <Translation name="T.objects.contents.freeToMoveQuantity" />,
      value: row => row.freeToMoveQty,
      className: 'text-right',
      hideBelow: 'sm',
    },
    {
      Header: <Translation name="T.objects.contents.releasedQuantity" />,
      value: row => row.releasedQty,
      className: 'text-right',
      hideBelow: 'sm',
    },
    {
      Header: <Translation name="T.objects.contents.pickedQuantity" />,
      value: row => row.pickQty,
      className: 'text-right',
      hideBelow: 'sm',
    },
  ],
});

interface ExtraRenderProps {
  loadDefaultLocations: any;
  item: IItem;
}

interface ShowColumnProps {
  auth: IAuthenticatedUser;
}

const DefaultLocationsTable = beTableAsComponent<IDefaultBinLocation, ExtraRenderProps, ShowColumnProps>({
  tableId: 'defaultLocations',
  columns: [
    {
      Header: <Translation name="T.misc.warehouse" />,
      Cell: ({ row }) => {
        return <Warehouse inline obj={row.whs} />;
      },
    },
    {
      Header: <Translation name="T.misc.location" />,
      Cell: ({ row }) => {
        return <Location inline obj={row.binLocation} />;
      },
    },
    {
      Header: <Translation name="T.misc.enforced" />,
      hideBelow: 'sm',
      width: 20,
      className: 'text-center',
      value: (row) => (row.enforced ? 'Y' : 'N'),
      Cell: ({ row }) => {
        return <FontAwesomeIcon icon={row.enforced ? faLock : faLockOpen} />;
      },
    },
    {
      Header: <Translation name="T.misc.minStock" />,
      hideBelow: 'sm',
      width: 20,
      className: 'text-right',
      value: (row) => row.minStock,
    },
    {
      Header: <Translation name="T.misc.maxStock" />,
      hideBelow: 'sm',
      width: 20,
      className: 'text-right',
      value: (row) => row.maxStock,
    },
    {
      Header: <Translation name="T.misc.edit" />,
      width: 20,
      className: 'text-center',
      show: ({ auth }) => auth.expandedRoles.includes('defaultItemBinLocationEdit'),
      Cell: ({ row, loadDefaultLocations, item }) => {
        const notifications = useNotifications();
        const api = useApi();
        const [state, setState] = useState({} as (IIItemDao$ICompanion$ISetDefaultBinLocation & { locationCode: string }))
        return (
          <SimpleModal
            title={<Translation name="T.misc.editDefaultItemLocations" params={{ whs: row.whs.name }} />}
            fullscreen
            trigger={(show) => (
              <Button className="py-0" onClick={show} color="info">
                <Translation name="T.misc.edit" />
              </Button>
            )}
            onOpen={() => {
              setState({
                locationCode: row.binLocation?.code,
                location: row.binLocation,
                enforced: row.enforced,
                minStock: row.minStock,
                maxStock: row.maxStock,
              });
            }}
            buttons={(close) => [
              <ProcessingButton
                color="primary"
                onClick={async () => {
                  await api.item.setDefaultLocation(item.code, row.whs.code, state);
                  notifications.add('success', <Translation name="T.misc.success" />);
                  await loadDefaultLocations();
                  close();
                }}
                data-testid="btn-save"
              >
                <Translation name="T.misc.save" />
              </ProcessingButton>,
              <ProcessingButton color="secondary" onClick={close} data-testid="btn-close">
                <Translation name="T.misc.close" />
              </ProcessingButton>,
            ]}
          >
            <Container fluid>
              <FormState
                state={state}
                setState={setState}
                disableFormWrapper
              >
                <Labeled title={<Translation name="T.objects.defaultItemLocation.location" />}>
                  <FormObject<IBinLocation>
                    path="locationCode"
                    objectPath='location'
                    autocomplete={api.binLocation.findAutocomplete}
                    find={api.binLocation.findBinLocation}
                    getCode={useCallback(it => it.code, [])}
                    getEditValue={useCallback(it => it.visualCode, [])}
                    render={useCallback((it) => <Location inline obj={it} />, [])}
                  />
                </Labeled>

                <Labeled title={<Translation name="T.objects.defaultItemLocation.enforced" />}>
                  <FormToggle path="enforced" />
                </Labeled>

                <Labeled title={<Translation name="T.objects.defaultItemLocation.minStock" />}>
                  <FormInput
                    path="minStock"
                    typed={FloatType}
                  />
                </Labeled>

                <Labeled title={<Translation name="T.objects.defaultItemLocation.maxStock" />}>
                  <FormInput
                    path="maxStock"
                    typed={FloatType}
                  />
                </Labeled>
              </FormState>
            </Container>
          </SimpleModal>
        );
      },
    },
  ],
});

const OrdersTable = beTableAsComponent<IItemOrder>({
  tableId: 'itemOrdersTable',
  columns: [
    {
      Header: () => <Translation name="T.misc.orders" />,
      value: (row) => row.visualOrderCode,
    },
    {
      Header: () => <Translation name="T.misc.cardName" />,
      value: (row) => `${row.businessPartnerCode} - ${row.businessPartnerName}`,
    },
    {
      Header: () => <Translation name="T.misc.quantity" />,
      className: 'text-right',
      value: (row) => row.quantity,
    },
    {
      Header: () => <Translation name="T.misc.shipdate" />,
      Cell: ({ row }) => <Date format={DATE} val={row.shipdate} />,
      value: (row) => row.shipdate,
    },
  ],
});


const HistoryTable = beTableAsComponent<IItemMovementHistoryEntry>({
  tableId: 'itemHistoryTable',
  columns: [
    {
      id: 'type',
      Header: () => <Translation name="T.objects.item.history.transType" />,
      value: (row) => `${row.transactionType} (${row.documentCode})`,
      filterable: true,
      fullText: true,
    },
    {
      id: 'at',
      Header: () => <Translation name="T.objects.item.history.at" />,
      Cell: ({ row }) => <Date format={DATE_TIME} val={row.at} />,
      value: (row) => row.at,
      fullText: true,
    },
    {
      id: 'username',
      Header: () => <Translation name="T.objects.item.history.username" />,
      value: (row) => row.username,
      filterable: true,
    },
    {
      id: 'quantity',
      Header: () => <Translation name="T.objects.item.history.quantity" />,
      className: 'text-right',
      value: (row) => row.quantity,
    },
    {
      id: 'batchSerial',
      Header: () => <Translation name="T.objects.item.history.batchSerial" />,
      Cell: ({ row }) => {
        if (isBatch(row.item)) {
          return <Batch obj={row.batch} inline/>
        }
        if (isSerial(row.item)) {
          return <Serial obj={row.serialNumber} inline/>
        }
        return null
      },
      value: (row) => row.serialNumber?.visualCode ?? row.batch?.visualCode ?? "",
      filterable: true,
    },
    {
      id: 'bin',
      Header: () => <Translation name="T.objects.item.history.bin" />,
      Cell: ({ row }) => <Location obj={row.bin} inline />,
      value: (row) => row.bin.visualCode,
      filterable: true,
    },
  ],
});

interface Props {
  inline?: boolean;
  obj: IItem | null;
}

type DefaultLocationFilter = 'present' | 'active' | 'all';

export const getShortDescription = (item: IItem) => <Translation name="T.objects.item.shortDescription" params={(item as any) || {}} />

const Item = ({ obj: item, inline }: Props) => {
  const api: IApi = useApi();
  const config = useConfig();
  const user = useAuth();
  const T = useTranslations();
  const deviceConfig = useDeviceConfig().deviceConfig;
  const [locations, setLocations] = useState<IContentsLocationTreeLine[]>(null);
  const rerender = useRerender();
  const {
    data: orders,
    isLoading: ordersLoading,
    reload: loadOrders,
  } = useApiLoader(
    useCallback((api) => api.item.getOpenOrders(item.code), [item?.code]), // eslint-disable-line react-hooks/exhaustive-deps
    [],
    false,
  );
  const {
    data: history,
    isLoading: historyLoading,
    reload: loadHistory,
  } = useApiLoader(
    useCallback((api) => api.item.getHistory(item.code), [item?.code]), // eslint-disable-line react-hooks/exhaustive-deps
    [],
    false,
  );
  const [defaultLocations, setDefaultLocations] = useState<Array<IDefaultBinLocation>>(null);
  const [defaultLocationsFilter, setDefaultLocationsFilter] = useState<DefaultLocationFilter>('present');

  useEffect(() => {
    setLocations(null);
  }, [item?.code]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadLocations = async () => {
    setLocations(toLocationTree(await api.item.getItemLocations(item.code)));
  };

  const loadDefaultLocations = async () => {
    setDefaultLocations(await api.item.getDefaultLocations(item.code));
  };

  const shortDescription = () => getShortDescription(item);

  const printLabels = async (quantity: number) => {
    if (quantity) {
      await api.item.printLabel(
        deviceConfig.defaultItemLabelPrinter,
        {
          receiptCode: null,
          picklistCode: null,
          deliveryCode: null,
          jobs: [{
            item,
            batch: null,
            serial: null,
            quantity
          }]
        }
      );
    }
  }

  const printLargeLabels = async (quantity: number) => {
    if (quantity) {
      await api.item.printLargeLabel(
        deviceConfig.defaultPrinter,
        {
          receiptCode: null,
          picklistCode: null,
          deliveryCode: null,
          jobs: [{
            item,
            batch: null,
            serial: null,
            quantity
          }]
        }
      );
    }
  }

  const barcodeSetter = async (field: EBarcodeFields) => {
    const barcode = await prompt(<Translation name="T.hints.barcode"/>, '')
    if (barcode != null) {
      const setBarcode = async (b: string) => {
        await api.item.setBarcode(item.code, field, b);
        if (field === 'BARCODE1') {
          item.barcode = b
        } else if (field === 'BARCODE2') {
          item.barcode2 = b
        } else if (field === 'BARCODE3') {
          item.barcode3 = b
        }
        addNotification('success', <Translation name="T.misc.success"/>)
        rerender()
      }

      // get the possible compounds from this barcode
      let compounds = compiledUserCompoundItemBarcodeParser('itemCode', barcode)
      if (compounds.length === 0) {
        addNotification('danger', <Translation name="T.errors.noBarcodeFound"/>)
        return
      }

      if (compounds.length === 1) {
        setBarcode(compounds[0].itemCode)
        return
      }
    
      new Audio(serialSfx).play();
      const selected = await choiceList(
        <Translation name="T.objects.item.whatBarcodeToUse" params={{ code: barcode }}/>,
        compounds.map(it => ({
          value: it,
          label: (
            <Translation 
              name='T.objects.item.barcodeSelectionTile' 
              params={it}
            />
          )
        }))
      )
      if (selected) {
        setBarcode(selected.itemCode)
        return
      }
    }
  }

  const detailedRender = () => {
    return (
      <UncontrolledTabs key={item?.code}>
        <Tab 
          dataTestId='itemInfoTabGeneral' 
          name={<Translation name="T.misc.information" />}
        >
          <Container fluid>
            <Row>
              <Col sm="12" md="12" lg="4" className="text-center">
                <ItemImage
                  obj={item}
                  openOnClick
                  imgProps={{
                    style: {
                      maxHeight: '100%',
                      maxWidth: '100%',
                      objectFit: 'contain',
                    },
                  }}
                />
              </Col>

              <Col sm="12" md="12" lg="8">
                <PropertyList
                  rows={[
                    [
                      <b>
                        <Translation name="T.objects.item.itemCode" />
                      </b>,
                      <>
                        {item!.visualCode}
                        {' '}
                        <RoleProtected roles={['authCopyObjectCodes']}>
                          <ProcessingButton color="link" className='p-0' onClick={async () => {
                            navigator.clipboard.writeText(item!.visualCode)
                            await sleep(100)        
                          }}>
                            <FaCopy/>
                          </ProcessingButton>
                        </RoleProtected>
                      </>,
                    ],
                    [
                      <b>
                        <Translation name="T.objects.item.itemName" />
                      </b>,
                      <>{item!.name}<br/>{item!.secondName}</>,
                    ],
                    [
                      <b>
                        <Translation name="T.objects.item.barcode1" />
                      </b>,
                      <>
                        {item!.barcode}
                        {' '}
                        <If cond={user.expandedRoles.includes('barcodeSetup')}>
                          <ProcessingButton className='py-0' onClick={() => barcodeSetter('BARCODE1')}>
                            <FaEdit/>
                          </ProcessingButton>
                        </If>
                      </>,
                    ],
                    [
                      <b>
                        <Translation name="T.objects.item.barcode2" />
                      </b>,
                      <>
                        {item!.barcode2}
                        {' '}
                        <If cond={user.expandedRoles.includes('barcodeSetup')}>
                          <ProcessingButton className='py-0' onClick={() => barcodeSetter('BARCODE2')}>
                            <FaEdit/>
                          </ProcessingButton>
                        </If>
                      </>,
                    ],
                    [
                      <b>
                        <Translation name="T.objects.item.barcode3" />
                      </b>,
                      <>
                        {item!.barcode3}
                        {' '}
                        <If cond={user.expandedRoles.includes('barcodeSetup')}>
                          <ProcessingButton className='py-0' onClick={() => barcodeSetter('BARCODE3')}>
                            <FaEdit/>
                          </ProcessingButton>
                        </If>
                      </>,
                    ],
                    [
                      <b>
                        <Translation name="T.misc.units" />
                      </b>,
                      item!.units,
                    ],
                  ]}
                />
              </Col>
            </Row>
          </Container>
        </Tab>
        <Tab 
          dataTestId='itemInfoDetails' 
          name={<Translation name="T.misc.details" />}
        >
          <PropertyList
            rows={[
              [
                <b>
                  <Translation name="T.objects.item.groupname" />
                </b>,
                item!.groupName,
              ],
              [
                <b>
                  <Translation name="T.objects.item.managedBy" />
                </b>,
                item!.managedBy,
              ],
              item.managedBy === 'SERIAL_NUMBER' && [
                <b>
                  <Translation name="T.objects.item.serialManagedByDispatch" />
                </b>,
                <Bool val={item!.serialManagedByDispatch} type="YN" />,
              ],
              [
                <b>
                  <Translation name="T.objects.item.avgPrice" />
                </b>,
                item!.avgPrice,
              ],
              [
                <b>
                  <Translation name="T.objects.item.isActive" />
                </b>,
                <Bool val={item!.active} type="YN" />,
              ],
              [
                <b>
                  <Translation name="T.objects.item.isStockItem" />
                </b>,
                <Bool val={item!.isStockItem} type="YN" />,
              ],
              [
                <b>
                  <Translation name="T.objects.item.weight" />
                </b>,
                <>{item!.weightInMg / 1e6} kg</>,
              ],
              [
                <b>
                  <Translation name="T.objects.item.productionIssueMethod" />
                </b>,
                item.productionIssueMethod,
              ],
              [
                <b>
                  <Translation name="T.objects.item.productionMaterialTolerance" />
                </b>,
                <>
                  {item.productionMaterialToleranceValue} {item.productionMaterialToleranceType}
                </>,
              ],
              hasBatch(item) && [
                <b>
                  <Translation name="T.objects.item.blockForQcType" />
                </b>,
                item.blockForQcType,
              ],
              item.hasBatch && [
                <b>
                  <Translation name="T.objects.item.receiptScanExpirationDate" />
                </b>,
                <Bool type='YN' val={item.receiptScanPreferences?.scanExpirationDate}/>,
              ],
              item.hasBatch && [
                <b>
                  <Translation name="T.objects.item.receiptScanManufacturingDate" />
                </b>,
                <Bool type='YN' val={item.receiptScanPreferences?.scanManufacturingDate}/>,
              ],
              item.hasBatch && [
                <b>
                  <Translation name="T.objects.item.receiptScanManufacturerBatch" />
                </b>,
                <Bool type='YN' val={item.receiptScanPreferences?.scanManufacturingBatch}/>,
              ],
              !item.serialManagedByDispatch && item.serial && [
                <b>
                  <Translation name="T.objects.item.receiptScanManufacturerserial" />
                </b>,
                <Bool type='YN' val={item.receiptScanPreferences?.scanManufacturingSerial}/>,
              ],
            ]}
          />
        </Tab>
        <Tab 
          name={<Translation name="T.objects.item.locations" />}
          onFirstOpen={loadLocations}
          hidden={config.settings.systemOperationMode === 'WALL_TO_WALL_BATCHING_MODE' && !user.expandedRoles.includes('admin')}
        >
          <LocationsTable data={locations || []} loading={locations === null} />
        </Tab>
        <Tab 
          name={<Translation name="T.objects.item.defaultLocationsTabHeader" />}
          onFirstOpen={loadDefaultLocations}
        >
          <>
            <Input
              type="select"
              value={defaultLocationsFilter}
              onChange={(e) => setDefaultLocationsFilter(e.target.value as DefaultLocationFilter)}
            >
              <option value="present">{T('T.misc.onlyPresent')}</option>
              <option value="active">{T('T.misc.onlyActive')}</option>
              <option value="all">{T('T.misc.all')}</option>
            </Input>
            <DefaultLocationsTable
              data={(defaultLocations || []).filter((it) => {
                if (defaultLocationsFilter === 'present') {
                  return it.binLocation;
                }
                if (defaultLocationsFilter === 'active') {
                  return it.whs.active;
                }
                return true;
              })}
              colShowProps={{ auth: user }}
              getExtraProps={() => ({
                item,
                loadDefaultLocations,
              })}
            />
          </>
        </Tab>
        <Tab 
          name={<Translation name="T.misc.orders" />}
          onFirstOpen={loadOrders}
        >
          <OrdersTable loading={ordersLoading} data={orders} />
        </Tab>
        <Tab 
          name={<Translation name="T.misc.history" />}
          onFirstOpen={loadHistory}
        >
          <HistoryTable loading={historyLoading} data={history} />
        </Tab>
        <Tab 
          name={<Translation name="T.misc.actions" />}
        >
          <>
            <ButtonGroup className="w-100">
              <ProcessingButton
                block
                color="primary"
                onClick={async () => await printLabels(
                  parseInt(await prompt(<Translation name="T.misc.howManyLabels" />, ``))
                )}
              >
                <Translation name="T.misc.printLabels" />
              </ProcessingButton>
              <ProcessingButton
                color="info"
                onClick={async () => await printLabels(1)}
              >
                <RiNumber1/>
              </ProcessingButton>
            </ButtonGroup>
            <br/>

            <ButtonGroup className="w-100">
              <ProcessingButton
                block
                color="primary"
                onClick={async () => await printLargeLabels(
                  parseInt(await prompt(<Translation name="T.misc.howManyLabels" />, ``))
                )}
              >
                <Translation name="T.misc.printLargeLabels" />
              </ProcessingButton>
              <ProcessingButton
                color="info"
                onClick={async () => await printLargeLabels(1)}
              >
                <RiNumber1/>
              </ProcessingButton>
            </ButtonGroup>

            <RoleProtected roles={['itemManagedByEdit']}>
              <br/>
              <UpdateManagedBy item={item}/>
            </RoleProtected>

            <br/>
            <ItemImageSetter
              itemCode={item.code}
              onImageChanged={it => {
                item.imagePath = it;
                rerender()
              }}
            />
          </>
        </Tab>
      </UncontrolledTabs>
    );
  };

  const dialog = {
    titleText: <Translation name="T.objects.item.itemDetails" />,
    closeBtnText: <Translation name="T.misc.close" />,
    content: () => <Item obj={item} />,
  };

  return (
    <Model
      inline={inline}
      hasLoaded={item}
      copyValue={() => item.visualCode}
      description={shortDescription}
      detailedRender={detailedRender}
      dialog={dialog}
    />
  );
};

export const ItemLocationsTable = LocationsTable;
export default Item;
