
export type GeneratorType = 'QUERY' | 'SCRIPT';
export const GeneratorTypeValues: GeneratorType[] = ['QUERY' , 'SCRIPT'];

export interface IReportDefinition {
  createdAt: any;
  cronExpression: string;
  generationSettings: IReportDefinition$IReportGenerationSettings;
  group: string;
  id: number;
  name: string;
  outputSettings: IReportDefinition$IReportOutput;
  parameters: IReportDefinition$IReportParameter[];
  updatedAt: any;
}

export interface IReportDefinition$IReportGenerationSettings {
  queries: IReportDefinition$IReportGenerationSettings$IQuery[];
  queriesFileName: string;
  scriptSource: string;
  type: GeneratorType;
}

export interface IReportDefinition$IReportGenerationSettings$IQuery {
  name: string;
  src: string;
}

export interface IReportDefinition$IReportOutput {
  emailSubject: string;
  emailText: string;
  targetEmailAddresses: string;
  targetFolder: string;
  type: OutputType;
}

export interface IReportDefinition$IReportParameter {
  askOnRun: boolean;
  format: string;
  name: string;
  type: string;
  value: string;
}

export type OutputType = 'NONE' | 'EMAIL' | 'DISK';
export const OutputTypeValues: OutputType[] = ['NONE' , 'EMAIL' , 'DISK'];

