import React, { useState, ReactNode, FC, ReactElement } from 'react';
import { Container, FormGroup, Label } from 'reactstrap';
import { useApi, useAuth } from 'utils/API';
import { useNotifications } from 'utils/NotificationManager';
import { useTranslations } from 'utils/language';
import { LanguageValues, IUserSettings, Role, IErpUser, IUser } from 'interfaces';
import { useConfig } from 'utils/Config';
import ProcessingButton from 'components/ProcessingButton';
import Translation from 'utils/Language/Translation';
import {
  FormRange,
  FormSelect,
  FormState,
  FormToggle,
  Labeled,
  IntegerType,
  FormShortcutKeySelector,
  FormObject,
} from 'component_utils/FormUtils';
import { Tab, UncontrolledTabs } from 'components/uncontrolled_tabs';
import If from 'components/If';
import ResetPassword from './PasswordReset';
import RoleEditor from './RoleEditor';
import Setup2FA from './setup2FA';
import { run, useEffectAsync } from 'component_utils/utils';
import { WebPushDeviceRegistration } from 'utils/endpoints/User';
import * as Immutable from 'object-path-immutable'
import PersonalMenuEditor from './PersonalMenuEditor';

export function areNotificationsEnabled() {
  try {
    return "Notification" in window && Notification.permission === 'granted' && window.be_sw_push_subscription
  } catch {
    return false
  }
}

const ErpUserSelector = () => {
  const [erpUsers, setErpUsers] = useState<Array<IErpUser>>([]);
  const api = useApi()
  useEffectAsync(async () => {
    setErpUsers(await api.users.getErpUsers());
  }, [setErpUsers])

  return (
    <>
      <Labeled title={<Translation name="T.user.settings.erpUser"/>}>
        <FormObject<IErpUser>
          path="externalUserId"
          autocomplete={async (s) =>
            erpUsers
              .filter((it) => it.name.toLowerCase().includes(s.toLowerCase()) || it.code.toLowerCase().includes(s.toLowerCase()))
              .map((it) => ({
                label: `${it.code} - ${it.name}`,
                value: it.code,
              }))
          }
          find={(s) => erpUsers.find((it) => it.code === s)}
          getCode={(it) => it.code}
          render={(it) => <>{it?.code} - {it?.name}</>}
        />
      </Labeled>
    </>
  )
}

type State = {settings: IUserSettings, externalUserId: string}
interface UserSettingsProps {
  userId: number;
  webPushRegistration: WebPushDeviceRegistration;
  getUser: (userId: number) => Promise<IUser>
  setWebPushSetIsPersonalDevice: (isPersonalDevice: boolean) => any;
  render: (editor: ReactNode, save: any) => ReactElement<any, any>
  beforeSave?: (state: State) => any
}

export const UserSettings: FC<UserSettingsProps> = ({ userId, getUser, render, beforeSave, webPushRegistration, setWebPushSetIsPersonalDevice }) => {
  const [state, setState] = useState<State>(null);
  const [selectedRoles, setSelectedRoles] = useState<Role[]>([]);
  const api = useApi();
  const T = useTranslations();
  const config = useConfig();
  const notifications = useNotifications();
  const user = useAuth();

  const saveSettings = async () => {
    if (beforeSave) 
      await beforeSave(state);
    await api.users.updateUser({
      newExternalId: state.externalUserId || "",
      newRoles: selectedRoles,
      newSettings: state.settings     
    }, userId)
    notifications.add('success', <Translation name="T.misc.saved" />);
  };

  useEffectAsync(async () => {
    const toUpdate = await getUser(userId)
    setState({
      settings: toUpdate.userSettings,
      externalUserId: toUpdate.externalId
    })
    setSelectedRoles([...toUpdate.roles]);
  }, [api, setState, getUser, userId]);

  if (state === null) return null;

  const tabs: {name: string, content: ReactNode}[] = []
  tabs.push({
    name: 'T.user.settings.userSettingsTitle',
    content: (
      <div className="flex-fill-height">
        <Container fluid>
          <h1><Translation name="T.user.settings.userSettingsTitle"/></h1>

          <FormState state={state} setState={setState}>
            <Labeled title={<Translation name="T.misc.language" />}>
              <FormSelect path="settings.preferredLanguage" options={LanguageValues.map((it) => ({ value: it, label: it }))} />
            </Labeled>

            <Labeled
              title={
                <>
                  {<Translation name="T.settings.user.popupDuration" />}
                  {': '}
                  {state.settings.popupDuration / 1000}
                  {' s'}
                </>
              }
            >
              <FormRange path="settings.popupDuration" min={0} max={10000} typed={IntegerType} />
            </Labeled>

            <Labeled
              title={
                <>
                  <Translation name="T.settings.user.linkableHoldDuration" />
                  {': '}
                  {state.settings.linkableHoldToOpenDuration / 1000}
                  {' s'}
                </>
              }
            >
              <FormRange path="settings.linkableHoldToOpenDuration" min={1} step={1} max={10000} typed={IntegerType} />
            </Labeled>

            <Labeled title={<Translation name="T.settings.general.pickingToSoundEnabled" />}>
              <FormToggle path="settings.pickingToSound" />
            </Labeled>

            <Labeled title={<Translation name="T.settings.general.menuToggleShortcutKey" />}>
              <FormShortcutKeySelector path="settings.menuToggleShortcutKey" />
            </Labeled>

            <Labeled title={<Translation name="T.settings.general.menuOnlyOneOpenAtOnce" />}>
              <FormToggle path="settings.menuOnlyOneOpenAtOnce" />
            </Labeled>

            <If cond={config.settings.allowUsersToChangePassword || user.expandedRoles.includes('userManager')}>
              <ResetPassword api={api} T={T} notifications={notifications} userId={userId} />
            </If>

            <If cond={config.settings.allowUsersToChangePassword || user.expandedRoles.includes('userManager')}>
              <Setup2FA userId={userId} />
            </If>
            
            {areNotificationsEnabled() && webPushRegistration && run(() => {
              const isSetAsPersonalDevice = webPushRegistration.users.find(it => it.userid === userId)
              return (
                <FormGroup>
                  <Label>
                    <Translation name="T.settings.webpush.personalDevice" params={{ users: webPushRegistration.users.map(it => it.username).join(", ") }} />
                  </Label>
                  <ProcessingButton block color={isSetAsPersonalDevice ? 'danger' : 'success'} onClick={async () => {
                    if (isSetAsPersonalDevice) {
                      await setWebPushSetIsPersonalDevice(false)
                    } else {
                      await setWebPushSetIsPersonalDevice(true)
                    }
                  }}>
                    {isSetAsPersonalDevice ? <Translation name="T.settings.webpush.unsetAsPersonalDevice"/> : <Translation name="T.settings.webpush.setAsPersonalDevice"/>}
                  </ProcessingButton>
                </FormGroup>
              )
            })}

            <If cond={user.expandedRoles.includes('userManager')}>
              <ErpUserSelector/>
            </If>

            <If cond={user.expandedRoles.includes('userManager')}>
              <Labeled title={<Translation name="T.settings.general.allowMultipleTabsActiveOnSameDevice" />}>
                <FormToggle path="settings.allowMultipleTabsActiveOnSameDevice" />
              </Labeled>
            </If>
          </FormState>
        </Container>
      </div>
    )
  })

  tabs.push({
    name: "T.user.settings.menuSettings",
    content: (
      <>
        <h1><Translation name="T.user.settings.menuSettingsTitle"/></h1>
        <PersonalMenuEditor state={state.settings.menuFavorites || []} setState={f => setState(old => Immutable.set(old, 'settings.menuFavorites', f))}/>
      </>
    )
  })

  if (user.expandedRoles.includes('userManager')) {
    tabs.push({
      name: 'T.user.settings.rolesTitle',
      content: (
        <RoleEditor roles={selectedRoles} setRoles={setSelectedRoles}/>
      )
    })
  }

  let view = null
  if (tabs.length === 1) {
    view = tabs[0].content
  } else {
    view = (
      <UncontrolledTabs className='nested-flex-container'>
        {tabs.map(it => (
          <Tab key={it.name} name={<Translation name={it.name}/>}>
            {it.content}
          </Tab>
        ))}
      </UncontrolledTabs>
    )
  }

  return render(view, saveSettings)
};