import React from 'react';
import ReactDOM from 'react-dom';
import AppContainer from 'AppContainer';
import * as serviceWorker from 'serviceWorker';
import vendorSetup from 'vendor';
import moment from 'moment';
import { setup } from 'component_utils/external_loader'
import * as Shake from 'shake.js'
import performMigrations from 'frontendMigrations'
import { sessionID } from 'statics/session_identification';

const myShakeEvent = new Shake({
    threshold: 15, // optional shake strength threshold
    timeout: 1000 // optional, determines the frequency of event generation
});
myShakeEvent.start();
moment.fn.toJSON = function() { return this.format(); }
vendorSetup();
setup();
performMigrations();
console.log("Starting session " + sessionID)
ReactDOM.render(<AppContainer />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
