
export interface IIPFilterSettings {
  filters: IIPFilterSettings$IIpFilter[];
}

export interface IIPFilterSettings$IIpFilter {
  ipFormat: string;
  rule: IpFilterRule;
}

export type IpFilterRule = 'BLOCK' | 'ALLOW_2FA' | 'ALLOW';
export const IpFilterRuleValues: IpFilterRule[] = ['BLOCK' , 'ALLOW_2FA' , 'ALLOW'];

