import React, { FC } from 'react';
import classnames from 'classnames';
import { Column, ColumnState } from './index';

export const defaultColumnProps = {
  width: 40,
  resizable: true,
  sortable: true,
};

export const defaultColumnState: ColumnState = {
  sort: 0,
  filter: '',
};

const NoRowsFoundComponent: FC<{ text: string }> = ({ text }) => {
  return <div className="rt-noData">{text}</div>;
};

export const FilterComponent: FC<{
  column: Column<any, any, any>;
  value: string;
  onChange: (s: string) => void;
  onBlur: (s: string) => void;
}> = ({ value, column, onChange, onBlur }) => {
  return (
    <input
      type="text"
      style={{
        width: '100%',
      }}
      placeholder={column.filterPlaceHolder}
      value={value ? value : ''}
      onKeyUp={e => {
        if (e.key === 'Enter' && !e.repeat) {
          (e.target as HTMLInputElement).blur()
        }
      }}
      onChange={(event) => onChange(event.target.value)}
      onBlur={(event) => onBlur(event.target.value)}
    />
  );
};

const LoadingComponent: FC<{ text: string; loading: boolean }> = ({ text, loading }) => {
  return (
    <div className={classnames('-loading', { '-active': loading })}>
      <div className="-loading-inner">{text}</div>
    </div>
  );
};

const ExpanderComponent: FC<{ isExpanded: boolean }> = ({ isExpanded }) => {
  return (
    <div
      className={classnames('rt-expander', isExpanded && '-open')}
      data-testid="Expander"
      data-test-is-expanded={isExpanded ? 'true' : 'false'}
    >
      &bull;
    </div>
  );
};

type RowClickArgs<T> = { index: number; row: T; level: number; parents: any[] };

export default {
  subRowsKeys: [],
  noDataText: 'No rows found',
  loadingText: 'Loading',
  minRows: 3,
  minRowSize: 30,
  alwaysExpendToLevel: 0,
  NoRowsFoundComponent,
  LoadingComponent,
  ExpanderComponent,
  getExtraProps: () => ({} as any),
  colShowProps: {},
  onRowClick: (args: RowClickArgs<any>) => {},
};
