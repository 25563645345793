import { IAnalyticsActionEntry } from "interfaces";

export interface AnalyticsActionEntry extends IAnalyticsActionEntry {
  fieldValues: any[]
}

export function recordAction(actions: AnalyticsActionEntry[]) {
  const blob = new Blob([JSON.stringify(actions)], { type: 'application/json' });
  navigator.sendBeacon('/api/v1/analytics/action', blob);
}
