import React, { useState, ReactNode, PropsWithChildren } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import get from 'lodash/get';
import {
  FormGroup,
  Col,
  Label,
  Input,
  CustomInput,
  Button,
  InputGroup,
  InputGroupAddon,
  ButtonProps,
} from 'reactstrap';
import { InputProps, InputType } from 'reactstrap/lib/Input';
import { CustomInputProps, CustomInputType } from 'reactstrap/lib/CustomInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import ProcessingButton from 'components/ProcessingButton';
import { isNil } from 'lodash';
import { SelectOption } from 'components/Select';

interface DefaultProps<P> {
  path: P;
  title?: ReactNode;
  register: {
    state: any;
    idPrefix: string;
    formOnChange: (path: string, value: any) => void;
  };
}
export const IntegerType = (v: any) => parseInt(v) || 0;
export const IntegerTypeOrNull = (v: any) => parseInt(v) || null;
export const FloatType = (v: any) => parseFloat(v) || 0;

function getIfNotNill(value: any, ifNil: any) {
  return isNil(value) ? ifNil : value;
}

function create_input_props(
  comp: any,
  path: string,
  typed: any,
  transformValue = (v: any) => v,
  preProcessValue = (v: any) => v,
) {
  const value = transformValue(get(comp.state, path, undefined));
  return {
    id: comp.idPrefix + path,
    value: `${getIfNotNill(value, '')}`,
    checked: getIfNotNill(value, false),
    onChange: (event: any) =>
      comp.formOnChange(
        path,
        preProcessValue(event.target.type === 'checkbox' ? event.target.checked : event.target.value),
      ),
    onBlur: (event: any) =>
      comp.formOnChange(
        path,
        typed(preProcessValue(event.target.type === 'checkbox' ? event.target.checked : event.target.value)),
      ),
    'data-testid': comp.idPrefix + path,
  };
}

class Helper<T> {
  Return = useFormState<T>(null);
}
export type IFormState<T> = Helper<T>['Return'];

export function useFormState<T>(def: T, options = { idPrefix: '', canChangeState: true }) {
  const [state, setState] = useState<T>(def);
  const [changed, setChanged] = useState(false);
  const proxyState = {
    state,
    setState,
    setChanged,
    formOnChange: (path: string, value: any) => {},
    idPrefix: options.idPrefix,
  };
  if (options.canChangeState) {
    proxyState.formOnChange = formOnChange(proxyState);
  }

  return {
    state,
    setState,
    setStateMerge: (mergeState: object) => setState({ ...state, ...mergeState }),
    setField: proxyState.formOnChange,
    getField: function <T>(path: string, defaultValue: T = undefined) {
      return get(state, path, defaultValue) as T;
    },
    register: proxyState,
    createInputProps: (path: string, typed = (v: any) => v) => create_input_props(proxyState, path, typed),

    changed: changed,
    clearChanged: () => setChanged(false),

    FormInput,
    FormCustomInput,
    FormSelect,
    FormInputButton,
    FormSelectButton,
    FormObject,
    FormButton,
    FormText,
  };
}

export function formOnChange(comp: any) {
  return (path: string, value: any) => {
    const newState = cloneDeep(comp.state);
    set(newState, path, value);

    comp.setChanged(true);
    comp.setState(newState);
  };
}

interface FormObjectProps<P> extends PropsWithChildren<DefaultProps<P>> {
  clearValue: any;
  getNewValue: () => Promise<any>;
}
export function FormObject<P extends string>({
  children,
  path,
  title,
  register,
  clearValue,
  getNewValue,
}: FormObjectProps<P>) {
  const input = (
    <InputGroup>
      <div className="form-control">{children}</div>
      <InputGroupAddon addonType="append">
        <Button color="secondary" onClick={async () => register.formOnChange(path, clearValue)}>
          <FontAwesomeIcon icon={faTrash} />
        </Button>
        <Button
          color="primary"
          onClick={async () => {
            const inp = await getNewValue();
            if (inp) {
              register.formOnChange(path, inp);
            }
          }}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
      </InputGroupAddon>
    </InputGroup>
  );

  if (!title) {
    return input;
  }
  return (
    <FormGroup row>
      <Label md={4} for={register.idPrefix + path}>
        {title}
      </Label>
      <Col md={8}>{input}</Col>
    </FormGroup>
  );
}

interface FormInputProps<P> extends DefaultProps<P> {
  type?: InputType;
  typed?: (v: any) => any;
  inputProps?: InputProps;
}
export function FormInput<P extends string>({
  path,
  title,
  register,
  type = 'text',
  typed = (v) => v,
  inputProps = {},
}: FormInputProps<P>) {
  const input = <Input type={type} {...create_input_props(register, path, typed)} {...inputProps} />;

  if (!title) {
    return input;
  }
  return (
    <FormGroup row>
      <Label md={4} for={register.idPrefix + path}>
        {title}
      </Label>
      <Col md={8}>{input}</Col>
    </FormGroup>
  );
}

interface FormButtonProps extends ButtonProps {
  buttonTitle: ReactNode;
  buttonContent: ReactNode;
}
export function FormButton({ buttonTitle, buttonContent, ...props }: FormButtonProps) {
  return (
    <FormGroup row>
      <Label md={4}>{buttonTitle}</Label>
      <Col md={8}>
        <ProcessingButton block {...props}>
          {buttonContent}
        </ProcessingButton>
      </Col>
    </FormGroup>
  );
}

interface FormTextProps {
  title: ReactNode;
  text: ReactNode;
}
export function FormText({ title, text }: FormTextProps) {
  return (
    <FormGroup row>
      <Label md={4}>{title}</Label>
      <Col md={8}>{text}</Col>
    </FormGroup>
  );
}

interface FormCustomInputProps<P> extends DefaultProps<P> {
  type: CustomInputType;
  typed?: (v: any) => any;
  props?: Omit<CustomInputProps, 'type'>;
}
export function FormCustomInput<P extends string>({
  path,
  title,
  type,
  props = {},
  typed = (v) => v,
  register,
}: FormCustomInputProps<P>) {
  if (type === 'switch') {
    const input = <CustomInput type={type} {...props} {...create_input_props(register, path, typed)} label={title} />;
    if (!title) return input;
    return (
      <FormGroup row>
        <Col>{input}</Col>
      </FormGroup>
    );
  }

  const input = <CustomInput type={type} {...props} {...create_input_props(register, path, typed)} />;
  if (!title) return input;
  return (
    <FormGroup row>
      <Label md={4} for={register.idPrefix + path}>
        {title}
      </Label>
      <Col md={8}>{input}</Col>
    </FormGroup>
  );
}

interface FormSelectProps<P> extends PropsWithChildren<DefaultProps<P>> {
  typed?: (v: any) => any;
  options: SelectOption[];
}
export function FormSelect<P extends string>({ path, title, typed = (v) => v, options, register }: FormSelectProps<P>) {
  const input = (
    <Input
      type="select"
      {...create_input_props(
        register,
        path,
        typed,
        (v) => options.findIndex((it) => it.value === v),
        (v) => options[parseInt(v) || 0]?.value,
      )}
    >
      {options.map(
        (it, index) =>
          it && (
            <option key={index} value={index}>
              {it.label}
            </option>
          ),
      )}
    </Input>
  );

  if (!title) return input;
  return (
    <FormGroup row>
      <Label md={4} for={register.idPrefix + path}>
        {title}
      </Label>
      <Col md={8}>{input}</Col>
    </FormGroup>
  );
}

interface FormSelectButtonProps<P> extends PropsWithChildren<DefaultProps<P>> {
  typed?: (v: any) => any;
  options: SelectOption[];
  btnClick: () => any;
  btnLabel: any;
}
export function FormSelectButton<P extends string>({
  path,
  title,
  btnLabel,
  btnClick,
  typed = (v) => v,
  options,
  register,
}: FormSelectButtonProps<P>) {
  const input = (
    <InputGroup>
      <Input
        type="select"
        {...create_input_props(
          register,
          path,
          typed,
          (v) => options.findIndex((it) => it.value === v),
          (v) => options[parseInt(v) || 0]?.value,
        )}
      >
        {options.map(
          (it, index) =>
            it && (
              <option key={index} value={index}>
                {it.label}
              </option>
            ),
        )}
      </Input>

      <InputGroupAddon addonType="append">
        <Button color="primary" onClick={btnClick}>
          {btnLabel}
        </Button>
      </InputGroupAddon>
    </InputGroup>
  );
  if (!title) return input;
  return (
    <FormGroup row>
      <Label md={4} for={register.idPrefix + path}>
        {title}
      </Label>
      <Col md={8}>{input}</Col>
    </FormGroup>
  );
}

interface FormInputButtonProps<P> extends DefaultProps<P> {
  btnLabel: ReactNode;
  btnClick: any;
  type?: InputType;
  typed?: (v: any) => any;
  inputProps?: any;
}
export function FormInputButton<P extends string>({
  path,
  title,
  btnLabel,
  btnClick,
  type = 'text',
  typed = (v) => v,
  inputProps,
  register,
}: FormInputButtonProps<P>) {
  const input = (
    <InputGroup>
      <Input type={type} {...create_input_props(register, path, typed)} {...inputProps} />
      <InputGroupAddon addonType="append">
        <Button color="primary" onClick={btnClick}>
          {btnLabel}
        </Button>
      </InputGroupAddon>
    </InputGroup>
  );

  if (!title) return input;
  return (
    <FormGroup row>
      <Label md={4} for={register.idPrefix + path}>
        {title}
      </Label>
      <Col md={8}>{input}</Col>
    </FormGroup>
  );
}
