import React, { PropsWithChildren, ReactNode } from 'react';
import { Table } from 'reactstrap';

interface ColumnConfig {
  className: string;
  width: number;
}

type IProps = PropsWithChildren<{
  headers?: Array<ReactNode>;
  rows: Array<Array<ReactNode>>;
  columnConfig?: ColumnConfig[];
  tableProps?: any;
}>;

export default (props: IProps) => {
  const conf = props.columnConfig;
  let headers = null;
  if (props.headers) {
    headers = (
      <thead>
        <tr>
          {props.headers.map((h, i) => {
            return (
              <th key={i} className={conf?.[i]?.className}>
                {h}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  }

  return (
    <Table striped className="mb-0" {...(props.tableProps || {})}>
      {headers}
      <tbody>
        {props.rows.map((r, i) => {
          if (!r) {
            return null;
          }
          return (
            <tr key={i}>
              {r.map((c, i) => {
                return (
                  <td key={i} className={conf?.[i]?.className} width={conf?.[i]?.className}>
                    {c}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
