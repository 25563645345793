import React from 'react'
import { PeripheralPrinter } from "utils/PeripheralManager"

const MaintenanceComponent = ({device}: {device: BrowserPrint}) => {
  return (
    <>
      
    </>
  )
}

class BrowserPrint implements PeripheralPrinter<BrowserPrint> {
  printerName: string;

  getDriverName = () => 'BrowserPrint'

  connect = async (data: any) => {}

  disconnect = async () => {}

  isConnected = async () => { return true }

  print = async (mediatype: string, payload: string) => { 
    console.log("printing", mediatype, payload, this.printerName)
    if (mediatype === 'application/pdf') {
      const printJS = await (await import('print-js')).default
      printJS({printable: payload, type: 'pdf', base64: true})
    }
  }

  getMaintenanceComponent = () => MaintenanceComponent
}

export default BrowserPrint