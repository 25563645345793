import {
  IUser,
  IErpUser,
  ISimpleUserDetails,
  IAuthenticatedUser,
  ISessionIdentification,
  IVerifyManagerLogin,
  IUpdateUserDto,
  IAddUserDto,
} from 'interfaces';
import { Registry } from '.';

export type WebPushDeviceRegistration = {
  users: ISimpleUserDetails[];
}

export default ({ get, post, destroy }: Registry) => {
  return {
    verifyManagerUser: (data: IVerifyManagerLogin): Promise<ISimpleUserDetails> =>
      post('/api/v1/auth/verify', data, undefined, { preventLogout: true }),

    users: {
      getMe: (): Promise<IAuthenticatedUser> => get('/api/v1/auth/me'),
      getUsers: (): Promise<Array<IUser>> => get('/api/v1/admin/users'),
      getUser: (id: number): Promise<IUser> => get(`/api/v1/admin/users/${id}`),
      getErpUsers: (): Promise<Array<IErpUser>> => get('/api/v1/admin/erpUsers'),
      getSimpleUsers: (): Promise<Array<ISimpleUserDetails>> => get('/api/v1/admin/simple_users'),

      addUser: (data: IAddUserDto): Promise<IUser> => post('/api/v1/admin/users/new', data),
      updateUser: (update: IUpdateUserDto, target: number): Promise<void> =>
        post(`/api/v1/users/${target}`, update),
      saveUserPassword: (id: number, newPassword: { password: string }): Promise<void> =>
        post(`/api/v1/users/${id}/password`, newPassword),
      deleteUser: (id: number): Promise<void> => destroy(`/api/v1/admin/users/${id}`),
  
      get2FABarcode: (id: number, mode: 'view' | 'reset'): Promise<{ qr: string }> => post(`/api/v1/users/${id}/2fa/${mode}`, {}),

      getWebPushRegistrationForDevice: (): Promise<WebPushDeviceRegistration> => post(`/api/v1/web_push/getRegistrationForDevice`, {subscription:JSON.stringify(window.be_sw_push_subscription)}),
      setAsPersonalDevice: (id: number): Promise<WebPushDeviceRegistration> => post(`/api/v1/web_push/register_as_user_device/${id}`, {subscription:JSON.stringify(window.be_sw_push_subscription)}),
      unsetAsPersonalDevice: (id: number): Promise<WebPushDeviceRegistration> => post(`/api/v1/web_push/deregister_as_user_device/${id}`, {subscription:JSON.stringify(window.be_sw_push_subscription)}),
    },

    sessions: {
      getSessions: (): Promise<ISessionIdentification[]> => get('/api/v1/sessions/'),
      endSession: (sid: number) => destroy(`/api/v1/sessions/${sid}`),
      initiateWebRTC: (userId: number, sid: number, conn_id: string, metadata: any) => post(`/api/v1/sessions/${userId}/${sid}/capture/${conn_id}`, metadata)
    },
  };
};
