import {
  IPickListHeader,
  IPickListRow,
  IPickListHeaderId,
  IBrokenPicklistDescriptor,
  IPicklistCandidatesFilter,
  IPickListUpdatePicked,
  IPicklistGeneratorController$ICandidateGroups,
  IAddress,
  ClaimType,
  IPicklistPromotionInformation,
  IIPickListDao$ICompanion$ICreatedDelivery,
  ISetPicklistTargetCodeDto,
  IBatchDeliveryData,
  IPickListsAttachment,
  IPickListsShippingContainer,
  ISetPicklistShippingContainerContent,
  IPicklistsShippingContainerContents,
  IIDeliveryDao$IStoredShippingContainer,
  IEditPicklistDto$INewPicklist,
  IIDeliveryDao$IDeliveryDocument,
  IPicklistCreationLock,
  ICreatePicklistDto,
} from 'interfaces';
import { Registry } from '.';

interface IGeneratedLabelLine {
  code: string,
  name: string,
  type: string,
  weight: number,
  height: number,
  width: number,
  depth: number,
}

export default ({ get, post, destroy }: Registry) => {
  const createClaimObject = (type: ClaimType) => {
    return {
      claim: (ids: IPickListHeaderId[]): Promise<number[]> => post(`/api/v1/pick_list/${type}/claim`, ids),
      getClaims: (claimId: number): Promise<{
        header: IPickListHeader,
        rows: IPickListRow[]
      }> => get(`/api/v1/pick_list/${claimId}/claim`),
      markRowsPicked: (data: IPickListUpdatePicked[]): Promise<IPickListRow[]> =>
        post(`/api/v1/pick_list/markRowsPicked`, data),

      printPreDeliveryDocuments: (claimId: number, printerId: number) => post(
        `/api/v1/pick_list/${claimId}/printPreDeliveryDocuments`,
        null,
        { printerId: printerId || -1 }
      ),

      getAttachments: (claimId: number): Promise<IPickListsAttachment[]> => get(`/api/v1/pick_list/${claimId}/attachments/`),
      saveAttachment: (claimId: number, data: IPickListsAttachment): Promise<IPickListsAttachment> => post(`/api/v1/pick_list/${claimId}/attachments/`, data),
      deleteAttachment: (claimId: number, id: number) => destroy(`/api/v1/pick_list/${claimId}/attachments/${id}`),

      getShippingContainers: (claimId: number): Promise<IPickListsShippingContainer[]> => get(`/api/v1/pick_list/${claimId}/shippingContainer/`),
      saveShippingContainer: (claimId: number, data: IPickListsShippingContainer): Promise<IPickListsShippingContainer> => post(`/api/v1/pick_list/${claimId}/shippingContainer/`, data),
      deleteShippingContainer: (claimId: number, id: number) => destroy(`/api/v1/pick_list/${claimId}/shippingContainer/${id}`),
      setShippingContainerContent: (claimId: number, container: number, setInsctruction: ISetPicklistShippingContainerContent): Promise<IPicklistsShippingContainerContents> => post(`/api/v1/pick_list/${claimId}/shippingContainer/${container}/contents`, setInsctruction),

      flush: (claimId: number): Promise<void> => post(`/api/v1/pick_list/${claimId}/flush`, null),
      promote: (claimId: number, data: IPicklistPromotionInformation): Promise<IIPickListDao$ICompanion$ICreatedDelivery> =>
        post(`/api/v1/pick_list/${claimId}/promote`, data),
      removeClaim: (claimId: number): Promise<void> => post(`/api/v1/pick_list/${claimId}/removeClaim`, null),
      detachClaim: (claimId: number): Promise<void> => post(`/api/v1/pick_list/${claimId}/detachClaim`, null),
      setTargetForPicklist: (claimId: number, payload: ISetPicklistTargetCodeDto): Promise<void> => post(`/api/v1/pick_list/${claimId}/setTargetCode`, payload)
    };
  };
  return {
    picklists: {
      picking: createClaimObject('PICKLIST'),
      delivery: createClaimObject('DELIVERY'),

      getPickListHeaders: (onlyForMe: boolean): Promise<Array<IPickListHeader>> =>
        get(`/api/v1/pick_list/all/${onlyForMe}`),
      getPickListHeader: (id: IPickListHeaderId): Promise<IPickListHeader> =>
        get(`/api/v1/pick_list/`, id),
      getBrokenPickLists: (): Promise<Array<IBrokenPicklistDescriptor>> => get(`/api/v1/pick_list/broken`),
      getPickListRows: (id: IPickListHeaderId): Promise<Array<IPickListRow>> => get(`/api/v1/pick_list/rows`, id),

      mergePicklists: (ids: IPickListHeaderId[]): Promise<string> => post(`/api/v1/pick_list/merge`, ids),
      copyPicklists: (code: string): Promise<string> => post(`/api/v1/pick_list/copy`, { code }),
      editPicklists: (
        code: string, 
        newPicklists: IEditPicklistDto$INewPicklist[], 
      ) => post(`/api/v1/pick_list/edit`, { code, newPicklists }),
      createPicklist: (payload: ICreatePicklistDto) => post(`/api/v1/pick_list/create`, payload),

      resetPicklist: (id: IPickListHeaderId): Promise<void> => post(`/api/v1/pick_list/clearPicklist`, id),

      getDeliverySettings: (id: IPickListHeaderId): Promise<any> => get(`/api/v1/pick_list/deliveryApplicationSettings`, id),
      getDeliveryAddress: (id: IPickListHeaderId): Promise<IAddress> => get(`/api/v1/pick_list/deliveryAddress`, id),
    },

    delivery: {
      createBatchDelivery: (request: IBatchDeliveryData) => post(`/api/v1/delivery/batchDelivery`, request),

      getDeliveries: (from: Date, to: Date): Promise<IIDeliveryDao$IDeliveryDocument[]> =>
        get(`/api/v1/delivery/`, { from: from.toISOString(), to: to.toISOString() }),
      getNotYetDispatchedDeliveries: (): Promise<IIDeliveryDao$IDeliveryDocument[]> =>
        get(`/api/v1/delivery/notYetDispatched`),
      getDeliveryShippingDetails: (code: string): Promise<IIDeliveryDao$IStoredShippingContainer[]> => get(`/api/v1/delivery/${code}/shippingDetails`),

      markDispatched: (deliveryCode: string): Promise<string[]> =>
        post(`/api/v1/delivery/${deliveryCode}/markDispatched`, {}),

      generateDeliveryLabels: (deliveryCode: string, lineCodes: string[], printerId: number, all: boolean = false): Promise<IGeneratedLabelLine[]> =>
        post(`/api/v1/delivery/${deliveryCode}/generate/label`, lineCodes, { all, printerId: printerId || -1 }),
      printDeliveryLabels: (deliveryCode: string, lineCodes: string[], printerId: number, all: boolean = false): Promise<void> =>
        post(`/api/v1/delivery/${deliveryCode}/print/label`, lineCodes, { all, printerId: printerId || -1 }),
      
      printDeliveryReport: (deliveryCode: string, printerId: number, context: string): Promise<string[]> =>
        post(`/api/v1/delivery/${deliveryCode}/print/report/${context}`, null, { printerId: printerId || -1 }),
      downloadDeliveryReport: (deliveryCode: string, context: string): Promise<{file: string}> =>
        post(`/api/v1/delivery/${deliveryCode}/download/report/${context}`, null),
    },

    picklistGenerator: {
      print: (id: string, printerId: number): Promise<void> => get(`/api/v1/ppm/print/${id}`, { printerId }),
      kardex: (id: IPickListHeaderId): Promise<void> => post(`/api/v1/ppm/kardex`, id),
      close: (id: IPickListHeaderId): Promise<void> => post(`/api/v1/ppm/close`, id),
      getNextAutoRun: (): Promise<number> => get('/api/v1/ppm/nextAutoRun'),
      getCandidates: (filter: IPicklistCandidatesFilter): Promise<IPicklistGeneratorController$ICandidateGroups> =>
        post(`/api/v1/ppm/getCandidates`, filter),
      createFromSelection: (groups: IPicklistGeneratorController$ICandidateGroups, pickOnly: boolean, printerId: number): Promise<void> =>
        post(`/api/v1/ppm/create/${pickOnly}`, groups, { printerId }),

      isCreationLocked: (): Promise<IPicklistCreationLock> => get(`/api/v1/ppm/allowPicklistCreation`),
      setCreationLock: (enabled: boolean, force: boolean = false) => post(`/api/v1/ppm/allowPicklistCreation/${enabled}/${force}`, {})
    },
  };
};
