import React, { FC, useState } from 'react';
import { useTranslationEditorContext, cachedLanguages, patchLanguageFileCache } from 'utils/language';
import { get } from 'lodash';
import { Button, ButtonGroup, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useApi } from 'utils/API';

interface Props {
  language: string;
  row: string;
}

const EditorComponent: FC<Props> = ({ language, row }) => {
  const [currentValue, setCurrentValue] = useState(null);
  const translationEditor = useTranslationEditorContext();
  const api = useApi();

  const resolvedValue = get(cachedLanguages[language], row, '');
  const patchValue = get(patchLanguageFileCache[language], row, '');

  const saveNewValue = async () => {
    await api.language.updateLanguage({
      language: language,
      path: row,
      value: currentValue,
    });
    translationEditor.updateLanguageKey(language, row, currentValue);
    setCurrentValue(null);
  };

  if (currentValue !== null) {
    return (
      <>
        <Input
          type={'textarea'}
          value={currentValue}
          onChange={(e) => setCurrentValue(e.target.value)}
          onKeyDown={(e) => {
            if (!e.shiftKey && e.key === 'Enter') {
              e.preventDefault();
              e.stopPropagation();
              saveNewValue();
            }
          }}
          data-testid={`language-editor-${language}`}
          placeholder={resolvedValue}
        />
        <div
          style={{
            width: '100%',
          }}
        >
          <ButtonGroup className="d-flex">
            <Button data-testid={`language-editor-${language}-save`} className="w-100" onClick={saveNewValue}>
              <FontAwesomeIcon icon={faCheck} />
            </Button>
            <Button
              data-testid={`language-editor-${language}-cancel`}
              className="w-100"
              onClick={() => {
                setCurrentValue(null);
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
            <Button
              data-testid={`language-editor-${language}-trash`}
              className="w-100"
              onClick={async () => {
                await api.language.updateLanguage({
                  language: language,
                  path: row,
                  value: null,
                });
                translationEditor.updateLanguageKey(language, row, null);
                setCurrentValue(null);
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </ButtonGroup>
        </div>
      </>
    );
  }

  return (
    <span onClick={() => setCurrentValue(patchValue)} className="w-100 d-block">
      {resolvedValue || <>&nbsp;</>}
    </span>
  );
};

export default EditorComponent;
