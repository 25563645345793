import { IBatchCorrectionInstruction } from 'interfaces';
import { Registry } from '.';

export default ({ post }: Registry) => {
  return {
    stockCorrections: {
      batchCorrection: (body: IBatchCorrectionInstruction): Promise<any> => post('/api/v1/stock_corrections/batch_correction', body)
    }
  };
};
