import React, { useContext, useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import { useSetLanguage, useTranslations } from 'utils/language';
import { addNotification, setNotificationDuration } from 'utils/NotificationManager';
import { useConfig, useConfigReloader, useConfigVersion } from 'utils/Config';
import Login from 'components/login';
import DeviceConfiguration from 'components/device_configuration';
import Endpoints from './endpoints';
import { IAuthenticatedUser, ISimpleUserDetails } from 'interfaces';
import { useLocalStorage, HooksHoc, useConstantRef, getFunVarResult, useConsistentState } from 'component_utils/utils';
import moment from 'moment';
import Translation from './Language/Translation';
import { recordAction } from './Analytics';
import { noop } from 'lodash';
import { ReactNotifications } from 'react-notifications-component';
import { alert } from './Prompts';
import qs from 'qs';
import { sessionID } from 'statics/session_identification';

declare global {
  interface Window {
    be_api: IApi
  }
}

const LICENSE_EXPIRATION_GRACE_DAYS = 14;
let hasShownLicenseExpiredPopup = false;
let disableReloadCheck = false;
function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
}

export interface Options {
  maxRetries?: number;
  preventLogout?: boolean;
  expectNetworkError?: boolean;
}

export interface ICurrentDeviceConfig {
  deviceName: string;

  defaultPrinter: number;
  defaultItemLabelPrinter: number;
  defaultLocationLabelPrinter: number;
  defaultDeliveryLabelPrinter: number;

  allowSelectingText: boolean;

  useEmbeddedBarcodeScanner: boolean;
  useVirtualKeyboard: boolean;

  TTSVoice: string;
  TTSRate: number;

  goFullscreenOnFirstInteraction: boolean;
}

export interface IApi extends ReturnType<typeof Endpoints> {
  login: (username: string, password: string, mfaToken: string) => Promise<void>;
  processTransferToken: (token: string) => Promise<void>;
  logout: () => void;
  reload: () => void;
  saveSystemConfiguration: (newSettings: any) => Promise<void>;
}

export interface IDeviceConfigCtx {
  deviceConfig: ICurrentDeviceConfig;
  saveDeviceConfiguration: (rawValue: ICurrentDeviceConfig | ((old: ICurrentDeviceConfig) => ICurrentDeviceConfig)) => void;
  openDeviceSettingsModal: () => void;
}

export type ApiLoaderFun<T> = (api: IApi) => Promise<T>;

const ApiContext = React.createContext({} as IApi);
const DeviceConfigContext = React.createContext({} as IDeviceConfigCtx);
const AuthenticationContext = React.createContext({} as IAuthenticatedUser);
const OpenRequestsContext = React.createContext(0);
const CURRENT_USER_KEY = 'ApiUser';
const CURRENT_DEVICE_CONFIG_KEY = 'DeviceConfigV1';

export const Tests = {
  ApiContext,
  DeviceConfigContext,
  CURRENT_USER_KEY,
  CURRENT_DEVICE_CONFIG_KEY,
};

export function useApi(): IApi {
  return useContext(ApiContext) as IApi;
}

export function useDeviceConfig(): IDeviceConfigCtx {
  return useContext(DeviceConfigContext);
}

export function useAuth(): IAuthenticatedUser {
  return useContext(AuthenticationContext);
}

export function useOpenRequests(): number {
  return useContext(OpenRequestsContext);
}

export function authToSimpleUser(user: IAuthenticatedUser): ISimpleUserDetails {
  return {
    userid: user.userId,
    username: user.username,
  };
}

export function useApiLoader<T>(f: ApiLoaderFun<T>, defaultData: any, immidiateLoad: boolean) {
  const ctx = useApi();
  const [data, setData] = useState<T>(defaultData);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);

  const clear = useCallback(() => {
    setError(false);
    setData(defaultData);
    setIsLoading(false);
    setHasLoaded(false);
  }, [defaultData]);

  const reload = useCallback(async () => {
    setError(false);
    setIsLoading(true);
    try {
      const data = await f(ctx);
      setData(data);
      setIsLoading(false);
      setHasLoaded(true);
    } catch (error) {
      setError(true);
    }
  }, [f, ctx]);

  const lazyLoad = useCallback(() => {
    if (!hasLoaded) {
      reload();
    }
  }, [hasLoaded, reload]);

  useEffect(() => {
    if (immidiateLoad) {
      reload();
    }
  }, [immidiateLoad, reload]);

  return {
    data,
    error,
    isLoading,
    setData,
    reload,
    lazyLoad,
    clear,
  };
}

export function withApi<P extends object>(C: React.ComponentType<P>) {
  return HooksHoc(C, () => {
    return {
      api: useContext(ApiContext),
    };
  });
}

export const ApiProvider: React.FC = ({ children }) => {
  const reloadConfig = useConfigReloader();
  const setLanguage = useSetLanguage();
  const config = useConfig();
  const T = useTranslations();
  const appVersion = useConfigVersion();

  const licenseExpirationDate = moment(config.licenseExpirationDate);
  const licenseIsExpired = licenseExpirationDate.isBefore();
  const daysSinceLicenseExpired = moment().diff(licenseExpirationDate, 'days');

  const [openRequests, openRequestsRef, setOpenRequests] = useConsistentState(0);
  const [initialized, setInitialized] = useState(false);
  const [deviceSettingsDialogOpen, setDeviceSettingsDialogOpen] = useState(false);
  const [currentUser, setCurrentUser] = useLocalStorage<IAuthenticatedUser>(CURRENT_USER_KEY, null);
  const [deviceConfig, setDeviceConfig] = useLocalStorage<ICurrentDeviceConfig>(CURRENT_DEVICE_CONFIG_KEY, null);
  const stableContextProps = useConstantRef({
    appVersion,
    licenseIsExpired,
    daysSinceLicenseExpired,
    reloadConfig,
    T,
  });
  
  // warn the user from leaving if there are open requests
  useEffect(() => {
    if (!currentUser) {
      return
    }

    // only apply this when the user is logged in
    const f = (e: BeforeUnloadEvent) => {
      if (!disableReloadCheck && openRequestsRef.current > 0) {
        e.preventDefault()
        e.returnValue = "There are open network requests"
        return "There are open network requests"
      }
    }
    window.addEventListener('beforeunload', f)
    return () => { 
      window.removeEventListener('beforeunload', f)
    };
  }, [currentUser, openRequestsRef, T]);


  // watchers for value changes
  useEffect(() => {
    if (currentUser) {
      setLanguage(currentUser.userSettings.preferredLanguage);
      setNotificationDuration(currentUser.userSettings.popupDuration);
      if (!hasShownLicenseExpiredPopup && stableContextProps.current.licenseIsExpired) {
        alert(<Translation name="T.warnings.licenseExpired"/>);
        hasShownLicenseExpiredPopup = true;
      }
    }
    setInitialized(true);
  }, [currentUser]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentUser && !deviceConfig) {
      setDeviceSettingsDialogOpen(true);
    }
  }, [currentUser, deviceConfig]);

  useEffect(() => {
    if (!deviceConfig?.allowSelectingText) {
      document.body.classList.add('no-select');
      return () => {
        document.body.classList.remove('no-select');
      };
    }
  }, [deviceConfig]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--linkableHoldDuration',
      `${currentUser?.userSettings?.linkableHoldToOpenDuration || 1500}ms`,
    );
  }, [currentUser]);

  // create the context data for the api
  const ctx = useMemo<IApi>(() => {
    // axios interceptor initialization
    const instance = axios.create({
      baseURL: '',
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
      headers: {
        Accept: 'application/json',
      },
    });

    // generate the registry
    const get = async (url: string, params?: any, options?: Options) =>
      (await instance.get(url, { params, beOptions: options } as any)).data;
    const post = async (url: string, data: any, params?: any, options?: Options) =>
      (await instance.post(url, data, { params, beOptions: options } as any)).data;
    const destroy = async (url: string, params?: any, options?: Options) =>
      (await instance.delete(url, { params, beOptions: options } as any)).data;
    const endpoints = Endpoints({ get, post, destroy });

    const login = async (username: string, password: string, mfaToken: string) => {
      const result = (
        await instance.post(
          '/api/v1/auth/signin',
          {
            username,
            password,
            mfaToken
          }
        )
      ).data as IAuthenticatedUser;
      recordAction([{
        application: "",
        actionType: 'login',
        modelVersion: 0,
        fieldValues: [moment().toDate()]
      }]);
      setCurrentUser(result);
    };

    const processTransferToken = async (token: string) => {
      const result = (
        await instance.post('/api/v1/auth/transfer_token/use', { token })
      ).data as IAuthenticatedUser;
      setCurrentUser(result);
    }

    const logout = async () => {
      disableReloadCheck = true;
      recordAction([{
        application: "",
        actionType: 'logout',
        modelVersion: 0,
        fieldValues: [moment().toDate()]
      }]);
      await post('/api/v1/auth/logout', {});
      setCurrentUser(null);
      window.location.reload();
    };

    const reload = async () => {
      stableContextProps.current.reloadConfig();
      setCurrentUser(await endpoints.users.getMe());
    };

    // define the interceptors
    instance.interceptors.request.use((config) => {
      setOpenRequests((old) => old + 1);
      return config;
    });
    instance.interceptors.response.use(
      (response) => {
        setOpenRequests((old) => old - 1);
        return response;
      },
      (error) => {
        setOpenRequests((old) => old - 1);
        return Promise.reject(error);
      },
    );

    instance.interceptors.response.use(
      (response: any) => {
        return response;
      },
      (error: any) => {
        // correct the configurations
        error.config.beRuntimeData = {
          retryCount: 0,
          ...(error.config.beRuntimeData || {}),
        };
        error.config.beOptions = {
          maxRetries: 0,
          preventLogout: false,
          expectNetworkError: false,
          ...(error.config.beOptions || {}),
        };

        const isLoginRequest = error.config.url === '/api/v1/auth/signin'
        const suppressNotifications = isLoginRequest
        const localAddNotification: typeof addNotification = suppressNotifications ? noop : addNotification

        // check the error type
        if (!error.response) {
          if (error.config.beOptions.expectNetworkError) {
            return Promise.reject(error);
          }

          // network error
          error.config.beRuntimeData.retryCount = error.config.beRuntimeData.retryCount + 1;
          if (
            error.config.beOptions.maxRetries &&
            error.config.beRuntimeData.retryCount > error.config.beOptions.maxRetries
          ) {
            localAddNotification(
              'danger',
              'Error',
              <Translation name="T.errors.networkErrorTooManyRetries" />,
            );
          } else {
            return new Promise((resolve) => {
              setTimeout(() => resolve(instance.request(error.config)), 1000);
            });
          }
        } else if (error.response.status === 401) {
          // only act on this if this was on something else than a login
          if (error.config.beOptions.preventLogout) {
            localAddNotification(
              'danger',
              'Error',
              <Translation name="T.errors.invalidLoginDetails" />,
            );
          } else if (error.config.url !== '/api/v1/auth/signin') {
            logout();
          }
        } else if (error.response.status === 403) {
          localAddNotification(
            'danger',
            'Error',
            <Translation name="T.errors.requestForbidden" />,
          );
        } else if (error.response.status === 500) {
          localAddNotification(
            'danger',
            'Error',
            <Translation name={error.response.data.message} params={error.response.data.args} />,
          );
        } else if (error.response.status === 410) {
          window.location.reload();
        } else if (error.response.status === 412) {
          // outdated entity information
        } else {
          localAddNotification(
            'danger',
            'Error',
            <Translation name="T.errors.unknownNetworkError" params={{ err: error.response.status }} />,
          );
        }
        return Promise.reject(error);
      },
    );

    instance.interceptors.request.use((_config: any) => {
      let licenseIsExpired = stableContextProps.current.licenseIsExpired
      let daysSinceExpiration = stableContextProps.current.daysSinceLicenseExpired
      if (isNaN(daysSinceExpiration)) {
        licenseIsExpired = true
        daysSinceExpiration = LICENSE_EXPIRATION_GRACE_DAYS / 2
      }

      // potentially show warning
      if (
        licenseIsExpired &&
        getRandomInt(0, Math.max(0, LICENSE_EXPIRATION_GRACE_DAYS - daysSinceExpiration)) === 0
      ) {
        addNotification(
          'warning',
          'License',
          <Translation name="T.warnings.licenseExpired" />,
        );
      }

      _config.headers.AppVersion = stableContextProps.current.appVersion;
      _config.headers.UniqueSession = sessionID;
      return _config;
    });

    const resolved = {
      /**
       * Session methods
       */
      login,
      processTransferToken,
      logout,
      reload,

      /**
       * Load the endpoints
       */
      ...endpoints,
    };
    window.be_api = resolved
    return resolved
  }, [setCurrentUser, setOpenRequests, stableContextProps]);

  const deviceConfigContext = useMemo<IDeviceConfigCtx>(() => {
    /**
     * device configuration
     */
    return {
      openDeviceSettingsModal: () => setDeviceSettingsDialogOpen(true),
      saveDeviceConfiguration: (rawValue: ICurrentDeviceConfig | ((old: ICurrentDeviceConfig) => ICurrentDeviceConfig)) => {
        setDeviceConfig(old => getFunVarResult(rawValue, old));
        setDeviceSettingsDialogOpen(false);
      },
      deviceConfig,
    };
  }, [setDeviceSettingsDialogOpen, setDeviceConfig, deviceConfig]);

  // wait for initial loads
  if (!initialized) return null;
  return (
    <ApiContext.Provider value={ctx}>
      <DeviceConfigContext.Provider value={deviceConfigContext}>
        <AuthenticationContext.Provider value={currentUser}>
          <OpenRequestsContext.Provider value={openRequests}>
            <ReactNotifications />

            {currentUser && deviceConfig && children}

            <Login show={!Boolean(currentUser)} deviceName={deviceConfig?.deviceName} />
            <DeviceConfiguration show={deviceSettingsDialogOpen} deviceConfig={deviceConfig} />
          </OpenRequestsContext.Provider>
        </AuthenticationContext.Provider>
      </DeviceConfigContext.Provider>
    </ApiContext.Provider>
  );
};
