import React, { useState, useRef, useEffect, CSSProperties, FC, MutableRefObject } from 'react';
import ReactDOM from 'react-dom';
import { Button, Input, InputGroup, InputGroupAddon, Progress } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode, faTimes } from '@fortawesome/free-solid-svg-icons';
import { BrowserMultiFormatReader, DecodeHintType, BarcodeFormat } from '@zxing/library';
import { klet, useConsistentState, useRefInitOnce } from 'component_utils/utils';
import beep from './beep.mp3'
import useSound from 'use-sound';
import { BsCardText } from 'react-icons/bs';
import SimpleModal from 'components/SimpleModal';
import Translation from 'utils/Language/Translation';
import { getOcrWorker } from 'component_utils/OCR';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { BiSpaceBar } from 'react-icons/bi'

const OcrModule: FC<{ videoRef: MutableRefObject<HTMLVideoElement>; onScan: (s: string) => any }> = ({
  videoRef, onScan
}) => {
  const [what, setWhat] = useState('');
  const [progress, setProgress] = useState(0.0);
  const [bboxes, setbboxes] = useState<{
    bbox: Tesseract.Bbox
    text: string
  }[]>([])
  const canvasRef = useRef<HTMLCanvasElement>()
  const [value, setValue] = useState('')

  return (
    <SimpleModal
      title={<Translation name="T.misc.ocr"/>}
      trigger={open => (
        <div
          onClick={() => {
            setValue('')
            setbboxes([])
            open()
          }}
          style={{
            bottom: '5px',
            right: '5px',
            position: 'absolute',
            height: '3em',
            width: '3em',
          }}
        >
          <BsCardText color='white' className='w-100 h-100'/>
        </div>
      )}
      onOpened={async () => {
        const canvas = canvasRef.current
        const context = canvas.getContext('2d')
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        context.drawImage(videoRef.current, 0, 0, videoRef.current.videoWidth, videoRef.current.videoHeight);
        const image = canvas.toDataURL('image/png')

        // await use_external_library_async(OPENCV)
        // let src = cv.imread(canvas);
        // let dst = new cv.Mat();
        // let ds2 = new cv.Mat();
        // cv.cvtColor(src, dst, cv.COLOR_BGR2GRAY)
        // cv.adaptiveThreshold(dst, ds2, 250, cv.ADAPTIVE_THRESH_MEAN_C, cv.THRESH_BINARY, 3, 3)
        // cv.imshow(canvas, ds2);
        // src.delete();
        // dst.delete();
        // ds2.delete();
        console.time("get ocr")
        const workerState = await getOcrWorker((status, progress) => {
          setWhat(status);
          setProgress(progress);
        })
        console.timeEnd("get ocr")
        const result = await workerState.worker.recognize(image);
        const bboxes: {
          bbox: Tesseract.Bbox
          text: string
        }[] = []
        result.data.words.forEach(function (w) {
          var b = w.bbox;
          context.lineWidth = 2;
          context.strokeStyle = 'red';
          context.strokeRect(b.x0, b.y0, b.x1 - b.x0, b.y1 - b.y0);
          context.beginPath();
          context.moveTo(w.baseline.x0, w.baseline.y0);
          context.lineTo(w.baseline.x1, w.baseline.y1);
          context.strokeStyle = 'green';
          context.stroke();

          bboxes.push({
            bbox: b,
            text: w.text
          })
        });
        setbboxes(bboxes)
      }}
    >
      {close => (
        <div className='flex-container'>
          <Progress min={0} value={progress * 100} max={100} color={'info'}>
            {what}: {(progress * 100).toFixed(0)} %
          </Progress>
          <div className='nested-flex-container'>
            <canvas ref={canvasRef} className='w-100' onClick={(e) => {
              const r = canvasRef.current.getBoundingClientRect();
              const ratio = canvasRef.current.clientWidth / canvasRef.current.width
              const x = (e.clientX - r.left) / ratio
              const y = (e.clientY - r.top) / ratio

              for (const bbox of bboxes) {
                if (x >= bbox.bbox.x0 && x <= bbox.bbox.x1 && y >= bbox.bbox.y0 && y <= bbox.bbox.y1) {
                  setValue(old => `${old}${bbox.text}`)
                  return
                }
              }
            }}/>
          </div>
          <InputGroup>
            <Input value={value} onChange={e => setValue(e.target.value)}/>
            <InputGroupAddon addonType="append">
              <Button color='danger' onClick={() => setValue('')}><FaTimes/></Button>
              <Button color='info' onClick={() => setValue(old => old + ' ')}><BiSpaceBar/></Button>
              <Button color='success' onClick={() => {
                onScan(value)
                close()
              }}><FaCheck/></Button>
            </InputGroupAddon>
          </InputGroup>
        </div>
      )}
    </SimpleModal>
  )
}

export default ({ onScan }: { onScan: (s: string) => any }) => {
  const [playBeep] = useSound(beep)
  const [visible, setVisible] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [shouldRecordBarcodes, shouldRecordBarcodesRef, setShouldRecordBarcodes] = useConsistentState(false)
  const readerRef = useRefInitOnce(() => {
    const hints = new Map();
    hints.set(DecodeHintType.POSSIBLE_FORMATS, [
      BarcodeFormat.CODE_39,
      BarcodeFormat.CODE_128,
      BarcodeFormat.EAN_8,
      BarcodeFormat.EAN_13,
      BarcodeFormat.UPC_A,
      BarcodeFormat.UPC_E,
      BarcodeFormat.QR_CODE,
    ]);
    hints.set(DecodeHintType.TRY_HARDER, true);
    const reader = new BrowserMultiFormatReader(null, 250);
    console.log('init barcode reader\n');
    return {
      reader,
      stopTimeout: null,
      isRunning: false,
    };
  });

  if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
    return null;
  }

  const rootStyle: CSSProperties = {
    display: visible ? 'block' : 'none',
    position: 'fixed',
    background: 'black',
    zIndex: 1050,
    marginLeft: 'auto',
    marginRight: 'auto',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)'
  };

  const stopCamera = (withTimeout: boolean) => {
    const f = () => {
      readerRef.current.isRunning = false;
      readerRef.current.reader.reset();
    }

    klet(readerRef.current.stopTimeout, (it) => clearTimeout(it))
    readerRef.current.stopTimeout = null
    if (withTimeout) {
      readerRef.current.stopTimeout = setTimeout(f, 10_000);
    } else {
      f()
    }
    setVisible(false);
    setShouldRecordBarcodes(false);
  };

  const startCamera = async () => {
    // const inputDevices = await readerRef.current.listVideoInputDevices();
    // console.log(inputDevices)
    // inputDevices.forEach(device =>
    //   console.log(`${device.label}, ${device.deviceId}`)
    // );
    // const firstDeviceId = inputDevices[0].deviceId;
    klet(readerRef.current.stopTimeout, (it) => clearTimeout(it))
    readerRef.current.stopTimeout = null

    if (!readerRef.current.isRunning) {
      let lastScan = { t: new Date(), text: '' };
      await readerRef.current.reader.decodeFromVideoDevice(undefined, videoRef.current, async (result) => {  
        if (result && shouldRecordBarcodesRef.current) {
          const text = result.getText();
          const now = new Date();
          if (lastScan.text === text && (now.getTime() - lastScan.t.getTime()) / 1000 < 1.0) {
            return;
          }
          playBeep()
          await onScan(text);
          setShouldRecordBarcodes(false);
          lastScan.text = text;
          lastScan.t = now;
        }
      });
      const width = Math.min(400, window.innerWidth * 0.75);
      videoRef.current.width = width
      readerRef.current.isRunning = true
    }
    setVisible(true);
  };

  useEffect(() => {
    return () => {
      stopCamera(false);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Button
        innerRef={buttonRef}
        color="primary"
        onClick={startCamera}
        disabled={visible}
        data-testid="openCamera"
        data-prevent-auto-focus="true"
      >
        <FontAwesomeIcon icon={faBarcode} />
      </Button>

      {ReactDOM.createPortal(
        <div data-prevent-auto-focus="true" style={rootStyle}>
          <video autoPlay={true} ref={videoRef} />

          <FontAwesomeIcon
            icon={faTimes}
            size="2x"
            onClick={() => stopCamera(true)}
            style={{
              background: 'white',
              top: '5px',
              right: '5px',
              position: 'absolute',
            }}
          />

          <div
            onTouchStart={() => {
              setShouldRecordBarcodes(true)
            }}
            onMouseDown={() => {
              setShouldRecordBarcodes(true)
            }}
            onTouchEnd={() => {
              setShouldRecordBarcodes(false)
            }}
            onMouseUp={() => {
              setShouldRecordBarcodes(false)
            }}
            style={{
              bottom: '5px',
              left: '50%',
              transform: 'translateX(-50%)',
              position: 'absolute',
              borderRadius: '50%',
              backgroundColor: shouldRecordBarcodes ? 'green' : 'red',
              height: '3em',
              width: '3em',
              border: 'solid #670000 3px'
            }}
          />

          <OcrModule videoRef={videoRef} onScan={onScan}/>
        </div>,
        document.body,
      )}
    </>
  );
};
