interface WorkerState {
  listener: (status: string, progress: number) => any;

  worker: Tesseract.Worker;
  logger: (message: Tesseract.LoggerMessage) => any;
}

let OCRWorkerIsLoading: Promise<WorkerState> = null
export const getOcrWorker = (listener: (status: string, progress: number) => any): Promise<WorkerState> => {
  if (OCRWorkerIsLoading) {
    return OCRWorkerIsLoading.then(it => {
      it.listener = listener
      return it
    })
  }
  OCRWorkerIsLoading = new Promise(async resolve => {
    const { createWorker } = await import('tesseract.js');
    const workerState: WorkerState = {
      listener: listener,
      worker: null,
      logger: function (message: Tesseract.LoggerMessage) {
        this.listener(message.status, message.progress)
      }
    }
  
    workerState.worker = await createWorker({
      workerPath: '/tesseract/worker.min.js',
      corePath: '/tesseract/',
      langPath: '/tesseract/languages',
      logger: (m) => workerState.logger(m),
    });
    await workerState.worker.loadLanguage('eng');
    await workerState.worker.initialize('eng');
    resolve(workerState);
  })
  return OCRWorkerIsLoading
}