import React, { FC, ReactNode } from 'react';
import { useJsxTranslations } from 'utils/language';

interface TranslationInterface {
  name: string;
  params?: { [x: string]: ReactNode };
  stringsOnly?: boolean;
}

const Translation: FC<TranslationInterface> = ({ name, params = {}, stringsOnly = false }) => {
  const T = useJsxTranslations();
  return <>{T(name, { ...(params || {}), enter: <br /> })}</>;
};

export default Translation;
