import { Function0 } from "lodash"

const migrations: Function0<any>[] = [
  () => {
    console.log("Test migration 1")
  },
]

export default () => {
  console.log("[MIGRATIONS] Start")
  let lastProcessedIndex = parseInt(localStorage.getItem('__BE_MIGRATIONS_STATE__') || '0')
  for (; lastProcessedIndex < migrations.length; lastProcessedIndex++) {
    const migration = migrations[lastProcessedIndex]
    try {
      migration?.()
    } catch (e) {
      console.error(e)
    }
  }
  localStorage.setItem('__BE_MIGRATIONS_STATE__', lastProcessedIndex.toString())
  console.log("[MIGRATIONS] Stop")
}