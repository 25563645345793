import React from 'react'
import { InputPeripheral } from "utils/PeripheralManager"
import { sleep } from 'component_utils/utils'

const MaintenanceComponent = ({device}: {device: TestScale}) => {
  return (<></>)
}

// _be_peripheral_input_{"usage":"scale","driverName":"TEST","deviceName":"test","settings":{"t": 500, "v":"123.23kg"}}
class TestScale implements InputPeripheral<TestScale> {
  _isConnected: boolean;
  _config: any;

  setConfig(config: any) {
    this._config = config
  }
  getDriverName = () => 'TEST'

  connect = async (data: any) => {
    this._isConnected = true;
  }
  disconnect = async () => {
    this._isConnected = false;
  }

  isConnected = async () => {
    return Promise.resolve(this._isConnected)
  }

  getValue = async () => { 
    await sleep(this._config.t || 1000)
    return this._config.v || "2  kg"
  }

  getMaintenanceComponent = () => MaintenanceComponent
}

(TestScale as any)._do_not_register_device = true

export default TestScale