import {
  IGenericKardexService$ICompanion$IKardexDataRow,
  IKardexBox,
  IKardexSettings,
  IMarkStockInKardexBox,
} from 'interfaces';
import { Registry } from '.';

export default ({ get, post, destroy }: Registry) => {
  return {
    kardex: {
      markStockInKardex: (data: IMarkStockInKardexBox) => post('/api/v1/kardex/kardexContainer', data),
      getKardexBoxesOnLocation: (bincode: string): Promise<IKardexBox[]> =>
        get(`/api/v1/kardex/kardexContainer/location/${bincode}`),
      deleteKardexBoxesOnLocation: (bincode: string): Promise<IKardexBox[]> =>
        destroy(`/api/v1/kardex/kardexContainer/location/${bincode}`),
      deleteKardexBoxData: (kardexBoxCode: string): Promise<void> =>
        destroy(`/api/v1/kardex/kardexContainer/${kardexBoxCode}`),

      pushKardex: (
        fromBinCode: string,
        toBinCode: string,
        kardexLines: IGenericKardexService$ICompanion$IKardexDataRow[],
      ): Promise<void> => post(`/api/v1/kardex/storeKardex/${fromBinCode}/${toBinCode}`, kardexLines),

      settings: {
        get: (id: string): Promise<IKardexSettings> => get(`/api/v1/kardex/admin/${id}`),
        test: (kardex: IKardexSettings): Promise<void> => post('/api/v1/kardex/admin/test', kardex),
        save: (kardex: IKardexSettings): Promise<void> => post('/api/v1/kardex/admin/save', kardex),
        delete: (id: string): Promise<void> => destroy(`/api/v1/kardex/admin/${id}`),
      },
    },
  };
};
