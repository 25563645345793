import FileSaver from 'file-saver'
import React from 'react'
import { PeripheralPrinter } from "utils/PeripheralManager"

const MaintenanceComponent = ({device}: {device: DownloadPrint}) => {
  return (
    <>
      
    </>
  )
}

class DownloadPrint implements PeripheralPrinter<DownloadPrint> {
  getDriverName = () => 'DownloadPrint'

  connect = async (data: any) => {}

  disconnect = async () => {}

  isConnected = async () => { return true }

  print = async (mediatype: string, payload: string) => { 
    const data = Uint8Array.from(
      atob(payload as any).split('').map(char => char.charCodeAt(0))
    );  
    const file = new Blob([data], {type: mediatype});
    FileSaver.saveAs(file);
  }

  getMaintenanceComponent = () => MaintenanceComponent
}

export default DownloadPrint