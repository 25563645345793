import React from 'react';
import UncontrolledTabs from 'components/uncontrolled_tabs';
import Model from 'components/objects/model';
import PropertyList from 'components/property_list';
import { IWarehouse } from 'interfaces';
import Translation from 'utils/Language/Translation';
import ProcessingButton from 'components/ProcessingButton';
import { FaCopy } from 'react-icons/fa';
import { sleep } from 'component_utils/utils';
import RoleProtected from 'components/RoleProtected';

interface Props {
  inline?: boolean;
  obj: IWarehouse | null;
}

const Warehouse = ({ obj: whs, inline }: Props) => {
  const shortDescription = () => {
    return `${whs!.visualCode} - ${whs!.name}`;
  };

  const detailedRender = () => {
    return (
      <UncontrolledTabs
        key={whs!.code}
        tabs={[
          {
            name: <Translation name="T.misc.information" />,
            content: (
              <PropertyList
                rows={[
                  [
                    <b>
                      <Translation name="T.objects.warehouse.code" />
                    </b>,
                    <>
                      {whs!.visualCode}
                      {' '}
                      <RoleProtected roles={['authCopyObjectCodes']}>
                        <ProcessingButton color="link" className='p-0' onClick={async () => {
                          navigator.clipboard.writeText(whs!.visualCode)
                          await sleep(100)
                        }}>
                          <FaCopy/>
                        </ProcessingButton>
                      </RoleProtected>
                    </>,
                  ],
                  [
                    <b>
                      <Translation name="T.objects.warehouse.name" />
                    </b>,
                    whs!.name,
                  ],
                ]}
              />
            ),
          },
        ]}
      />
    );
  };

  const dialog = {
    titleText: <Translation name="T.objects.warehouse.warehouseDetails" />,
    closeBtnText: <Translation name="T.misc.close" />,
    content: () => <Warehouse obj={whs} />,
  };
  return (
    <Model
      inline={inline}
      hasLoaded={whs}
      copyValue={() => whs.visualCode}
      description={shortDescription}
      detailedRender={detailedRender}
      dialog={dialog}
    />
  );
};

export default Warehouse;
