import { IWebSocketMessage } from 'interfaces';
import React, { FC } from 'react'
import { sessionID } from 'statics/session_identification';
import { useAuth } from 'utils/API';
import Translation from 'utils/Language/Translation';
import { addNotification } from 'utils/NotificationManager';
import { Socket } from 'utils/Socket';

type Props = {
  print: (
    printerId: number, 
    printerName: string,
    peripheralType: string,
    mediaType: string,
    payload: string
  ) => Promise<void>
}

const PeripheralPrinterListener: FC<Props> = ({ print }) => {
  const auth = useAuth()
  const topic = '/topic/peripherals/printing/' + sessionID
  return (
    <Socket
      topics={[topic]}
      onMessage={(topic, msg: IWebSocketMessage<any>) => {
        if (msg.what === 'USER_CONNECTED' && msg.who.userid !== auth.userId) {
          addNotification('warning', <Translation name="T.warnings.anotherUserAlsoRegisteredTheSamePrinter" params={{ name: msg.who.username }}/>)
          return
        }

        if (msg.what === 'PRINT_JOB') {
          if (document.visibilityState === 'hidden') {
            console.log("only print if current tab is active")
            return
          }
  
          const printerId = msg.payload.printer
          const printerName = msg.payload.printerName
          const peripheralType = msg.payload.peripheralType
          const mediatype = msg.payload.mediaType
          const payload = msg.payload.payload
  
          if (!peripheralType) {
            console.log("weird message came in?", topic, msg)
            return
          }
          
          print(printerId, printerName, peripheralType, mediatype, payload)
        }
      }}
    />

  )
}

export default PeripheralPrinterListener