import { CostumerIdMappings, SpecializationPoints } from 'specialization';
import React, { FC, useContext, useState } from 'react';
import { useConfig } from './Config';
import { useEffectAsync } from 'component_utils/utils';
import { Spinner } from 'reactstrap';
import logo from 'images/beelogo.png';
import GenericSpecializations from 'specialization/generic';

const SpecializationContext = React.createContext<SpecializationPoints>(GenericSpecializations);

export function useSpecializations() {
  return useContext(SpecializationContext);
}

export const SpecializationProvider: FC = ({ children }) => {
  const customerId = useConfig().customerId;
  const [specializations, setSpecializations] = useState<SpecializationPoints>(null)
  useEffectAsync(async () => {
    const specializationLoader = CostumerIdMappings[customerId]
    let loadedSpecialization = GenericSpecializations
    if (specializationLoader) {
      const module = await (specializationLoader())
      loadedSpecialization = module.default as SpecializationPoints
    }

    console.log("Loaded specialization with identifier '" + loadedSpecialization.identifier + "'")
    setSpecializations(loadedSpecialization)
  }, [customerId])


  let content = children;
  if (!specializations) {
    const size = '7rem';
    const centeredDivProps: any = {
      style: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      },
    };  
    content = (
      <>
        <div {...centeredDivProps}>
          <Spinner style={{ width: size, height: size }} type="grow" color="primary" />
        </div>
        <div {...centeredDivProps}>
          <img src={logo} className="brandLogo" alt="BeEfficient logo" />
        </div>
      </>
    )
  }
  return (
    <SpecializationContext.Provider value={specializations}>
      {content}
    </SpecializationContext.Provider>
  );
};
