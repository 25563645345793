import React, { useContext, ReactNode, useMemo, useEffect, useRef } from 'react';
import { Store } from 'react-notifications-component';
import warnSfx from 'audio/alarm.mp3';
import { AddErrorToLog } from 'components/ErrorHistoryOverview';

type ColorTypes = 'success' | 'danger' | 'info' | 'default' | 'warning';

type Options = {
  centered?: boolean
  persistent?: boolean
}
export type TAddNotification = (type: ColorTypes, title: ReactNode, msg?: ReactNode, options?: Options) => void;
export interface INotificationContext {
  add: TAddNotification;
  setDuration: (duration: number) => void;
}

const NotificationContext = React.createContext<INotificationContext>({} as INotificationContext);

export const Tests = {
  NotificationContext,
};

export function useNotifications(): INotificationContext {
  return useContext(NotificationContext);
}

//
let duration = 5000;
export const setNotificationDuration = (d: number) => (duration = d);
export const addNotification: TAddNotification = (type, title, msg?, options?) => {
  Store.addNotification({
    title: (
      <Notification type={type} title={title} msg={msg}>
        {title}
      </Notification>
    ),
    message: msg || <div />,
    type: type,
    insert: 'top',
    container: options?.centered ? 'center' : 'top-right',
    animationIn: ['animated', 'fadeInRight'],
    animationOut: ['animated', 'fadeOutRight'],
    dismiss: options?.persistent ? null : {
      duration,
      pauseOnHover: true,
      onScreen: true,
    },
  });
};

const Notification: React.FC<any> = ({ children, type, title, msg }) => {
  const titleRef = useRef<HTMLSpanElement>(null);
  useEffect(() => {
    const container = titleRef.current.parentElement.parentElement;
    const titleText = (container.querySelector('.rnc__notification-title') as any)?.innerText || '';
    const msgText = (container.querySelector('.rnc__notification-message') as any)?.innerText || '';
    const appName = document.getElementById('current-app-name')?.innerText || '';
    AddErrorToLog(appName, type, title, titleText, msg, msgText);

    // play a noise
    if (type === 'danger') {
      const audio = new Audio(warnSfx);
      audio.play();
    }
  }, [type, title, msg]);

  return <span ref={titleRef}>{children}</span>;
};

export const NotificationManager: React.FC = ({ children }) => {
  const ctx = useMemo(() => {
    return {
      add: addNotification,
      setDuration: setNotificationDuration,
    };
  }, []);

  return <NotificationContext.Provider value={ctx}>{children}</NotificationContext.Provider>;
};
