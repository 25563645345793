import { IScriptedExactOnlineConfiguration } from 'interfaces';
import { Registry } from '.';

export default ({ get, post, destroy }: Registry) => {
  return {
    exactOnline: {
      downloadBrowserExtension: (): Promise<any> => get('/api/v1/exact_online_extension/latest_extension_bundle'),
      browserExtensionConfig: {
        scripting: {
          getAll: (): Promise<{ script: IScriptedExactOnlineConfiguration, loaded: boolean }[]> => get('/api/v1/exact_online_extension/config/scripts'),
          save: (script: IScriptedExactOnlineConfiguration) => post('/api/v1/exact_online_extension/config/scripts', script),
          delete: (id: number) => destroy(`/api/v1/exact_online_extension/config/scripts/${id}`),
        }
      }
    },
  };
};
