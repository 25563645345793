import React, { PropsWithChildren, ReactNode } from 'react';
import { Badge, Collapse, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';
import ExpandedIndicator from 'components/ExpandedIndicator';
import { IconType } from 'react-icons/lib';

type IProps = PropsWithChildren<{
  padding: string;
  icon: IconType;
  title: ReactNode;
  dataTestId: string;
  notifications: number;

  opened: boolean;
  setOpened: (b: boolean) => any;
}>;

export default ({ icon: Icon, title, dataTestId, padding, children, notifications, opened, setOpened }: IProps) => {
  const toggleNavbar = () => {
    setOpened(!opened);
  };

  return (
    <div>
      <NavItem
        data-prevent-auto-focus="true"
        onClick={toggleNavbar}
        style={{ paddingLeft: padding }}
        className={classNames({ 'menu-open': opened })}
      >
        <NavLink className="position-relative" data-testid={dataTestId} href="#">
          <Icon className="mr-2" />
          {title}
          <ExpandedIndicator isExpanded={opened} />
          {notifications > 0 && (
            <Badge
              color="danger"
              className="position-absolute"
              style={{
                right: '1rem',
                top: '50%',
                transform: 'translateY(-50%)',
              }}
            >
              {notifications}
            </Badge>
          )}
        </NavLink>
      </NavItem>
      <Collapse isOpen={opened} className={classNames('items-menu')}>
        {children}
      </Collapse>
    </div>
  );
};
