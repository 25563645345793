import React, { CSSProperties } from 'react';
import ReactDOM from 'react-dom';

const WatermarkOverlay: React.FC<{
  xOffset: string;
  yOffset: string;
  style?: CSSProperties;
}> = ({ children, xOffset, yOffset, style }) => {
  return ReactDOM.createPortal(
    <div
      style={{
        pointerEvents: 'none',
        opacity: '0.5',
        color: 'black',
        position: 'absolute',
        bottom: yOffset,
        right: xOffset,
        zIndex: 999999999,
        ...(style || {}),
      }}
    >
      {children}
    </div>,
    document.body,
  );
};

export default WatermarkOverlay;
