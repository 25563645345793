import React, { FC, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { Socket, SocketManager, SocketSubscriber } from './Socket'
import useResizeDetector from 'components/useResizeDetector';
import SimpleModal from 'components/SimpleModal';
import Translation from './Language/Translation';
import ProcessingButton from 'components/ProcessingButton';
import FileSaver from 'file-saver';
import { Document, Page } from 'react-pdf';
import { range } from 'lodash';
import { useHistory } from 'react-router';
import { useApi } from './API';
import { addNotification } from './NotificationManager';

export const PdfPreview: FC<{ pdf: any; setPdf: any }> = ({ pdf, setPdf }) => {
  const [numPages, setNumPages] = useState<number>(null);
  const pdfSource = useMemo(() => `data:application/pdf;base64,${pdf}`, [pdf])
  const { width, ref: sizeRef } = useResizeDetector({
    handleHeight: false,
    handleWidth: true,
    refreshMode: 'debounce',
    refreshRate: 500,
  });

  return (
    <SimpleModal
      fullscreen={true}
      open={!!pdf}
      title={<Translation name="T.misc.pdf" />}
      onClose={() => {
        setPdf(null)
      }}
      buttons={(close) => [
        <ProcessingButton color="info" onClick={async () => {
          const data = Uint8Array.from(
            atob(pdf).split('').map(char => char.charCodeAt(0))
          );  
          const file = new Blob([data], {type: 'application/pdf'});
          FileSaver.saveAs(file, 'test.pdf');
        }} data-testid={'script-pdf-download'}>
          <Translation name="T.misc.download" />
        </ProcessingButton>,
        <ProcessingButton color="secondary" onClick={close} data-testid={'simple-modal-secondary'}>
          <Translation name="T.misc.close" />
        </ProcessingButton>,
    ]}
    >
      <div
        id="testReportPdfContainer"
        ref={sizeRef}
        style={{
          width: '100%',
          height: '100%',
          padding: '10px',
          background: 'gray',
          overflow: 'auto',
        }}
      >
        <Document file={pdfSource} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
          {range(numPages).map((i) => (
            <Page key={i} pageIndex={i} width={width || 100} />
          ))}
        </Document>
      </div>
    </SimpleModal>
  )
}

export interface LoginScreenSocketRef {
  onRequestDeviceSession: (deviceName: String) => void;
}

interface IPRops { 
  deviceName: string; 
  setUnauthedSocketConnected: any
}

export default React.forwardRef<LoginScreenSocketRef, IPRops>(({ deviceName, setUnauthedSocketConnected }, ref) => {
  const [pdf, setPdf] = useState()
  const api = useApi()
  const router = useHistory()
  const socket = useRef<SocketSubscriber>();
  
  const topics = []
  if (deviceName) {
    topics.push(`/device_listener/${deviceName}`)
  }

  useImperativeHandle(
    ref,
    () => ({
      onRequestDeviceSession: (otherDeviceName) => {
        if (deviceName) {
          socket.current.sendMessage(`/device_listener/${otherDeviceName}`, {
            what: 'REQUEST_SESSION_TAKEOVER',
            who: null,
            payload: { deviceName },
          });  
        } else {
          addNotification('danger', <Translation name="T.errors.canNotTakeOverSessionToUnnamedDevice"/>)
        }
      }
    }),
    [deviceName, socket],
  );

  return (
    <SocketManager>
      <Socket
        ref={socket}
        topics={topics}
        onConnect={() => setUnauthedSocketConnected(true)}
        onDisconnect={() => setUnauthedSocketConnected(false)}
        onMessage={async (topic, msg) => {
          if (document.visibilityState === 'hidden') {
            console.log("only act if current tab is active")
            return
          }

          if (msg.what === 'PREVIEW_PDF') {
            await import('react-pdf/dist/esm/entry.webpack')
            setPdf(msg.payload)
          }
          if (msg.what === 'TRANSFER_LOGIN') {
            await api.processTransferToken(msg.payload.token)
            router.push(msg.payload.data.path)
          }
        }}
      />
      
      <PdfPreview pdf={pdf} setPdf={setPdf}/>
    </SocketManager>
  )
})