import React, {
  useState,
  useEffect,
  ReactNode,
  FC,
  createContext,
  Children,
  isValidElement,
  cloneElement,
  useContext,
  useImperativeHandle,
  Ref,
} from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

import './style.css';
import { getRenderable, useStable } from 'component_utils/utils';
import { isFunction } from 'lodash';
import { FaPlus, FaTimes } from 'react-icons/fa';

export interface ITab {
  name: ReactNode;
  content: ReactNode;

  hidden?: boolean;
  dataTestId?: string;
  onFirstOpen?: () => void;
}

interface IProps<EP> {
  props?: EP;
  onAdd?: () => any;
  onDelete?: (index: number) => any;
  tabs: ITab[];
}

export interface UncontrolledTabsRef {
  activeTab: number;
  setActiveTab: (tab: number) => void;
}

export const UncontrolledTabsContext = createContext({
  activeTab: 0,
});

type UncontrolledTabsProps = {
  tabsRef?: Ref<UncontrolledTabsRef>;
  onAdd?: () => any;
  onDelete?: (index: number) => any;
  className?: string;
};

export const UncontrolledTabs: FC<UncontrolledTabsProps> = ({ tabsRef, children, onAdd, onDelete, className = "flex-container" }) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const noChildren = Children.count(children);
  useImperativeHandle(
    tabsRef,
    () => ({
      activeTab,
      setActiveTab,
    }),
    [activeTab, setActiveTab],
  );

  useEffect(() => {
    if (activeTab >= noChildren) {
      setActiveTab(Math.max(0, noChildren - 1));
    }
  }, [setActiveTab, activeTab, noChildren]);

  return (
    <UncontrolledTabsContext.Provider value={{ activeTab }}>
      <div className={className}>
        <div className="tab-scroll">
          <Nav tabs>
            {Children.map(children, (child, index) => {
              if (!isValidElement(child)) {
                return null;
              }
              if (child.props.hidden) {
                return null;
              }
              return (
                <NavItem key={index}>
                  <NavLink
                    className={classnames({
                      active: activeTab === index,
                    })}
                    onClick={() => {
                      setActiveTab(index);
                    }}
                    data-testid={`tab-title-${child.props.dataTestId || index}`}
                  >
                    {child.props.name}
                    {onDelete && <FaTimes className="small-icon" onClick={() => onDelete(index)} />}
                  </NavLink>
                </NavItem>
              );
            })}
            {onAdd && (
              <NavItem>
                <NavLink
                  onClick={async () => {
                    if (await onAdd()) {
                      setActiveTab(noChildren);
                    }
                  }}
                >
                  <FaPlus className="small-icon" />
                </NavLink>
              </NavItem>
            )}
          </Nav>
        </div>

        <TabContent activeTab={activeTab} className="flex-fill-height">
          {Children.map(children, (child, index) => {
            if (!isValidElement(child)) {
              return null;
            }
            if (child.props.hidden) {
              return null;
            }
            return (
              <TabPane
                key={index}
                tabId={index}
                data-testid={`tab-content-${child.props.dataTestId || index}`}
                style={{ height: '100%' }}
              >
                <div className="flex-container">{cloneElement(child, { index } as any)}</div>
              </TabPane>
            );
          })}
        </TabContent>
      </div>
    </UncontrolledTabsContext.Provider>
  );
};

export const Tab: FC<{
  name: ReactNode;
  hidden?: boolean;
  dataTestId?: string;
  onFirstOpen?: () => void;
  index?: number;
}> = ({ children, index, onFirstOpen }) => {
  const ctx = useContext(UncontrolledTabsContext);
  const [hasBeenOpened, setHasBeenOpened] = useState(false);
  useEffect(() => {
    if (index === ctx.activeTab && !hasBeenOpened) {
      onFirstOpen?.();
      setHasBeenOpened(true);
    }
  }, [ctx.activeTab, index, onFirstOpen, hasBeenOpened]);
  return <>{children}</>;
};

function UncrontrolledTabs<EP = {}>({ props, onAdd, onDelete, tabs }: IProps<EP>) {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [openedTabs, setOpenedTabs] = useState<Array<boolean>>(Array(tabs.length).fill(false));
  const [stableProps] = useStable(props || ({} as EP));

  const doOnFirstOpenFor = (tab: number) => {
    const _openedTabs = [...openedTabs];
    if (!_openedTabs[tab]) {
      _openedTabs[tab] = true;
      if (tabs[tab].onFirstOpen) {
        tabs[tab].onFirstOpen();
      }
    }
    return _openedTabs;
  };

  const toggle = (tab: number) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setOpenedTabs(doOnFirstOpenFor(tab));
    }
  };

  useEffect(() => {
    setActiveTab(0);
    setOpenedTabs(doOnFirstOpenFor(0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const visibleTabs = tabs.filter((tab) => {
    if (isFunction(tab.hidden)) {
      return !tab.hidden(stableProps);
    }
    return !tab.hidden;
  });
  return (
    <div className="flex-container">
      <div className="tab-scroll">
        <Nav tabs>
          {visibleTabs.map((tab, index) => {
            return (
              <NavItem key={index}>
                <NavLink
                  className={classnames({
                    active: activeTab === index,
                  })}
                  onClick={() => {
                    toggle(index);
                  }}
                  data-testid={`tab-title-${tab.dataTestId || index}`}
                >
                  {tab.name}
                  {onDelete && <FaTimes className="small-icon" onClick={() => onDelete(index)} />}
                </NavLink>
              </NavItem>
            );
          })}
          {onAdd && (
            <NavItem>
              <NavLink
                onClick={async () => {
                  if (await onAdd()) {
                    toggle(tabs.length);
                  }
                }}
              >
                <FaPlus className="small-icon" />
              </NavLink>
            </NavItem>
          )}
        </Nav>
      </div>

      <TabContent activeTab={activeTab} className="flex-fill-height">
        {visibleTabs.map((tab, index) => {
          return (
            <TabPane
              key={index}
              tabId={index}
              data-testid={`tab-content-${tab.dataTestId || index}`}
              style={{ height: '100%' }}
            >
              <div className="flex-container">{getRenderable(tab.content, stableProps)}</div>
            </TabPane>
          );
        })}
      </TabContent>
    </div>
  );
}

export default UncrontrolledTabs;
