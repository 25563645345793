import React from 'react'
import SimpleModal from "components/SimpleModal";
import { useState } from "react";
import { IApi } from "utils/API";
import { TTranslate } from "utils/language";
import Translation from "utils/Language/Translation";
import { INotificationContext } from "utils/NotificationManager";
import { Button, FormGroup, Label } from 'reactstrap';
import ProcessingButton from 'components/ProcessingButton';
import { FormInput, FormState, Labeled } from 'component_utils/FormUtils';

interface IResetPasswordProps {
  userId: number;
  api: IApi;
  T: TTranslate;
  notifications: INotificationContext;
}
const ResetPassword = ({ userId, api, T, notifications }: IResetPasswordProps) => {
  const [state, setState] = useState(
    {} as {
      password_one: string;
      password_two: string;
    },
  );

  return (
    <>
      <SimpleModal
        title={<Translation name="T.settings.changePassword" />}
        fullscreen={false}
        onOpen={() => {
          setState({
            password_one: '',
            password_two: '',
          });
        }}
        trigger={(open) => (
          <FormGroup>
            <Label>
              <Translation name="T.settings.changePassword" />
            </Label>
            <Button onClick={open} block>
              <Translation name="T.settings.changePassword" />
            </Button>
          </FormGroup>
        )}
        buttons={(close) => [
          <ProcessingButton
            color="primary"
            onClick={async () => {
              if (state.password_one !== state.password_two) {
                return notifications.add('danger', <Translation name="T.errors.passwordsShouldMatch" />);
              }
              // save the new user password
              await api.users.saveUserPassword(userId, { password: state.password_one });
              notifications.add('success', <Translation name="T.settings.passwordIsSaved" />);
              close();
            }}
            data-testid={'simple-modal-primary'}
          >
            <Translation name="T.misc.save" />
          </ProcessingButton>,
          <ProcessingButton color="secondary" onClick={close} data-testid={'simple-modal-secondary'}>
            <Translation name="T.misc.cancel" />
          </ProcessingButton>,
        ]}
      >
        <FormState state={state} setState={setState}>
          <Labeled title={<Translation name="T.settings.newPassword" />}>
            <FormInput path="password_one" hideInput />
          </Labeled>
          <Labeled title={<Translation name="T.settings.repeatPassword" />}>
            <FormInput path="password_two" hideInput />
          </Labeled>
        </FormState>
      </SimpleModal>
    </>
  );
};

export default ResetPassword;