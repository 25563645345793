
export interface IRestApiSettings {
  rateLimiterRate: number;
  rateLimiterType: RateLimiterType;
}

export interface IScriptedRestApiEndpoint {
  authorizations: string;
  createdAt: any;
  enabled: boolean;
  id: number;
  ipMatchers: string;
  method: string;
  name: string;
  path: string;
  permitAll: boolean;
  permits: number;
  scriptSource: string;
  updatedAt: any;
}

export type RateLimiterType = 'GLOBAL' | 'PER_KEY' | 'NONE';
export const RateLimiterTypeValues: RateLimiterType[] = ['GLOBAL' , 'PER_KEY' , 'NONE'];

