import { Role } from 'interfaces';
import React, { useEffect } from 'react';
import { useAuth } from 'utils/API';

const RoleProtected: React.FC<{ 
  roles: Role[],
  onHasRole?: () => any
}> = ({ roles, children, onHasRole }) => {
  const auth = useAuth();
  const hasRole = auth.expandedRoles.some((role) => roles.includes(role))

  useEffect(() => {
    if (hasRole) {
      onHasRole?.()
    }
  }, [hasRole, onHasRole])

  if (hasRole) {
    return <>{children}</>;
  }
  return null;
};

export default RoleProtected;
