import ReactDOM from 'react-dom';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  domId: string;
}

export default ({ domId, children }: Props) => {
  const el = document.getElementById(domId);
  if (!el) {
    return null;
  }
  return ReactDOM.createPortal(children, el);
};
