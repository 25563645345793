import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

interface Props {
  icon: IconDefinition;
  onClick: () => any;
  [k: string]: any;
}

export default ({ icon, onClick, ...others }: Props) => {
  return (
    <FontAwesomeIcon
      {...others}
      icon={icon}
      onClick={(e: any) => {
        e.stopPropagation();
        onClick();
      }}
    />
  );
};
