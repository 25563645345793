import React from 'react';
import moment from 'moment';
import { TTranslate, useTranslations } from 'utils/language';

export const DATE = 'T.date.dateFormat';
export const TIME = 'T.date.timeFormat';
export const DATE_TIME = 'T.date.dateTimeFormat';

export function getFormatedDate(T: TTranslate, val: string | Date, format?: string) {
  return moment(val).format(T(format || DATE));
}

export default (props: { val: string | Date; format?: string }) => {
  const T = useTranslations();
  return <span data-testid="date-text">{getFormatedDate(T, props.val, props.format)}</span>;
};
