import {
  IQualityControlVerificationRequest,
  IIQualityControlDao$IQualityControlDocumentFile,
  IBinLocation,
  IQualityControlRequest,
} from 'interfaces';
import { Registry } from '.';

export default ({ get, post, destroy }: Registry) => {
  return {
    qualityControl: {
      getOpenRequests: (): Promise<IQualityControlRequest[]> => get('/api/v1/qc/getOpenRequests'),
      getLocations: (code: string): Promise<IBinLocation[]> => get(`/api/v1/qc/${code}/locations`),
      saveQualityControlResults: async (forObjects: IQualityControlRequest[], files: File[], comments: string): Promise<{ code: string }> => {
        var formData = new FormData();
        formData.append("files", [] as any)
        files.forEach(file => formData.append("files", file))
        formData.append("comments", comments);
        formData.append('forObjects', new Blob([JSON.stringify(forObjects)], { type: 'application/json' }));
        return await post(`/api/v1/qc/qc_performed`, formData, null)
      },

      getVerifiableRequests: (): Promise<IQualityControlVerificationRequest[]> => get('/api/v1/qc/getOpenVerificationRequests'),
      getDocumentsForRequest: (code: string): Promise<IIQualityControlDao$IQualityControlDocumentFile[]> => get(`/api/v1/qc/${code}/documents`),
      downloadDocument: (code: string, filecode: string): Promise<any> => get(`/api/v1/qc/${code}/documents/${filecode}`),
      resolveRequest: (code: string, comments: string, accepted: boolean): Promise<IQualityControlVerificationRequest[]> => post(`/api/v1/qc/${code}/qc_verified`, { comments, accepted }),
    }
  };
};
