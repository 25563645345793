
export interface ISapConfiguration {
  beWsHost: string;
  beWsToken: string;
  company_pass: string;
  company_user: string;
  concurrentSessions: number;
  database_name: string;
  database_pass: string;
  database_server: string;
  database_type: SapDatabaseType;
  database_user: string;
  diServiceLayerHost: string;
  jdbcParameters: ISapConfiguration$IJdbcParameters;
  license_server: string;
  ppsOneConfiguration: ISapConfiguration$IPpsOneConfiguration;
  productionBackend: SapProductionBackend;
  temporaryAttachmentsFolder: string;
  useDiServiceLayerAttachmentsService: boolean;
}

export interface ISapConfiguration$IJdbcParameters {
  dbHost: string;
  dbName: string;
  dbPass: string;
  dbUser: string;
}

export interface ISapConfiguration$IPpsOneConfiguration {
  ppsOneHost: string;
}

export type SapDatabaseType = 'NONE' | 'MSSQL2005' | 'MSSQL2008' | 'MSSQL2012' | 'MSSQL2014' | 'MSSQL2016' | 'MSSQL2017' | 'MSSQL2019' | 'HANADB';
export const SapDatabaseTypeValues: SapDatabaseType[] = ['NONE' , 'MSSQL2005' , 'MSSQL2008' , 'MSSQL2012' , 'MSSQL2014' , 'MSSQL2016' , 'MSSQL2017' , 'MSSQL2019' , 'HANADB'];

export type SapProductionBackend = 'SAP' | 'PPS_ONE';
export const SapProductionBackendValues: SapProductionBackend[] = ['SAP' , 'PPS_ONE'];

