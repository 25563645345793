import React, { useEffect } from 'react';
import { CustomInput, DropdownItem } from 'reactstrap';
import { useAuth } from 'utils/API';
import { useEditableTranslations } from 'utils/language';
import Translation from 'utils/Language/Translation';

export default () => {
  const user = useAuth();
  const [canEditTranslations, setCanEditTranslations] = useEditableTranslations();
  const isTranslationManager = user.expandedRoles.includes('translationManager');

  useEffect(() => {
    if (isTranslationManager) {
      const onKeyDown = (e: KeyboardEvent) => {
        if (e.shiftKey && e.altKey && e.code === 'KeyT') {
          setCanEditTranslations((old) => !old);
        }
      };
      document.addEventListener('keydown', onKeyDown);
      return () => document.removeEventListener('keydown', onKeyDown);
    }
  }, [isTranslationManager, setCanEditTranslations]);

  if (!isTranslationManager) {
    return null;
  }
  return (
    <DropdownItem toggle={false}>
      <CustomInput
        type="switch"
        id="editTranslationsToggle"
        checked={canEditTranslations}
        onChange={(e) => setCanEditTranslations(e.target.checked)}
        label={<Translation name="T.menu.manageTranslations" />}
      />
    </DropdownItem>
  );
};
