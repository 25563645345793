import React, { useMemo, useRef, useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { throttle } from 'lodash';
import { useRerender } from 'component_utils/utils';

interface Props {
  minRowSize: number;
  rowRenderer: (args: { index: number; props: any }) => JSX.Element;
  padRenderer: (args: { top: boolean; bottom: boolean; height: number }) => JSX.Element;
  rowCount: number;
  maxHeight: number;
  overscan: number;
  throtleInterval?: number;

  containerProps: any;
}

export interface VirtualizedListHandle {
  clearMeasurementAt: (index: number) => void;
  clearMeasurementFrom: (index: number) => void;
  clearAllMeasurements: () => void;
  scrollToIndex: (index: number, duration?: number) => void;

  getCurrentUIState: () => any;
  setUIState: (data: any) => any;
}

const VirtualizedList = forwardRef<VirtualizedListHandle, Props>(
  (
    { rowRenderer, padRenderer, minRowSize, rowCount, overscan, maxHeight, throtleInterval = 50, containerProps = {} },
    ref,
  ) => {
    const rerender = useRerender();
    const bodyRef = useRef<HTMLDivElement>(null);
    const [scrolled, setScrolled] = useState(0);
    const sizesRef = useRef(Array<number>(0).fill(null));
    if (rowCount > sizesRef.current.length) {
      sizesRef.current = sizesRef.current.concat(Array<number>(rowCount - sizesRef.current.length).fill(null));
    }

    useImperativeHandle<any, VirtualizedListHandle>(
      ref,
      () => ({
        scrollToIndex: (index: number, duration: number = 200) => {
          // ignore negative scrolls
          if (index < 0 || !isFinite(index)) {
            return;
          }

          // get the top offset
          let expectedOffset = 0;
          for (let i = 0; i < index; i++) {
            expectedOffset += sizesRef.current[i] || minRowSize;
          }

          // scroll to the expected position
          // bodyRef.current.scrollTop = expectedOffset
          const start = Date.now();
          const finishAt = start + duration;
          requestAnimationFrame(tick);
          const body = bodyRef.current
          if (!body) {
            return;
          }

          function tick() {
            // How many frames left? (60fps = 16.6ms per frame)
            const framesLeft = (finishAt - Date.now()) / 16.6;

            // How far do we have to go?
            const distance = expectedOffset - body.scrollTop;

            // Adjust by one frame's worth
            if (framesLeft <= 1) {
              // Last call
              let expectedOffset = 0;
              for (let i = 0; i < index; i++) {
                expectedOffset += sizesRef.current[i] || minRowSize;
              }
              body.scrollTop = expectedOffset;
              requestAnimationFrame(finalResolver)
            } else {
              // Not the last, adjust and schedule next
              body.scrollTop += distance / framesLeft;
              requestAnimationFrame(tick);
            }
          }
          let corrections = 0;
          function finalResolver() {
            let expectedOffset = 0;
            for (let i = 0; i < index; i++) {
              expectedOffset += sizesRef.current[i] || minRowSize;
            }
            body.scrollTop = expectedOffset;
            if (corrections < 10) {
              corrections++
              requestAnimationFrame(finalResolver)
            }
          }
        },
        clearMeasurementAt: (index: number) => {
          sizesRef.current[index] = null;
        },

        clearMeasurementFrom: (index: number) => {
          sizesRef.current.fill(null, index);
        },

        clearAllMeasurements: () => {
          sizesRef.current.fill(null);
        },

        getCurrentUIState: () => {
          if (!bodyRef.current) {
            return null
          }
          return {
            scrollTop: bodyRef.current.scrollTop,
            sizeCache: [...sizesRef.current]
          }
        },

        setUIState: (data) => {
          if (!data) return;
          if (data.sizeCache.length > sizesRef.current.length) {
            sizesRef.current = sizesRef.current.concat(Array<number>(data.sizeCache.length - sizesRef.current.length).fill(null));
          }
          sizesRef.current.fill(null);
          (data.sizeCache as number[]).forEach((v, i) => {
            sizesRef.current[i] = v
          })
          rerender(() => {
            setTimeout(() => {
              bodyRef.current.scrollTop = data.scrollTop
            }, (10));
          });
        }
      }),
      [sizesRef, minRowSize, rerender],
    );

    const handleScroll = useMemo(() => {
      return throttle((event: React.UIEvent<HTMLDivElement, UIEvent>) => {
        const scrollTop = bodyRef.current ? bodyRef.current.scrollTop : 0;
        setScrolled((old) => (Math.abs(scrollTop - old) > minRowSize ? scrollTop : old));
      }, throtleInterval);
    }, [bodyRef, setScrolled, minRowSize, throtleInterval]);

    const startAt = scrolled - overscan;
    const endAt = scrolled + (maxHeight || 0) + overscan;

    // console.time("getRenderable")
    let total = 0;
    let topHeight = 0;
    let bottomHeight = 0;
    let indexesToRender = [];

    for (let i = 0; i < rowCount; i++) {
      const rowHeight = sizesRef.current[i] || minRowSize;
      const rowStart = total;
      const rowEnd = total + rowHeight;

      // decide whether we should render this row
      // if this row ends before the current scroll offset hide it
      // if the row starts after the current scroll offset + height hide it
      // otherwise render it
      if (rowEnd < startAt) {
        topHeight += rowHeight;
      } else if (rowStart > endAt) {
        bottomHeight += rowHeight;
      } else {
        indexesToRender.push(i);
      }
      total += rowHeight;
    }

    // if no rows present set start and end to 0
    // console.timeEnd("getRenderable")
    // console.log(startAt, endAt)
    // console.log(total, topHeight, bottomHeight, indexesToRender)

    // this is used to get accurate measurements of the children rows
    useEffect(() => {
      let shouldRerender = false;

      Array.from(bodyRef.current.children).forEach((it) => {
        const vid = it.getAttribute('data-virtualized-id');
        if (vid === null) {
          return;
        }

        const key = parseInt(vid);
        const actualRowHeight = it.getBoundingClientRect().height;
        if (actualRowHeight === 0) {
          return;
        }

        const cachedHeight = sizesRef.current[key] === null ? minRowSize : sizesRef.current[key];
        sizesRef.current[key] = actualRowHeight;
        if (actualRowHeight < cachedHeight) {
          shouldRerender = true;
        }
      });

      if (shouldRerender) {
        rerender();
      }
    });

    return (
      <div {...containerProps} onScroll={handleScroll} ref={bodyRef}>
        {padRenderer({ top: true, bottom: false, height: topHeight })}

        {indexesToRender.map((key) =>
          rowRenderer({
            index: key,
            props: { 'data-virtualized-id': key },
          }),
        )}

        {padRenderer({ top: false, bottom: true, height: bottomHeight })}
      </div>
    );
  },
);

export default VirtualizedList;
