import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type BoolTypes = 'CIRCLE' | 'YN' | 'CUSTOM';
interface Props {
  type: BoolTypes;
  val: boolean | any;
  yes?: (x: boolean) => ReactNode;
  no?: (x: boolean) => ReactNode;
}

export default ({ val, type, yes, no }: Props) => {
  const getOrEmpty = (func?: (x: boolean) => ReactNode) => {
    if (!func) {
      return '';
    }
    return func(val);
  };

  if (type === 'YN') {
    return (
      <div data-testid="bool-container">
        {val ? (
          <FontAwesomeIcon data-testid="b_true" className="text-success" icon={faCheck} />
        ) : (
          <FontAwesomeIcon data-testid="b_false" className="text-danger" icon={faTimes} />
        )}
      </div>
    );
  }

  if (type === 'CUSTOM') {
    return <div data-testid="bool-container">{val ? getOrEmpty(yes) : getOrEmpty(no)}</div>;
  }

  if (type === 'CIRCLE') {
    return (
      <div
        data-testid="bool-container"
        className={classNames('dot', {
          'green-dot': val,
          'red-dot': !val,
        })}
      />
    );
  }

  return null;
};
