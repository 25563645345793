import { RuntimeType } from 'interfaces';
import React from 'react';
import { useConfig } from 'utils/Config';

const OnRuntimeType: React.FC<{ type: RuntimeType }> = ({ type, children }) => {
  const config = useConfig();
  if (config.runtimeType === type) {
    return <>{children}</>;
  }
  return null;
};

export default OnRuntimeType;
