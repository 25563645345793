import React, { useState, useEffect, useCallback } from 'react';
import { useApi, useApiLoader, useAuth, useDeviceConfig } from 'utils/API';
import PropertyList from 'components/property_list';
import { UncontrolledTabs, Tab } from 'components/uncontrolled_tabs';
import Item from 'components/objects/item';
import Model from 'components/objects/model';
import { IBinLocation, IBinLocationAttributes, IItem, IItemMovementHistoryEntry, IOrderHeader, LocationType, LocationTypeValues, PhysicalLocationType, PhysicalLocationTypeValues } from 'interfaces';
import Serial from '../serial';
import Batch from '../batch';
import { useConfig } from 'utils/Config';
import { beTableAsComponent } from 'components/BeTable';
import Translation from 'utils/Language/Translation';
import ProcessingButton from 'components/ProcessingButton';
import { indexFilter, sleep, useAsync, useRerender } from 'component_utils/utils';
import Bool from 'components/Bool';
import { IContentsItemTreeLine, isBatch, isSerial, toItemTree } from 'component_utils/itemLocationTree';
import ExpandedIndicator from 'components/ExpandedIndicator';
import { getLeftPaddingForLevel, tableHighlightColor } from 'styling/stylingVars';
import { choice, choiceList, confirm, prompt, promptAutocomplete } from 'utils/Prompts';
import { BsSignStopFill } from 'react-icons/bs'
import classNames from 'classnames';
import { addNotification } from 'utils/NotificationManager';
import If from 'components/If';
import { Button, ButtonGroup, InputGroup, InputGroupAddon, Spinner, Table } from 'reactstrap';
import { MdClear } from 'react-icons/md';
import { FaCopy, FaEdit, FaTimes } from 'react-icons/fa';
import { sumBy } from 'lodash';
import RoleProtected from 'components/RoleProtected';
import SimpleModal from 'components/SimpleModal';
import Scanner from 'components/Scanner';
import Date, { DATE_TIME } from 'components/Date';

const ContentsTable = beTableAsComponent<IContentsItemTreeLine, { selectedItem?: IItem }>({
  tableId: 'locationContents',
  subRowsKeys: ['batches', 'serials'],
  showRowsWithoutChildren: true,
  getRowStyle: ({ row, level, props }) => {
    if (level === 0 && row.item.code === props?.selectedItem?.code) {
      return {
        backgroundColor: tableHighlightColor,
      };
    }
    return null;
  },
  columns: [
    {
      Header: <Translation name="T.objects.item.itemCode" />,
      Cell: ({ row, level, isExpanded }) => {
        let inner = null;
        let hasChildren = false;
        if (level === 0) {
          inner = <Item obj={row.item} inline={true} />;
          hasChildren = row.batches.length > 0;
        } else if (level === 1) {
          const batch = row as unknown as IContentsItemTreeLine['batches'][0];
          inner = batch.batch ? <Batch inline obj={batch.batch} /> : batch.batchCode;
          hasChildren = batch.serials.length > 0;
        } else if (level === 2) {
          const serial = row as unknown as IContentsItemTreeLine['batches'][0]['serials'][0];
          inner = <Serial inline obj={serial.serial} />;
          hasChildren = false;
        }

        return (
          <div style={{ paddingLeft: getLeftPaddingForLevel(level) }}>
            <ExpandedIndicator isExpanded={isExpanded} disabled={!hasChildren} />
            {inner}
          </div>
        );
      },
      value: (row) => row.item.code,
      filterable: true,
      expander: true,
      width: 100,
      Footer: ({ rows }) => {
        return <Translation name="T.objects.contents.uniqueItems" params={{ number: rows.length }}/>
      }
    },
    {
      Header: <Translation name="T.objects.contents.onHandQuantity" />,
      value: row => row.onHandQty,
      className: 'text-right',
      Footer: ({ rows }) => {
        return sumBy(rows, it => it.onHandQty)
      }
    },
    {
      Header: <Translation name="T.objects.contents.freeToMoveQuantity" />,
      value: row => row.freeToMoveQty,
      className: 'text-right',
      hideBelow: 'sm',
    },
    {
      Header: <Translation name="T.objects.contents.releasedQuantity" />,
      value: row => row.releasedQty,
      className: 'text-right',
      hideBelow: 'sm',
    },
    {
      Header: <Translation name="T.objects.contents.pickedQuantity" />,
      value: row => row.pickQty,
      className: 'text-right',
      hideBelow: 'sm',
    },
  ],
});

const HistoryTable = beTableAsComponent<IItemMovementHistoryEntry>({
  tableId: 'locationHistoryTable',
  columns: [
    {
      id: 'type',
      Header: () => <Translation name="T.objects.item.history.transType" />,
      value: (row) => `${row.transactionType} (${row.documentCode})`,
      filterable: true,
      fullText: true,
    },
    {
      id: 'at',
      Header: () => <Translation name="T.objects.item.history.at" />,
      Cell: ({ row }) => <Date format={DATE_TIME} val={row.at} />,
      value: (row) => row.at,
      fullText: true,
    },
    {
      id: 'username',
      Header: () => <Translation name="T.objects.item.history.username" />,
      value: (row) => row.username,
      filterable: true,
    },
    {
      id: 'quantity',
      Header: () => <Translation name="T.objects.item.history.quantity" />,
      className: 'text-right',
      value: (row) => row.quantity,
    },
    {
      id: 'batchSerial',
      Header: () => <Translation name="T.objects.item.history.batchSerial" />,
      Cell: ({ row }) => {
        if (isBatch(row.item)) {
          return <Batch obj={row.batch} inline/>
        }
        if (isSerial(row.item)) {
          return <Serial obj={row.serialNumber} inline/>
        }
        return null
      },
      value: (row) => row.serialNumber?.visualCode ?? row.batch?.visualCode ?? "",
      filterable: true,
    },
    {
      id: 'item',
      Header: () => <Translation name="T.objects.item.history.item" />,
      Cell: ({ row }) => <Item obj={row.item} inline />,
      value: (row) => row.item.visualCode,
      filterable: true,
    },
  ],
});


interface Props {
  inline?: boolean;
  obj: IBinLocation | null;
}

export const getShortDescription = (location: IBinLocation) => (
  <>
    <span className={classNames(location.stockMovementRestricted && 'text-danger')}>
      <Translation name="T.objects.location.shortDescription" params={(location as any) || {}} />
      {location.stockMovementRestricted && <>{' '}<BsSignStopFill/></>}
    </span>
  </>
);

const Location = ({ inline, obj: location }: Props) => {
  const api = useApi();
  const config = useConfig();
  const user = useAuth();
  const deviceConfig = useDeviceConfig().deviceConfig;
  const [attributes, setAttributes] = useState<IBinLocationAttributes>({
    userAttributes: [],
  } as IBinLocationAttributes);
  const [contents, setContents] = useState<IContentsItemTreeLine[]>(null);

  const { isLoading: isLoadingOrderHeader, execute: executeOrderLoader } = useAsync()
  const [orderHeader, setOrderHeader] = useState<IOrderHeader>(null)

  const rerender = useRerender();

  useEffect(() => {
    setAttributes({ userAttributes: [] } as IBinLocationAttributes);
    setContents(null);
    setOrderHeader(null)
  }, [location?.code]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadAttributes = async () => setAttributes(await api.binLocation.getBinLocationAttributes(location!.code));
  const loadContents = async () =>
    setContents(toItemTree(await api.binLocation.getBinLocationContents(location!.code)));

  const shortDescription = () => getShortDescription(location);

  const loadOrderHeader = async () => {
    if (location.preferredOrderCode) { 
      setOrderHeader(await api.order.getOrder(location.preferredOrderCode)) 
    } 
  }

  const {
    data: history,
    isLoading: historyLoading,
    reload: loadHistory,
  } = useApiLoader(
    useCallback((api) => api.binLocation.getHistory(location.code), [location?.code]), // eslint-disable-line react-hooks/exhaustive-deps
    [],
    false,
  );

  const [itemsToCount, setItemsToCount] = useState<IItem[]>([])

  const detailedRender = () => {
    return (
      <UncontrolledTabs key={location?.code}>
        <Tab dataTestId='locationInfoTabGeneral' name={<Translation name="T.objects.binLocation.attributes" />} onFirstOpen={() => {
          loadAttributes()
          executeOrderLoader(() => loadOrderHeader())
        }}>
          <PropertyList
            rows={[
              [
                <b>{<Translation name="T.objects.binLocation.locationName" />}</b>, 
                <>
                  {location!.visualCode}
                  {' '}
                  <RoleProtected roles={['authCopyObjectCodes']}>
                    <ProcessingButton color="link" className='p-0' onClick={async () => {
                      navigator.clipboard.writeText(location!.visualCode)
                      await sleep(100)
                    }}>
                      <FaCopy/>
                    </ProcessingButton>
                  </RoleProtected>
                </>,
              ],
              [<b>{<Translation name="T.objects.binLocation.locationCode" />}</b>, location!.code],
              [<b>{<Translation name="T.misc.barcode" />}</b>, location!.barcode],
              [<b>{<Translation name="T.misc.minimumStockLevel" />}</b>, location!.minimalStockLevel],
              [<b>{<Translation name="T.misc.maximumStockLevel" />}</b>, location!.maximalStockLevel],
              [<b>{<Translation name="T.misc.locationType" />}</b>, location!.locationType],
              [<b>{<Translation name="T.misc.physicalLocationType" />}</b>, location!.physicalLocationType],
              [<b>{<Translation name="T.misc.pickingStrategy" />}</b>, location!.pickingStrategy],
              [<b>{<Translation name="T.misc.active" />}</b>, <Bool type="YN" val={location.active} />],
              [<b>{<Translation name="T.misc.stockMovementsAllowed" />}</b>, <Bool type="YN" val={!location.stockMovementRestricted} />],
              [<b>{<Translation name="T.misc.sortingCode" />}</b>, location.sortingCode],
              [<b>{<Translation name="T.misc.countingSortingCode" />}</b>, location.countingSortingCode],
              [
                <b>{<Translation name="T.misc.orderCode" />}</b>, 
                <InputGroup>
                  <div className="form-control h-auto py-0">
                    {isLoadingOrderHeader ? (
                      <Spinner size="sm" />
                    ) : (
                      orderHeader && `${orderHeader?.visualCode} - ${orderHeader?.businessPartnerName}`
                    )}
                  </div>
                  <InputGroupAddon addonType="append">
                    <ProcessingButton color="secondary" className='h-auto py-0' onClick={async () => {
                      if (await confirm(<Translation name="T.hints.areYouSure"/>)) {
                        await api.binLocation.setPreferredOrderCode(location!.code, null);
                        location.preferredOrderCode = null;
                        setOrderHeader(null)
                        rerender();
                      }
                    }}>
                      <MdClear/>
                    </ProcessingButton>
                    <ProcessingButton
                      className='h-auto py-0'
                      color="primary"
                      onClick={async () => {
                        const orderCode = await promptAutocomplete(
                          <Translation name="T.misc.orderCode"/>, 
                          '',
                          api.order.getOrderAutoComplete
                        );
                        if (orderCode) {
                          await executeOrderLoader(async () => {
                            await api.binLocation.setPreferredOrderCode(location!.code, orderCode);
                            location.preferredOrderCode = orderCode;
                            await loadOrderHeader()
                            rerender();
                          })
                        }
                      }}
                    >
                      <FaEdit/>
                    </ProcessingButton>
                  </InputGroupAddon>
                </InputGroup>              
              ],
              [
                <b>{<Translation name="T.misc.requiresRecounting" />}</b>,
                <SimpleModal
                  title={<Translation name="T.misc.selectItems"/>}
                  trigger={open => (
                    <>
                      <If cond={location.requiresCounting === 'NO'}>
                        <ProcessingButton
                          block
                          color={'success'}
                          className="py-0"
                          data-testid="register-for-counting"
                          onClick={async () => {
                            // mark location for counting
                            const type = await choiceList(
                              <Translation name="T.objects.binLocation.whatCountingType"/>,
                              [{
                                label: <Translation name="T.apps.counting.partial-counting"/>, value: 'partial'
                              },{
                                label: <Translation name="T.apps.counting.full-counting"/>, value: 'full'
                              },],
                              true,
                              false
                            )

                            if (type === 'full') {
                              await api.binLocation.setCountingState(location!.code, {
                                countingState: 'YES',
                                itemsToCount: []
                              });
                              await api.notifications.increase('/wall_to_wall');
                              location.requiresCounting = 'YES';
                              rerender();  
                            }

                            if (type === 'partial') {
                              const items = await api.binLocation.getItemsToCount(location.code)
                              setItemsToCount(items)
                              open();
                            }
                          }}
                        >
                          <Translation name='T.misc.markRequiresCounting' />
                        </ProcessingButton>
                      </If>

                      <If cond={location.requiresCounting === 'YES'}>
                        <ProcessingButton
                          block
                          color={'danger'}
                          className="py-0"
                          data-testid="register-for-counting"
                          onClick={async () => {
                            if (!(await confirm(<Translation name="T.misc.areYouSure?"/>))) return;
                            await api.binLocation.setCountingState(location!.code, {
                              countingState: 'NO',
                              itemsToCount: []
                            });
                            await api.notifications.decrease('/wall_to_wall');
                            location.requiresCounting = 'NO';
                            rerender();
                          }}
                        >
                          <Translation name='T.misc.markCounted' />
                        </ProcessingButton>
                      </If>

                      <If cond={location.requiresCounting === 'PARTIAL'}>
                        <ButtonGroup className='w-100'>
                          <ProcessingButton
                            block
                            color={'warning'}
                            className="py-0"
                            data-testid="register-for-counting"
                            onClick={async () => {
                              if (!(await confirm(<Translation name="T.misc.areYouSure?"/>))) return;
                              await api.binLocation.setCountingState(location!.code, {
                                countingState: 'NO',
                                itemsToCount: []
                              });
                              await api.notifications.decrease('/wall_to_wall');
                              location.requiresCounting = 'NO';
                              rerender();
                            }}
                          >
                            <Translation name='T.misc.markCounted' />
                          </ProcessingButton>
                          <ProcessingButton
                            color='info'
                            className='py-0'
                            onClick={async () => {
                              const items = await api.binLocation.getItemsToCount(location.code)
                              setItemsToCount(items)
                              open();
                            }}
                          >
                            <FaEdit/>
                          </ProcessingButton>
                        </ButtonGroup>
                      </If>
                    </>
                  )}
                  buttons={close => [
                    <ProcessingButton color="secondary" onClick={close} key="close">
                      <Translation name="T.misc.cancel" />
                    </ProcessingButton>,
                    <ProcessingButton color='primary' onClick={async () => {
                      if (!(await confirm(<Translation name="T.misc.areYouSure?"/>))) return;
                      await api.binLocation.setCountingState(location!.code, {
                        countingState: 'PARTIAL',
                        itemsToCount: itemsToCount.map(it => it.code)
                      });
                      await api.notifications.increase('/wall_to_wall');
                      location.requiresCounting = 'PARTIAL';
                      rerender();  
                      close()
                    }}>
                      <Translation name="T.misc.save"/>
                    </ProcessingButton>
                  ]}
                >
                  <Scanner
                    placeholder=''
                    onEnter={async s => {
                      const item = await api.item.findItem(s)
                      setItemsToCount(old => [...old, item])
                    }}
                    fetchAutocompleteOptions={s => api.item.findAutocomplete(s)}
                  />
                  <Table striped>
                    <tbody>
                      {itemsToCount.map((it, index) => (
                        <tr>
                          <td className='w-100'><Item inline obj={it}/></td>
                          <td>
                            <Button color='danger' className='py-0' onClick={() => {
                              setItemsToCount(old => old.filter(indexFilter([index])))
                            }}>
                              <FaTimes/>
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </SimpleModal>
              ],
              location.locationType === 'MOBILE' && [
                <b>
                  <Translation name="T.misc.readyForProcessing" />
                </b>,
                <ProcessingButton
                  block
                  color={location.readyForProcessing ? 'danger' : 'success'}
                  className="py-0"
                  onClick={async () => {
                    if (!(await confirm(<Translation name="T.misc.areYouSure?" />))) {
                      return;
                    }

                    const next = !location.readyForProcessing;
                    await api.binLocation.setReadyForProcessing(location!.code, next);
                    location.readyForProcessing = next;
                    rerender();
                  }}
                >
                  <Translation
                    name={location.readyForProcessing ? 'T.misc.readyForProcessing' : 'T.misc.processed'}
                  />
                </ProcessingButton>,
              ],
              ...attributes.userAttributes.map((attr) => [<b>{attr.name}</b>, attr.value]),
            ]}
          />
        </Tab>
        <Tab 
          dataTestId='locationInfoTabContents' 
          name={<Translation name="T.objects.binLocation.contents" />} 
          hidden={config.settings.systemOperationMode === 'WALL_TO_WALL_BATCHING_MODE' && !user.expandedRoles.includes('admin')}
          onFirstOpen={loadContents}
        >
          <ContentsTable data={contents || []} loading={contents === null} />
        </Tab>
        <Tab 
          name={<Translation name="T.misc.history" />}
          onFirstOpen={loadHistory}
        >
          <HistoryTable loading={historyLoading} data={history} />
        </Tab>
        <Tab
          dataTestId='actions'
          name={<Translation name="T.misc.actions" />}
        >
          <If cond={user.expandedRoles.includes('barcodeSetup')}>
            <ProcessingButton block color="primary" onClick={async () => {
              const barcode = await prompt(<Translation name="T.hints.barcode"/>, '')
              if (barcode != null) {
                await api.binLocation.setBarcode(location.code, barcode);
                location.barcode = barcode
                addNotification('success', <Translation name="T.misc.success"/>)
                rerender()
              }
            }}>
              <Translation name="T.misc.setBarcode"/>
            </ProcessingButton>
            <br/>
          </If>

          <If cond={user.expandedRoles.includes('barcodeSetup')}>
            <ProcessingButton block color="primary" onClick={async () => {
              const type = await choice(<Translation name="T.hints.locationType"/>, location.locationType, LocationTypeValues)
              if (type != null) {
                await api.binLocation.setLocationType(location.code, type as LocationType);
                location.locationType = (type as LocationType)
                addNotification('success', <Translation name="T.misc.success"/>)
                rerender()
              }
            }}>
              <Translation name="T.misc.setLocationType"/>
            </ProcessingButton>
            <br/>
          </If>

          <If cond={user.expandedRoles.includes('barcodeSetup')}>
            <ProcessingButton block color="primary" onClick={async () => {
              const type = await choice(<Translation name="T.hints.physicalLocationType"/>, location.physicalLocationType, PhysicalLocationTypeValues)
              if (type != null) {
                await api.binLocation.setPhysicalLocationType(location.code, type as PhysicalLocationType);
                location.physicalLocationType = (type as PhysicalLocationType)
                addNotification('success', <Translation name="T.misc.success"/>)
                rerender()
              }
            }}>
              <Translation name="T.misc.setLocationType"/>
            </ProcessingButton>
            <br/>
          </If>

          <ProcessingButton block color="primary" onClick={async () => {
            await api.binLocation.printLabel(
              deviceConfig.defaultLocationLabelPrinter,
              {
                location: location
              }
            )
          }}>
            <Translation name="T.misc.printLabels"/>
          </ProcessingButton>
          <br/>

          <ProcessingButton block color="primary" onClick={async () => {
            await api.binLocation.printLargeLabel(
              deviceConfig.defaultPrinter,
              {
                location: location
              }
            )
          }}>
            <Translation name="T.misc.printLargeLabels"/>
          </ProcessingButton>
        </Tab>
      </UncontrolledTabs>
    );
  };

  const dialog = {
    titleText: <Translation name="T.objects.binLocation.locationDetails" />,
    closeBtnText: <Translation name="T.misc.close" />,
    content: () => <Location obj={location} />,
  };
  return (
    <Model
      inline={inline}
      hasLoaded={location}
      copyValue={() => location.visualCode}
      description={shortDescription}
      detailedRender={detailedRender}
      dialog={dialog}
    />
  );
};

export const LocationContentsTable = ContentsTable;
export default Location;
