import { ExtendedError, useEffectAsync } from 'component_utils/utils';
import React, { FC, useState } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { FaCogs } from 'react-icons/fa';
import { useHistory } from 'react-router';
import { Button, UncontrolledCollapse } from 'reactstrap';
import dedent from 'dedent';
import StackTrace from 'stacktrace-js';
import './style.scss';

const isExtendedError = (error: any): error is ExtendedError => {
  return error instanceof ExtendedError;
};

const formatPayload = (error: ErrorReport) => {
  return JSON.stringify(error.payload, null, 2);
};

const formatStackTrace = (error: ErrorReport) => {
  return error.stacktrace
    .map((it) => {
      let fname = it.fileName
      if (fname.includes('/src/')) {
        fname = '/src/' + fname.split('/src/')[1]
      }
      return `${fname}:${it.lineNumber} in ${it.functionName}`
    })
    .join('\n');
};

const getCauseError = (error: any) => {
  if (isExtendedError(error)) {
    return error.cause;
  }
  return null;
};

interface ErrorReport {
  name: string;
  message: string;
  payload: any;
  stacktrace: StackTrace.StackFrame[];
  fullStacktrace: StackTrace.StackFrame[];
}

const BugReport = ({ error: _error, resetErrorBoundary }: FallbackProps) => {
  const [errorList, setErrorList] = useState<ErrorReport[]>([]);
  const [hasPostedReport, setHasPostedReport] = useState(false);
  const [errorIndex, setErrorIndex] = useState(0);
  const history = useHistory();

  useEffectAsync(async () => {
    const list: ErrorReport[] = [];

    let curr = _error;
    while (curr) {
      let fst: StackTrace.StackFrame[] = [];
      let st: StackTrace.StackFrame[] = [];
      try {
        fst = await StackTrace.fromError(curr);
        st = fst.filter((it) => it.fileName.includes('/src/'));
      } catch (e) {
        console.error(e);
      }

      list.push({
        name: curr.name,
        message: curr.message,
        payload: isExtendedError(curr) ? curr.payload : {},
        stacktrace: st,
        fullStacktrace: fst,
      });
      curr = getCauseError(curr);
    }

    setErrorList(list);
  }, [_error, setErrorList]);

  const error = errorList[errorIndex];
  const parent = errorIndex > 0;
  const cause = errorIndex < errorList.length - 1;
  return (
    <div className="page-404">
      <div className="outer">
        <div className="middle">
          <div className="inner">
            <div className="inner-circle mx-auto">
              <FaCogs className="icon" />
              <span>Error</span>
            </div>
            <span className="inner-status">An application error occured</span>
            {error ? (
              <>
                <span className="inner-detail">
                  {error.name}
                  <br />
                  {error.message}
                  <br />
                  <Button size="sm" className="py-0" disabled={!parent} onClick={() => setErrorIndex(errorIndex - 1)}>
                    Parent
                  </Button>{' '}
                  <Button size="sm" className="py-0" disabled={!cause} onClick={() => setErrorIndex(errorIndex + 1)}>
                    Cause
                  </Button>
                </span>
                <span className="payload">
                  <u id="payloadToggle">Payload:</u>
                  <br />
                  <UncontrolledCollapse toggler="#payloadToggle">
                    <pre className="payload-contents my-0">{formatPayload(error)}</pre>
                  </UncontrolledCollapse>
                </span>
                <span className="stack-trace">
                  <u id="traceToggle">Trace:</u>
                  <br />
                  <UncontrolledCollapse toggler="#traceToggle">
                    <pre className="stack-trace-contents my-0" style={{ overflowX: 'scroll', maxWidth: '90vw' }}>
                      {formatStackTrace(error)}
                    </pre>
                  </UncontrolledCollapse>
                </span>
                <br />
                <br />
                <br />
                <span className="inner-detail">
                  <Button
                    color="primary"
                    disabled={hasPostedReport}
                    onClick={async () => {
                      const report = errorList
                        .map((error, index) => {
                          let report = dedent`
                        -----------------------------------------------------------------
                        Error index: ${index}
                        Error type: ${error.name}
                        Error message: ${error.message}
                      `;

                          report += '\nError payload:\n';
                          report += formatPayload(error);

                          report += '\n\nError stack trace:\n';
                          report += formatStackTrace(error);

                          report += '\n\nFull stack trace:\n';
                          report += error.fullStacktrace
                            .map((it) => `${it.fileName}:${it.lineNumber} in ${it.functionName}`)
                            .join('\n');

                          if (index < errorList.length - 1) {
                            report += '\n\n\n\nCaused by\n';
                          }

                          return report;
                        })
                        .join('');

                      await fetch('/api/v1/system/submitBugReport', {
                        method: 'POST',
                        credentials: 'include',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ report }),
                      });
                      setHasPostedReport(true);
                    }}
                  >
                    Submit report
                  </Button>{' '}
                  <Button
                    color="primary"
                    onClick={() => {
                      history.push('/');
                      resetErrorBoundary();
                    }}
                  >
                    Restart
                  </Button>
                </span>
              </>
            ) : (
              <span className="inner-detail">Loading report</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const Wrapper: FC<{}> = ({ children }) => {
  return (
    <ErrorBoundary
      FallbackComponent={BugReport}
      onReset={() => {
        console.log('reset');
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export default Wrapper;
