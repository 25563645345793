declare global {
  interface Window {
    be_sw_registration: ServiceWorkerRegistration;
    be_sw_push_subscription: PushSubscription
  }
}

function urlB64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function subscribeUser() {
  const applicationServerKey = urlB64ToUint8Array("BHJ6wn5MHE7j835Fb-irW5kzCjMsXlXg7cdeBWJnRYuKy1aWmkAZsklcmvtyMsDjxIQxgLM3AG3203dAlBRnspg");
  window.be_sw_registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: applicationServerKey
  })
  .then(function(subscription) {
    console.log('Device is subscribed.');
    window.be_sw_push_subscription = subscription
    fetch('/api/v1/web_push/register', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        subscription: JSON.stringify(subscription)
      }),
    });
  })
  .catch(function(err) {
    console.log('Failed to subscribe the user: ', err);
    console.log(err.stack);
  });
}

export function register() {
  window.addEventListener('load', () => {
    const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      console.log('Service Worker and Push is supported');
      navigator.serviceWorker.register(swUrl, { scope: "/" }).then(function(swReg) {
        console.log('Service Worker is registered', swReg);
        window.be_sw_registration = swReg
        subscribeUser()

        navigator.serviceWorker.addEventListener("message", (event) => {
          console.log(event.data.type, event.data.data);
        });    
      }).catch(function(error) {
        console.error('Service Worker Error', error);
      });
    }
  });
}

export async function unregister() {
  if ('serviceWorker' in navigator) {
    const registration = await navigator.serviceWorker.ready
    await registration.unregister();
  }
}

export async function unregisterAll() {
  if ('serviceWorker' in navigator) {
    const registrations = await navigator.serviceWorker.getRegistrations()
    for(let registration of registrations) {
      await registration.unregister()
    }
  }
}

export async function updateAll() {
  if ('serviceWorker' in navigator) {
    const registrations = await navigator.serviceWorker.getRegistrations()
    for(let registration of registrations) {
      await registration.update()
    }
  }
}
