import { AutoCompleteEntry } from 'components/Autocomplete';
import { BusinessPartnerType, IBusinessPartner } from 'interfaces';
import { Registry } from '.';

export default ({ get, post, destroy }: Registry) => {
  return {
    bp: {
      getByCode: (code: string): Promise<IBusinessPartner> => get(`/api/v1/bp/${code}`),
      autocomplete: (s: string): Promise<AutoCompleteEntry[]> => get(`/api/v1/bp/autocomplete/${s}`),
      getByType: (type: BusinessPartnerType): Promise<IBusinessPartner[]> => get(`/api/v1/bp/type/${type}`),
    },
  };
};
