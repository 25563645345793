import { run, sleep, useConsistentState } from 'component_utils/utils'
import { IWebSocketMessage } from 'interfaces'
import React, { FC, useCallback, useRef } from 'react'
import { Socket, SocketSubscriber } from 'utils/Socket'
import './SessionViewerListener.css'
import { useConfig } from 'utils/Config'
import chroma from 'chroma-js'
import { first } from 'lodash'

function getScrollParent(element: Element, includeHidden = false) {
  var style = getComputedStyle(element);
  var excludeStaticParent = style.position === "absolute";
  var overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;

  if (style.position === "fixed") return document.body;
  for (var parent = element; (parent = parent.parentElement);) {
      style = getComputedStyle(parent);
      if (excludeStaticParent && style.position === "static") {
          continue;
      }
      if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) return parent;
  }

  return document.body;
}

const CREATE_TEST_POINTS = false
let html2canvas: any = null

let html2canvasStreamTimeout: NodeJS.Timeout = null;
let html2canvasCanvasElem: HTMLCanvasElement = null
let html2canvasCanvasElem2dCtx: CanvasRenderingContext2D = null

let rtcRef: RTCPeerConnection = null
let dataChannel: RTCDataChannel = null
let stream: MediaStream = null
let resizeListener: any = null

let _streamingType: 'stream' | 'canvas_stream' | 'canvas_frames' = null
let _availableStreamingTypes: (typeof _streamingType)[] = null
function getAvailableStreamingTypes() {
  if (_availableStreamingTypes) return _availableStreamingTypes

  _availableStreamingTypes = []
  if ((navigator.mediaDevices as any).getDisplayMedia) {
    _availableStreamingTypes.push('stream')
  }  
  if ((document.createElement('canvas') as any).captureStream) {
    _availableStreamingTypes.push('canvas_stream')
  }
  _availableStreamingTypes.push('canvas_frames')
  console.log("[WEBRTC] available streaming types: ", _availableStreamingTypes);
  return _availableStreamingTypes
}
function getStreamingType() {
  return _streamingType
}

const SessionViewerListener: FC<{}> = () => {
  const sessionViewerRtcConfig = useConfig().settings.sessionViewerRtcConfig
  const cursorRef = useRef<HTMLDivElement>()
  const socketRef = useRef<SocketSubscriber>()
  const [mySignalTopic, , setMySignalTopic] = useConsistentState("")
  const [, otherSignalTopicRef, setOtherSignalTopic] = useConsistentState("")

  const topics = ['/user/queue/viewer_request_listener']
  if (mySignalTopic) topics.push(mySignalTopic)

  const closeConnection = useCallback(() => {
    _streamingType = null;
    const _dataChannel = dataChannel
    const _rtcRef = rtcRef
    const _stream = stream

    // notify the other side
    try {
      dataChannel?.send(JSON.stringify({
        type: 'close',
        data: {}
      }))
    } catch (e) {}
    
    // close
    setMySignalTopic("")
    setOtherSignalTopic("")
    dataChannel = null;
    rtcRef = null;
    stream = null;
    cursorRef.current?.remove()
    cursorRef.current = null;

    // close
    if (resizeListener) {
      window.removeEventListener('resize', resizeListener)
      resizeListener = null
    }

    // stop canvas stream
    if (html2canvasStreamTimeout)
      clearTimeout(html2canvasStreamTimeout)
    html2canvasStreamTimeout = null;
    html2canvasCanvasElem = null;
    html2canvasCanvasElem2dCtx = null;

    // stop the stream
    _stream?.getTracks().forEach(function (track) { track.stop(); });
    try { _dataChannel?.close() } catch(e) {}
    try { _rtcRef?.close() } catch(e) {}
  }, [setMySignalTopic, setOtherSignalTopic])

  const updateCanvasFrames = useCallback(async () => {
    try {
      const png = (await html2canvas(document.body, {
        logging: false
      })).toDataURL('image/png')
      dataChannel.send(JSON.stringify({
        type: 'frame',
        data: png
      }))
    } catch (e) {
      console.log("[WEBRTC] frame render failed: ", e);
      try {
        dataChannel.send(JSON.stringify({
          type: 'frameFailed',
          data: {}
        }))
      } catch (e1) {
        console.log("[WEBRTC] frame failed notify failed", e1);
      }
    }
  }, [])

  const updateCanvasStream = useCallback(async () => {
    try {
      const c = (await html2canvas(document.body, {
        logging: false
      })) as HTMLCanvasElement
  
      if (html2canvasCanvasElem.width !== c.width)
        html2canvasCanvasElem.width = c.width
      if (html2canvasCanvasElem.height !== c.height)
        html2canvasCanvasElem.height = c.height
      if (html2canvasCanvasElem.style.width !== c.style.width)
        html2canvasCanvasElem.style.width = c.style.width
      if (html2canvasCanvasElem.style.height !== c.style.height)
        html2canvasCanvasElem.style.height = c.style.height
      html2canvasCanvasElem2dCtx.drawImage(c, 0, 0)   
    } catch (e) {
      console.log("[WEBRTC] frame render failed: ", e);
    }

    if (html2canvasCanvasElem) {
      html2canvasStreamTimeout = setTimeout(() => {
        updateCanvasStream()
      }, 300);  
    }
  }, [])

  const initRTC = useCallback(async () => {
    let configuration = null;
    try { 
      if (sessionViewerRtcConfig) 
        configuration = JSON.parse(sessionViewerRtcConfig) 
    } catch (e) {}
    rtcRef = new RTCPeerConnection(configuration)
    
    const st = getStreamingType()

    if (!getAvailableStreamingTypes().includes(st)) {
      socketRef.current.sendMessage(otherSignalTopicRef.current, {
        what: 'refused',
        who: { userid: -2, username: '' },
        payload: {
          msg: "Unsupported streaming type " + st
        }
      })
      setTimeout(() => { closeConnection() }, 100);
      throw new Error("invalid type")
    }


    if (st === 'canvas_frames' || st === 'canvas_stream') {
      // load html2canvas to send screenshots instead of a stream
      html2canvas = (await import('html2canvas')).default
    }

    if (st === 'canvas_stream') {
      const body = document.body;
      const documentElement = document.documentElement;          
      const width = Math.max(
        Math.max(body.scrollWidth, documentElement.scrollWidth),
        Math.max(body.offsetWidth, documentElement.offsetWidth),
        Math.max(body.clientWidth, documentElement.clientWidth)
      );
      const height = Math.max(
        Math.max(body.scrollHeight, documentElement.scrollHeight),
        Math.max(body.offsetHeight, documentElement.offsetHeight),
        Math.max(body.clientHeight, documentElement.clientHeight)
      );

      html2canvasCanvasElem = document.createElement('canvas');
      html2canvasCanvasElem.width = width
      html2canvasCanvasElem.height = height
      html2canvasCanvasElem.style.width = width + 'px'
      html2canvasCanvasElem.style.height = height + 'px'
      html2canvasCanvasElem2dCtx = html2canvasCanvasElem.getContext('2d')
      try {
        await run(async () => {
          stream = (html2canvasCanvasElem as any).captureStream();
          stream.getTracks().forEach((track) => {
            console.log("[WEBRTC] adding track to rtc")
            rtcRef.addTrack(track, stream)
          });
        })
      } catch (e) {
        socketRef.current.sendMessage(otherSignalTopicRef.current, {
          what: 'refused',
          who: { userid: -2, username: '' },
          payload: {
            msg: "Canvas could not be streamed"
          }
        })
        setTimeout(() => { closeConnection() }, 100);
        throw e
      }  
    } else if (st === 'stream') {
      try {
        await run(async () => {
          const constraints: any = { 
            video: { cursor: 'always' }, 
            audio: false,
            preferCurrentTab: true,
          };
          stream = await (navigator.mediaDevices as any).getDisplayMedia(constraints);
          stream.getTracks().forEach((track) => {
            console.log("[WEBRTC] adding track to rtc")
            rtcRef.addTrack(track, stream)
          });
        })
      } catch (e) {
        socketRef.current.sendMessage(otherSignalTopicRef.current, {
          what: 'refused',
          who: { userid: -2, username: '' },
          payload: {
            msg: "User refused to share the screen"
          }
        })
        setTimeout(() => { closeConnection() }, 100);
        throw e
      }
    }

    rtcRef.onicecandidate = function(event) {
      console.log("[WEBRTC] candidate event")
      if (event.candidate) {
        socketRef.current.sendMessage(otherSignalTopicRef.current, {
          what: 'candidate',
          who: { userid: -2, username: '' },
          payload: event.candidate
        })    
      }
    };

    rtcRef.ontrack = function (event) {
      console.log("[WEBRTC] track arrived", event)
    }

    rtcRef.ondatachannel = function (event) {
      console.log("[WEBRTC] data channel arrived");
      dataChannel = event.channel;

      // if channel arrived -> setup:
      if (dataChannel.label === 'dataChannel') {
        // add the dot
        const cursorDiv = document.createElement("div");
        cursorRef.current = cursorDiv
        cursorDiv.classList.add("d-none", "remote-control-cursor")
        cursorDiv.style.left = '0px';
        cursorDiv.style.top = '0px';
        document.body.appendChild(cursorDiv)

        // setup channel handlers
        dataChannel.onerror = function(error) {
          console.log("[WEBRTC] Error occured on datachannel:", error);
        };
    
        // when we receive a message from the other peer, printing it on the console
        dataChannel.onmessage = function(event) {
          const msg = JSON.parse(event.data)
          if (msg.type === 'click') {
            const coord = msg.data as any;
            const clickedElement = document.elementFromPoint(coord.x, coord.y) as HTMLElement
            const eventStream = ['mousedown', 'mouseup', 'click']
            for (const evType of eventStream) {
              const ev = new MouseEvent(evType, {
                'view': window,
                'bubbles': true,
                'cancelable': true,
                'screenX': coord.x,
                'screenY': coord.y
              });
              clickedElement.dispatchEvent(ev);  
            }
            clickedElement.focus()
            
    
            if (CREATE_TEST_POINTS) {
              console.log("[WEBRTC] coord", coord)
              document.body.insertAdjacentHTML('beforeend', `<div class="remote-control-cursor" style="left: ${coord.x}px; top: ${coord.y}px"/>`)
            }
          }
          if (msg.type === 'dblclick') {
            const coord = msg.data as any;
            const clickedElement = document.elementFromPoint(coord.x, coord.y) as HTMLElement
            const ev = new MouseEvent('dblclick', {
              'view': window,
              'bubbles': true,
              'cancelable': true,
              'screenX': coord.x,
              'screenY': coord.y
            });
            clickedElement.dispatchEvent(ev);
            clickedElement.focus()
    
            if (CREATE_TEST_POINTS) {
              console.log("[WEBRTC] coord", coord)
              document.body.insertAdjacentHTML('beforeend', `<div class="remote-control-cursor" style="left: ${coord.x}px; top: ${coord.y}px"/>`)
            }
          }
          if (msg.type === 'paste') {
            const input = document.activeElement as HTMLInputElement
            if (input && ['INPUT', 'TEXTAREA'].includes(input.tagName.toUpperCase())) {
              const [start, end] = [input.selectionStart, input.selectionEnd];
              input.setRangeText(msg.data, start, end, 'end');
            }
          }
          if (msg.type === 'copy') {
            const text = window.getSelection().toString()
            dataChannel.send(JSON.stringify({
              type: 'copyResponse',
              data: text
            }))
          }
          if (msg.type === 'key') {
            const input = document.activeElement as HTMLInputElement
            if (input && ['INPUT', 'TEXTAREA'].includes(input.tagName.toUpperCase())) {
              if (msg.data === 'Enter') {
                input.dispatchEvent(new KeyboardEvent('keydown', {
                  code: 'Enter',
                  key: 'Enter',
                  view: window,
                  bubbles: true
                }))
                input.dispatchEvent(new KeyboardEvent('keyup', {
                  code: 'Enter',
                  key: 'Enter',
                  view: window,
                  bubbles: true
                }))
              } else if (msg.data === 'Backspace') {
                const selectionStart = input.selectionStart
                input.value = input.value.slice(0, input.selectionStart - 1) + input.value.slice(input.selectionStart, input.value.length);
                input.setSelectionRange(selectionStart - 1, selectionStart - 1)
              } else if (msg.data === 'ArrowLeft') {
                input.setSelectionRange(input.selectionStart - 1, input.selectionStart - 1)
              } else if (msg.data === 'ArrowRight') {
                input.setSelectionRange(input.selectionStart + 1, input.selectionStart + 1)
              } else if (msg.data && msg.data.length === 1) {
                const [start, end] = [input.selectionStart, input.selectionEnd];
                input.setRangeText(msg.data, start, end, 'end');
              }
            }
          }
          if (msg.type === 'close') {
            console.log("[WEBRTC] closing connection")
            closeConnection()
          } 
          if (msg.type === 'wheel') {
            const coord = msg.data.coords as any;
            const clickedElement = document.elementFromPoint(coord.x, coord.y) as HTMLElement
            const scrollableElement = getScrollParent(clickedElement)
            console.log("[WEBRTC] scroll", scrollableElement)
            if (scrollableElement) {
              scrollableElement.scrollTop += msg.data.deltaY
              scrollableElement.scrollLeft += msg.data.deltaX
            }
          }
          if (msg.type === 'mouseMove') {
            const coord = msg.data as any;
            cursorRef.current.classList.remove("d-none")
            cursorRef.current.style.left = `${coord.x}px`;
            cursorRef.current.style.top = `${coord.y}px`;
          }
          if (msg.type === 'mouseLeave') {
            cursorRef.current.classList.add("d-none")
          }
          if (msg.type === 'frameReceived') {
            if (getStreamingType() === 'canvas_frames') {
              setTimeout(() => {
                updateCanvasFrames()
              }, 50);
            }
          }
        };
    
        dataChannel.onclose = function() {
            console.log("[WEBRTC] data channel is closed");
            closeConnection()
        };
    
        dataChannel.onopen = async () => {
          await sleep(500)
  
          // initialize the client
          resizeListener = () => {
            dataChannel?.send(JSON.stringify({
              type: 'notifyScreenSize',
              data: {
                width: window.innerWidth,
                height: window.innerHeight
              }
            }))
          }
          resizeListener()
          window.addEventListener('resize', resizeListener)
  
          let st = getStreamingType()
          if (st === 'canvas_frames') {
            setTimeout(() => {
              updateCanvasFrames()
            }, 1000);
          } else if (st === 'canvas_stream') {
            setTimeout(async () => {
              const iters = 100.0
              const cscale = chroma.scale([
                'white', 'black', 'white', 'black'
              ]);
              for (let i = 0; i < iters; i++) {
                html2canvasCanvasElem2dCtx.fillStyle = cscale(i / iters).hex();
                html2canvasCanvasElem2dCtx.fillRect(
                  0, 0, html2canvasCanvasElem.width, html2canvasCanvasElem.height
                );

                html2canvasCanvasElem2dCtx.fillStyle = cscale(1.0 - (i / iters)).hex();
                html2canvasCanvasElem2dCtx.beginPath();
                html2canvasCanvasElem2dCtx.arc(
                  html2canvasCanvasElem.width / 2.0, 
                  html2canvasCanvasElem.height / 2.0, 
                  30, 
                  0, 
                  2 * Math.PI
                );
                html2canvasCanvasElem2dCtx.fill();
              
                await sleep(50)
              }

              updateCanvasStream()
            }, 100);
          }
        }
      }
    };  
  }, [otherSignalTopicRef, closeConnection, updateCanvasFrames, updateCanvasStream, sessionViewerRtcConfig])

  return (
    <Socket
      topics={topics}
      ref={socketRef}
      onMessage={async (topic, msg: IWebSocketMessage<any>) => {
        if (topic === '/user/queue/viewer_request_listener') {
          if (document.hidden) {
            // only trigger it on visible tabs
            return
          }

          if (rtcRef) {
            const otherTopic = '/ctopic/viewer/' + msg.payload.conn_id + "-initiator"
            socketRef.current.sendMessage(otherTopic, {
              what: 'refused',
              who: { userid: -2, username: '' },
              payload: {
                msg: "Screen is already captured"
              }
            })
            throw new Error("already occupied")      
          }

          _streamingType = msg.payload.metadata.preferredMode || first(getAvailableStreamingTypes());
          await setOtherSignalTopic('/ctopic/viewer/' + msg.payload.conn_id + "-initiator")
          await setMySignalTopic('/ctopic/viewer/' + msg.payload.conn_id + "-client")
          await sleep(1000)
          await initRTC()
          console.log("[WEBRTC] initiating connection", msg.payload.conn_id, msg.payload.preferredMode)
          socketRef.current.sendMessage(otherSignalTopicRef.current, {
            what: 'hello',
            who: { userid: -2, username: '' },
            payload: {
              supportsNativeScreenCapture: (['stream', 'canvas_stream'].includes(getStreamingType()))
            }
          })
          return  
        }

        // process connection request
        if (msg.what === 'hello') {
          console.log("[WEBRTC] sending offer", msg.payload)
          const offer = await rtcRef.createOffer({
            offerToReceiveVideo: true
          })
          rtcRef.setLocalDescription(offer);
          socketRef.current.sendMessage(otherSignalTopicRef.current, {
            what: 'offer',
            who: { userid: -1, username: '' },
            payload: offer
          })    
        }
        if (msg.what === 'offer') {
          console.log("[WEBRTC] answer", msg.payload)
          rtcRef.setRemoteDescription(new RTCSessionDescription(msg.payload));
          const answer = await rtcRef.createAnswer();
          rtcRef.setLocalDescription(answer);
          socketRef.current.sendMessage(otherSignalTopicRef.current, {
            what: 'answer',
            who: { userid: -1, username: '' },
            payload: answer
          })    
        }
        if (msg.what === 'answer') {
          console.log("[WEBRTC] received answer", msg.payload)
          rtcRef.setRemoteDescription(new RTCSessionDescription(msg.payload));
        }
        if (msg.what === 'candidate') {
          console.log("[WEBRTC] received candidate", msg.payload)
          rtcRef.addIceCandidate(new RTCIceCandidate(msg.payload));
        }
      }}
    />
  )
}

export default SessionViewerListener;