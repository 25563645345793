import DOMPurify from 'dompurify';
import React, { FC } from 'react';

const Raw: FC<{
  children: string;
}> = ({ children }) => {
  const inner = DOMPurify.sanitize(children || '', { USE_PROFILES: { html: true } });
  return <span dangerouslySetInnerHTML={{ __html: inner }} />;
};

export default Raw;
