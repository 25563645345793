import React from 'react';
import { Button, ButtonGroup, InputGroup } from 'reactstrap';
import Translation from 'utils/Language/Translation';

interface Props {
  onYes: () => any;
  onNo: () => any;
}

export default ({ onYes, onNo }: Props) => {
  return (
    <InputGroup>
      <ButtonGroup size="lg" className="w-100">
        <Button className="w-100" color="danger" onClick={onNo}>
          <Translation name="T.misc.no" />
        </Button>
        <Button className="w-100" color="success" onClick={onYes}>
          <Translation name="T.misc.yes" />
        </Button>
      </ButtonGroup>
    </InputGroup>
  );
};
