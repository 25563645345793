import ReactDOM from 'react-dom';
import { faKeyboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Button } from 'reactstrap';
import { ScannerInputModes } from '.';
import { useMemo } from 'react';
import { useConfig } from 'utils/Config';
import Calender, { Detail } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';

interface Props {
  inputRef: React.MutableRefObject<HTMLInputElement>;
  inputMode: ScannerInputModes;
  inputStep?: any;
  onEnter: () => void;
  onKeyUp: () => any;
}

export interface VirtualKeyboardRef {
  setKeyboardOpen: (val: boolean) => void;
}

export default React.forwardRef<VirtualKeyboardRef, Props>(({ inputMode, inputStep, inputRef, onEnter, onKeyUp }, ref) => {
  const [keyboardOpen, _setKeyboardOpen] = useState(false);
  const [forceType, setForceType] = useState<ScannerInputModes>(null);
  const [shift, setShift] = useState(false);
  const [calenderView, setCalenderView] = useState<Detail>('decade');
  const divRef = useRef<HTMLDivElement>(null);
  const config = useConfig();

  const [keyboardLayoutMap, displayValues] = useMemo(() => {
    const createLayout = (layout: string) => {
      return layout
        .split('\n')
        .filter((it) => it)
        .map((line) => line.split(/\s+/));
    };

    const keyboardLayoutMap = {
      text: createLayout(config.virtualKeyboard.textKeyboardLayout),
      numeric: createLayout(config.virtualKeyboard.numericKeyboardLayout),
      date: createLayout('{enter} {close}'),
      none: createLayout(''),
    };
    return [keyboardLayoutMap, config.virtualKeyboard.visualizations];
  }, [config]);

  const setKeyboardOpen = useCallback(
    (open: boolean) => {
      if (open) {
        setCalenderView('decade');
        setShift(false);
        setForceType(null);
      }
      _setKeyboardOpen(open);
    },
    [setShift, setForceType, _setKeyboardOpen],
  );

  useEffect(() => {
    setKeyboardOpen(inputMode === 'numeric' || inputMode === 'date');
  }, [setKeyboardOpen, inputMode, inputStep]);

  useEffect(() => {
    if (keyboardOpen) {
      const handleClick = (e: any) => {
        if (divRef.current && !divRef.current.contains(e.target)) {
          setKeyboardOpen(false);
        }
      };
      document.addEventListener('click', handleClick);
      return () => {
        document.removeEventListener('click', handleClick);
      };
    }
  }, [keyboardOpen, divRef, setKeyboardOpen]);

  useImperativeHandle(
    ref,
    () => ({
      setKeyboardOpen,
    }),
    [setKeyboardOpen],
  );

  const isOpen = keyboardOpen && inputMode !== 'none';
  const root: CSSProperties = {
    display: isOpen ? 'block' : 'none',
    position: 'absolute',
    zIndex: 9000000,
    background: 'white',
    width: '100%',
    bottom: '0',
    left: '0',
  };

  // get the appropriate layout
  let layoutType: keyof typeof keyboardLayoutMap = forceType || inputMode;
  if (!Object.keys(keyboardLayoutMap).includes(layoutType)) {
    layoutType = 'text';
  }

  function renderKeyboard(keyboardLines: string[][]) {
    return (
      <div className="btn-group-vertical width-100" role="group" aria-label="Basic example">
        {keyboardLines.map((keys, index) => {
          return (
            <div className="btn-group" key={index}>
              {keys.map((key, index) => {
                // extra data
                let extra = {};

                // get the symbol
                let symbol = key;
                let textColor = null;
                let bgColor = null;
                if (key in displayValues) {
                  const formatter = displayValues[key];
                  symbol = formatter.symbol || '';
                  textColor = formatter.color;
                  bgColor = formatter.bgColor;
                }
                if (shift) {
                  symbol = symbol.toUpperCase();
                }

                // get the callback
                let callback: () => any = () => {
                  inputRef.current.value = inputRef.current.value + symbol;
                  onKeyUp();
                };
                if (key === '{bksp}') {
                  callback = () => (inputRef.current.value = inputRef.current.value.slice(0, -1));
                }
                if (key === '{enter}') {
                  callback = () => {
                    setKeyboardOpen(false);
                    onEnter();
                  };
                }
                if (key === '{close}') {
                  callback = () => setKeyboardOpen(false);
                }
                if (key === '{shift}') {
                  callback = () => setShift(!shift);
                }
                if (key === '{123}') {
                  callback = () => setForceType('numeric');
                }
                if (key === '{abc}') {
                  callback = () => setForceType('text');
                }
                if (key === '{date}') {
                  callback = () => setForceType('date');
                }

                return (
                  <button
                    key={index}
                    type="button"
                    className={`btn btn-outline-secondary py-3`}
                    onClick={callback}
                    tabIndex={-1}
                    unselectable="on"
                    onMouseDown={(e) => {
                      e.preventDefault();
                    }}
                    data-force-auto-focus="true"
                    style={{
                      width: `${100 / keys.length}%`,
                      color: textColor,
                      backgroundColor: bgColor,
                    }}
                    {...extra}
                  >
                    <b>{symbol}</b>
                  </button>
                );
              })}
            </div>
          );
        })}
      </div>
    )
  }

  // render the keyboard
  return (
    <>
      <Button color="dark" data-testid="openVirtualKeyboard" onClick={() => setKeyboardOpen(!keyboardOpen)}>
        <FontAwesomeIcon icon={faKeyboard} />
      </Button>
      {ReactDOM.createPortal(
        <div data-prevent-auto-focus="true" style={root} ref={divRef} onClick={e => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation()
        }}>
          {(layoutType === 'date') && (
            <>
              <Calender 
                view={calenderView} 
                onViewChange={e => setCalenderView(e.view)} 
                className="w-100" 
                onChange={(e: Date) => {
                  const formatted = moment(e).format('DD-MM-YYYY')
                  inputRef.current.value = formatted
                  inputRef.current.focus()
                  setKeyboardOpen(false);
                  onEnter();
                }}
              />
              {renderKeyboard(keyboardLayoutMap[layoutType] || [])}
            </>
          )}
          {(layoutType === 'text' || layoutType === 'numeric') && (
            renderKeyboard(keyboardLayoutMap[layoutType] || [])
          )}
        </div>,
        document.body,
      )}
    </>
  );
});
