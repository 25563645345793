import { useLayoutEffect, useState, Ref, useCallback } from 'react';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import { isFunction } from 'lodash';

interface ReactResizeDetectorDimensions {
  height?: number;
  width?: number;
}

interface Props {
  onResize?: (width?: number, height?: number) => void;
  handleHeight?: boolean;
  handleWidth?: boolean;
  refreshMode?: 'throttle' | 'debounce';
  refreshRate?: number;
  refreshOptions?: { leading?: boolean; trailing?: boolean };
  observerOptions?: ResizeObserverOptions;
}

const patchResizeHandler = (
  resizeCallback: ResizeObserverCallback,
  refreshMode: Props['refreshMode'],
  refreshRate: Props['refreshRate'],
  refreshOptions: Props['refreshOptions'],
) => {
  switch (refreshMode) {
    case 'debounce':
      return debounce(resizeCallback, refreshRate, refreshOptions);
    case 'throttle':
      return throttle(resizeCallback, refreshRate, refreshOptions);
    default:
      return resizeCallback;
  }
};

export const createNotifier =
  (
    onResize: Props['onResize'],
    setSize: React.Dispatch<React.SetStateAction<ReactResizeDetectorDimensions>>,
    handleWidth: boolean,
    handleHeight: boolean,
  ) =>
  ({ width, height }: ReactResizeDetectorDimensions): void => {
    setSize((prev) => {
      if (prev.width === width && prev.height === height) {
        // skip if dimensions haven't changed
        return prev;
      }

      if ((prev.width === width && !handleHeight) || (prev.height === height && !handleWidth)) {
        // process `handleHeight/handleWidth` props
        return prev;
      }

      if (onResize && isFunction(onResize)) {
        onResize(width, height);
      }

      return { width, height };
    });
  };

function useResizeDetector<T = any>(props: Props = {}) {
  const {
    refreshMode,
    refreshRate = 1000,
    refreshOptions,
    handleWidth = true,
    handleHeight = true,
    observerOptions,
    onResize,
  } = props;

  const [element, setElement] = useState<T>(null);
  const [size, setSize] = useState<ReactResizeDetectorDimensions>({
    width: undefined,
    height: undefined,
  });

  useLayoutEffect(() => {
    const notifyResize = createNotifier(onResize, setSize, handleWidth, handleHeight);
    const resizeCallback: ResizeObserverCallback = (entries) => {
      if (!handleWidth && !handleHeight) return;
      entries.forEach((entry) => {
        const { width, height } = (entry && entry.contentRect) || {};
        notifyResize({ width, height });
      });
    };
    const resizeHandler = patchResizeHandler(resizeCallback, refreshMode, refreshRate, refreshOptions);
    const resizeObserver = new window.ResizeObserver(resizeHandler);

    if (element) {
      resizeObserver.observe(element as any, observerOptions);
    }

    return () => {
      resizeObserver.disconnect();
      const patchedResizeHandler = resizeHandler as any;
      if (patchedResizeHandler && patchedResizeHandler.cancel) {
        patchedResizeHandler.cancel();
      }
    };
  }, [refreshMode, refreshRate, refreshOptions, handleWidth, handleHeight, onResize, observerOptions, element]);

  const ref: Ref<T> = useCallback(
    (element) => {
      setElement(element);
      if (!element) {
        setSize({
          width: undefined,
          height: undefined,
        });
      }
    },
    [setElement, setSize],
  );
  return { ref, ...size };
}

export default useResizeDetector;
