import React, { FC, useState } from 'react';
import { ButtonProps, Button } from 'reactstrap';

interface Props extends ButtonProps {}

const ProcessingButton: FC<Props> = ({ children, disabled, onClick, ...rest }) => {
  const [busy, setBusy] = useState(false);
  return (
    <Button
      {...rest}
      disabled={busy || disabled}
      onClick={async (...args) => {
        try {
          setBusy(true);
          await onClick(...args);
        } finally {
          setBusy(false);
        }
      }}
      data-test-running={busy}
    >
      {children}
    </Button>
  );
};

export default ProcessingButton;
