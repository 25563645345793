import { IItem, IItemBinLocation } from 'interfaces';
import { first, groupBy, sumBy } from 'lodash';
import timed from './timed';

export function isNormal(item: IItem) {
  return item?.managedBy === 'NORMAL';
}

export function isBatch(item: IItem) {
  return item?.managedBy === 'BATCH_NUMBER';
}

export function isSerial(item: IItem) {
  return item?.managedBy === 'SERIAL_NUMBER';
}

export function hasBatch(item: IItem) {
  return isBatch(item) || isSerial(item);
}

export function hasSerials(item: IItem) {
  return isSerial(item);
}

/**
 * ASSUMES ONLY A SINGLE LOCATION
 * This method creates a tree of the shape item -> batch -> serials
 */
export type IContentsItemTreeLine = ReturnType<typeof toItemTree>[0];
export const toItemTree = timed('toItemTree', (contents: IItemBinLocation[]) => {
  const collapseBatches = (itemLines: IItemBinLocation[], item: IItem) => {
    if (!(isBatch(item) || isSerial(item))) {
      return [];
    }

    const groupedBatches = groupBy(itemLines, (it) => (isBatch(item) ? it.batch.visualCode : it.serialNumber.lotCode));
    return Object.keys(groupedBatches).map((k) => {
      const batchLines = groupedBatches[k];
      return {
        batchCode: k,
        batch: first(batchLines).batch,
        freeToMoveQty: sumBy(batchLines, (it) => it.freeToMoveQty),
        onHandQty: sumBy(batchLines, (it) => it.onHandQty),
        pickQty: sumBy(batchLines, (it) => it.pickQty),
        releasedQty: sumBy(batchLines, (it) => it.releasedQty),
        serials: isSerial(item)
          ? batchLines.map((it) => {
              return {
                serial: it.serialNumber,
                freeToMoveQty: it.freeToMoveQty,
                onHandQty: it.onHandQty,
                pickQty: it.pickQty,
                releasedQty: it.releasedQty,
              };
            })
          : [],
      };
    });
  };

  const groupedItems = groupBy(contents, (it) => it.item.code);
  return Object.keys(groupedItems).map((k) => {
    const itemLines = groupedItems[k];
    const item = first(itemLines).item;
    return {
      item,
      freeToMoveQty: sumBy(itemLines, (it) => it.freeToMoveQty),
      onHandQty: sumBy(itemLines, (it) => it.onHandQty),
      pickQty: sumBy(itemLines, (it) => it.pickQty),
      releasedQty: sumBy(itemLines, (it) => it.releasedQty),
      batches: collapseBatches(itemLines, item),
    };
  });
});

/**
 * ASSUMES ONLY A SINGLE ITEM
 * This method creates a tree of the shape location -> batch -> serials
 */
export type IContentsLocationTreeLine = ReturnType<typeof toLocationTree>[0];
export const toLocationTree = timed('toLocationTree', (contents: IItemBinLocation[]) => {
  const collapseBatches = (itemLines: IItemBinLocation[], item: IItem) => {
    if (!(isBatch(item) || isSerial(item))) {
      return [];
    }

    const groupedBatches = groupBy(itemLines, (it) => (isBatch(item) ? it.batch.visualCode : it.serialNumber.lotCode));
    return Object.keys(groupedBatches).map((k) => {
      const batchLines = groupedBatches[k];
      return {
        batchCode: k,
        batch: first(batchLines).batch,
        freeToMoveQty: sumBy(batchLines, (it) => it.freeToMoveQty),
        onHandQty: sumBy(batchLines, (it) => it.onHandQty),
        pickQty: sumBy(batchLines, (it) => it.pickQty),
        releasedQty: sumBy(batchLines, (it) => it.releasedQty),
        serials: isSerial(item)
          ? batchLines.map((it) => {
              return {
                serial: it.serialNumber,
                freeToMoveQty: it.freeToMoveQty,
                onHandQty: it.onHandQty,
                pickQty: it.pickQty,
                releasedQty: it.releasedQty,
              };
            })
          : [],
      };
    });
  };

  const groupedLocations = groupBy(contents, (it) => it.binLocation.code);
  return Object.keys(groupedLocations).map((k) => {
    const locationLines = groupedLocations[k];
    const location = first(locationLines).binLocation;
    return {
      location,
      freeToMoveQty: sumBy(locationLines, (it) => it.freeToMoveQty),
      onHandQty: sumBy(locationLines, (it) => it.onHandQty),
      pickQty: sumBy(locationLines, (it) => it.pickQty),
      releasedQty: sumBy(locationLines, (it) => it.releasedQty),
      batches: collapseBatches(locationLines, first(locationLines).item),
    };
  });
});

/**
 * This method creates a tree of the shape location -> item -> batch -> serials
 */
export type IContentsLocationItemTreeLine = ReturnType<typeof toLocationItemTree>[0];
export const toLocationItemTree = timed('toLocationItemTree', (contents: IItemBinLocation[]) => {
  const groupedLocations = groupBy(contents, (it) => it.binLocation.code);
  return Object.keys(groupedLocations).map((k) => {
    const locationLines = groupedLocations[k];
    const location = first(locationLines).binLocation;
    return {
      location,
      freeToMoveQty: sumBy(locationLines, (it) => it.freeToMoveQty),
      onHandQty: sumBy(locationLines, (it) => it.onHandQty),
      pickQty: sumBy(locationLines, (it) => it.pickQty),
      releasedQty: sumBy(locationLines, (it) => it.releasedQty),
      items: toItemTree(locationLines),
    };
  });
});
