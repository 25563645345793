type LibraryLoadedCallback = () => any;
const loadedLibraries: string[] = [];
const callbacks: {[k:string]: LibraryLoadedCallback[]} = {};

export const JSRSASIGN = '/external/jsrsasign.js';
export const OPENCV = '/external/opencv.js';

const handleLibrariesAfterLoad = async (name: string) => {
  if (name === OPENCV) {
    (window as any).cv = await (window as any).cv;
  }
};

export function use_external_library_async(name: string) {
  return new Promise((resolve) => use_external_library(name, resolve as LibraryLoadedCallback));
}

export function use_external_library(name: string, callback: LibraryLoadedCallback) {
  if (loadedLibraries.includes(name)) {
    callback && callback();
  } else if (Object.keys(callbacks).includes(name)) {
    callbacks[name].push(callback);
  } else {
    callbacks[name] = [callback];
    const script = document.createElement('script');
    script.src = name;
    script.async = true;
    script.onload = async () => {
      // handle special cases to put them on the global space
      await handleLibrariesAfterLoad(name);

      // loaded
      console.log('Loaded library ' + name);
      loadedLibraries.push(name);
      callbacks[name].forEach((callback) => {
        callback && callback();
      });
      delete callbacks[name];
    };
    document.body.appendChild(script);
  }
}

export function preload_external_library(name: string) {
  return use_external_library(name, () => {});
}

export function use_external_libraries(names: string[], callback: LibraryLoadedCallback, inOrder: Boolean = true) {
  if (inOrder) {
    // load the libraries in order
    (async () => {
      for (const name of names) {
        await use_external_library_async(name)
      }
      callback()
    })()
  } else {
    // simple case, load them all at the same time
    let loaded = 0;
    for (const name of names) {
      // eslint-disable-next-line no-loop-func
      use_external_library(name, () => {
        loaded++;
        if (loaded === names.length) {
          callback()
        }
      })
    }  
  }
}

export function use_external_libraries_async(names: string[], inOrder: Boolean = true) {
  return new Promise((resolve) => use_external_libraries(names, resolve as LibraryLoadedCallback, inOrder));
}

export function setup() {
  (window as any).beLibraryLoader = {
    use_external_library,
    use_external_library_async,

    use_external_libraries,
    use_external_libraries_async
  }
}