import {Language} from './app-utils';
import {IBinLocation} from './models-binLocation';
import {IItem} from './models-item';
import {Type} from './settings-erp';
import {Role} from './utils-role';

export interface IAttachment {
  blob: number[];
  extension: string;
  name: string;
}

export interface ICountedEntry {
  counterName: string;
  counterNum: number;
  counterQty: number;
  id: number;
}

export interface IKardexBox {
  batchLot: string;
  binCode: string;
  createdAt: Date;
  itemCode: string;
  kardexBoxCode: string;
  quantity: number;
}

export interface IKardexSettings {
  activeMode: boolean;
  connectionType: KardexConnectionType;
  extra: {[key: string] :string};
  imageFolderPath: string;
  inboundDestinationFolderPath: string;
  isSecure: boolean;
  locationId: string;
  outboundDestinationFolderPath: string;
  password: string;
  port: number;
readonly   secure: boolean;
  url: string;
  username: string;
}

export interface IPrinterConfig {
  directory: string;
  id: number;
  ip: string;
  name: string;
  nativePrinterName: string;
  peripheralConfig: string;
  peripheralType: string;
  port: number;
  protocol: PrinterProtocol;
  secure: boolean;
  type: PrinterType;
}

export interface IRecountingDocument {
  currentUser: IUser;
  erpKey: string;
  id: number;
  numberOfCounters: number;
  rows: IRecountingRow[];
}

export interface IRecountingRow {
  binCode: string;
  edited: boolean;
  erpQuantity: number;
  id: number;
  item: IItem;
  itemCode: string;
  location: IBinLocation;
  previousCounters: ICountedEntry[];
  quantity: number;
  scannedQuantity: undefined | number;
}

export interface IScriptingSecret {
  id: number;
  name: string;
  value: string;
}

export interface IShippingContainer {
  code: string;
  contents: IShippingContainerContents[];
  depth: number;
  height: number;
  itemCode: string;
  name: string;
  type: string;
  weight: number;
  width: number;
}

export interface IShippingContainerContents {
  batchCode: string;
  itemCode: string;
  quantity: number;
  serialCode: string;
}

export interface IUser {
readonly   accountNonExpired: boolean;
readonly   accountNonLocked: boolean;
readonly   authorities: any[];
  createdAt: any;
readonly   credentialsNonExpired: boolean;
readonly   enabled: boolean;
  externalId: string;
  id: number;
readonly   preferredLanguage: Language;
  roles: Role[];
  updatedAt: any;
  userSettings: IUserSettings;
  username: string;
}

export interface IUserSettings {
  allowMultipleTabsActiveOnSameDevice: boolean;
  extra: {[key: string] :string};
  linkableHoldToOpenDuration: number;
  menuFavorites: string[];
  menuOnlyOneOpenAtOnce: boolean;
  menuToggleShortcutKey: {[key: string] :Object} | {[key: string] :any};
  pickingToSound: boolean;
  popupDuration: number;
  preferredLanguage: Language;
}

export interface IWhsPerformanceUserWidget {
  erpType: Type;
  group: string;
  id: number;
  name: string;
  query: string;
  widgetSettings: IWhsPerformanceUserWidget$IWidgetSettings;
}

export interface IWhsPerformanceUserWidget$IWidgetSettings {
  bar: IWhsPerformanceUserWidget$IWidgetSettings$IBar;
  colorScale: string[];
  customGenerator: string;
  groupCol: string;
  intensityCol: string;
  labelCol: string;
  scatter: IWhsPerformanceUserWidget$IWidgetSettings$IScatter;
  title: string;
  type: WarehousePerformanceChartTypes;
  version: number;
  xCol: string;
  xlabelTiltAngle: number;
  yCol: string;
}

export interface IWhsPerformanceUserWidget$IWidgetSettings$IBar {
  horizontal: boolean;
  stacked: boolean;
}

export interface IWhsPerformanceUserWidget$IWidgetSettings$IScatter {
  max: number;
  min: number;
}

export interface IWtwBatchCountingDocument {
  countingListId: string;
  id: number;
  rows: IWtwBatchLocation[];
}

export interface IWtwBatchLocation {
  binCode: string;
  binLocation: IBinLocation;
  id: number;
  rows: IWtwBatchLocationRow[];
}

export interface IWtwBatchLocationRow {
  batch: string;
  id: number;
  item: IItem;
  itemCode: string;
  lot: string;
  qty: number;
  serial: string;
}

export type KardexConnectionType = 'FTP' | 'FILE';
export const KardexConnectionTypeValues: KardexConnectionType[] = ['FTP' , 'FILE'];

export type PrinterProtocol = 'RAW' | 'PJL' | 'IPP' | 'CUPS' | 'FILE' | 'VOID' | 'PERIPHERAL' | 'NATIVE';
export const PrinterProtocolValues: PrinterProtocol[] = ['RAW' , 'PJL' , 'IPP' , 'CUPS' , 'FILE' , 'VOID' , 'PERIPHERAL' , 'NATIVE'];

export type PrinterType = 'A4' | 'LABEL';
export const PrinterTypeValues: PrinterType[] = ['A4' , 'LABEL'];

export type WarehousePerformanceChartTypes = 'PIE' | 'BAR' | 'LINE' | 'SCATTER' | 'GAUGE' | 'CUSTOM' | 'GRID';
export const WarehousePerformanceChartTypesValues: WarehousePerformanceChartTypes[] = ['PIE' , 'BAR' , 'LINE' , 'SCATTER' , 'GAUGE' , 'CUSTOM' , 'GRID'];

