import React, { FC, useEffect, useRef, useState } from 'react';
import { useAuth } from 'utils/API';
import './style.css';

interface Props {
  onOpen: () => any;
}

const SensitiveLinkable: FC<Props> = ({ onOpen, children }) => {
  const [forceLinkable, setForceLinkable] = useState(false);
  const [inButton, setInButton] = useState(true);
  const [keyDown, setKeyDown] = useState(false);
  const ref = useRef<HTMLSpanElement>();

  useEffect(() => {
    setInButton(ref.current.closest('button') !== null);
  }, []);

  useEffect(() => {
    const listenForShake = () => setForceLinkable(old => !old)
    window.addEventListener('shake', listenForShake, false);
    return () => {
      window.removeEventListener('shake', listenForShake, false);
    }
  }, [])

  useEffect(() => {
    const handleDown = (e: any) => {
      if (e.altKey) setKeyDown(true);
    };
    const handleUp = (e: any) => {
      if (!e.altKey) setKeyDown(false);
    };

    window.addEventListener('keydown', handleDown);
    window.addEventListener('keyup', handleUp);
    return () => {
      window.removeEventListener('keydown', handleDown);
      window.removeEventListener('keyup', handleUp);
    };
  }, [setKeyDown]);

  if (inButton && !keyDown && !forceLinkable) {
    return <span ref={ref}>{children}</span>;
  } else {
    return <Linkable onOpen={onOpen}>{children}</Linkable>;
  }
};

const Linkable: FC<Props> = ({ onOpen, children }) => {
  const isMouse = useRef<null | 'touch' | 'mouse'>(null);
  const uRef = useRef<HTMLElement>(null);
  const user = useAuth();

  const checkPropagateClick = (e: any) => {
    if (isMouse.current === 'mouse') {
      e.preventDefault();
      e.stopPropagation();
      onOpen();
    }
  };

  return (
    <span
      className="object_link"
      ref={uRef}
      data-prevent-auto-focus="true"
      onClick={checkPropagateClick}
      onContextMenu={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onMouseDown={(e) => {
        isMouse.current = 'mouse';
      }}
      onTouchStart={(e) => {
        isMouse.current = 'touch';
        let longClickTimer: NodeJS.Timeout = null;
        let isLongClick = false;
        let isInElement = false;

        const enterElement = () => {
          if (isInElement) {
            return;
          }

          // enter the element
          isInElement = true;
          uRef.current.classList.add('object_animate_longpress');
          longClickTimer = setTimeout(function () {
            if (uRef.current) {
              uRef.current.classList.remove('object_animate_longpress');
            }
            isLongClick = true;
            onOpen();
          }, user.userSettings.linkableHoldToOpenDuration);
        };
        const leaveElement = () => {
          if (!isInElement) {
            return;
          }

          // leave the element
          isInElement = false;
          clearTimeout(longClickTimer);
          uRef.current.classList.remove('object_animate_longpress');
        };

        // start tracking the point
        const { clientX: sx, clientY: sy } = e.changedTouches[0];
        const starget = document.elementFromPoint(sx, sy);
        enterElement();

        const touchMove = (e: TouchEvent) => {
          const { clientX: mx, clientY: my } = e.changedTouches[0];
          const mtarget = document.elementFromPoint(mx, my);
          if (starget === mtarget) {
            enterElement();
          } else if (starget !== mtarget) {
            leaveElement();
          }
        };

        const touchUp = (e: TouchEvent) => {
          // make sure the mouse events are not called
          // but we still want to call the onclick handlers
          e.preventDefault();
          const { clientX: ex, clientY: ey } = e.changedTouches[0];
          const etarget = document.elementFromPoint(ex, ey);
          if (!isLongClick && etarget === starget) {
            clearTimeout(longClickTimer);
            uRef.current?.click();
            uRef.current?.classList.remove('object_animate_longpress');
          }

          // cleanup
          document.removeEventListener('touchmove', touchMove);
          document.removeEventListener('touchend', touchUp);
          document.removeEventListener('touchcancel', touchUp);
        };

        document.addEventListener('touchmove', touchMove);
        document.addEventListener('touchend', touchUp);
        document.addEventListener('touchcancel', touchUp);
      }}
    >
      {children}
    </span>
  );
};

export default SensitiveLinkable;
