import React, { useState, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import SimpleModal from 'components/SimpleModal';
import { Button, ButtonGroup, Container, Table, UncontrolledTooltip } from 'reactstrap';
import { IPrinterConfig } from 'interfaces';
import { ICurrentDeviceConfig, useApi, useDeviceConfig } from 'utils/API';
import { useTranslations } from 'utils/language';
import { addNotification, useNotifications } from 'utils/NotificationManager';
import Translation from 'utils/Language/Translation';
import { getVoiceForId, getVoiceId, getVoices, mobileAndTabletCheck, tts } from 'component_utils/utils';
import { Tab, UncontrolledTabs } from 'components/uncontrolled_tabs';
import ProcessingButton from 'components/ProcessingButton';
import { FaQuestionCircle } from 'react-icons/fa';
import { FormSelectButton, FormState, FormRange, FormToggle, Labeled, FloatType, FormInput } from 'component_utils/FormUtils';
import Bool from 'components/Bool';
import { areNotificationsEnabled } from 'routes/tools/user_settings';
import * as serviceWorker from 'serviceWorker';
import RoleProtected from 'components/RoleProtected';

interface Props {
  show: boolean;
  deviceConfig: any;
}

const getDefaultSettings = (): ICurrentDeviceConfig => {
  const isMobile = mobileAndTabletCheck();
  return {
    deviceName: '',
    defaultPrinter: -1,
    defaultItemLabelPrinter: -1,
    defaultLocationLabelPrinter: -1,
    defaultDeliveryLabelPrinter: -1,

    allowSelectingText: !isMobile,

    useEmbeddedBarcodeScanner: false,
    useVirtualKeyboard: isMobile,

    TTSVoice: null as string,
    TTSRate: 1.0 as number,

    goFullscreenOnFirstInteraction: false
  };
};

const setManifest = (displayMode: 'fullscreen' | 'standalone') => {
  document.querySelector('#manifestFileLink').setAttribute('href', `${process.env.PUBLIC_URL}/manifest-${displayMode}.json`);
  addNotification('success', <Translation name="T.misc.success"/>)
}

export default ({ show, deviceConfig }: Props) => {
  const [possiblePrinters, setPossiblePrinters] = useState<Array<IPrinterConfig>>([]);
  const [state, setState] = useState<ICurrentDeviceConfig>({} as ICurrentDeviceConfig)
  const notifications = useNotifications();
  const T = useTranslations();
  const ctxDeviceConfig = useDeviceConfig();
  const api = useApi();

  useEffect(() => {
    if (!show) return;
    (async () => {
      const printers = await api.printers.getAvailablePrinters();
      setPossiblePrinters(printers);
      if (deviceConfig) {
        setState(cloneDeep(deviceConfig));
      } else {
        setState(getDefaultSettings());
      }
    })();
  }, [show]); // eslint-disable-line react-hooks/exhaustive-deps

  const saveSettings = () => ctxDeviceConfig.saveDeviceConfiguration(cloneDeep(state));

  const testPrinter = async (printerId: number) => {
    const printer = possiblePrinters.find((i) => i.id === printerId);
    if (!printer || printer.id === -1) {
      return notifications.add('danger', <Translation name="T.settings.configurePrinter" />);
    }
    await api.printers.testPrinter(printer);
    notifications.add('success', <Translation name="T.settings.printerTestSuccess" />);
  };

  return (
    <SimpleModal
      open={show}
      title={<Translation name="T.settings.deviceConfigTitle" />}
      fullscreen
      disableCloseTriggers
      buttons={[
        <Button onClick={saveSettings} color={'primary'} data-testid={'device-configuration-save-settings'} key="save">
          <Translation name="T.misc.save" />
        </Button>,
      ]}
    >
      <UncontrolledTabs>
        <Tab 
          dataTestId='general_settings' 
          name={<Translation name="T.misc.general" />}
        >
          <FormState state={state} setState={setState}>
            <Container fluid>
              <Labeled title={<Translation name="T.settings.deviceName" />}>
                <FormInput path="deviceName"/>
              </Labeled>

              <Labeled title={<Translation name="T.settings.defaultPrinter" />}>
                <FormSelectButton
                  path="defaultPrinter"                  
                  options={[
                    { value: -1, label: T('T.settings.noDefaultPrinter') },
                    ...possiblePrinters.map((item) => ({
                      value: item.id,
                      label: item.name,
                    })),
                  ]}
                >
                  <ProcessingButton onClick={() => testPrinter(state.defaultPrinter)}>
                    <Translation name="T.settings.testPrinter" />
                  </ProcessingButton>
                </FormSelectButton>
              </Labeled>

              <Labeled title={<Translation name="T.settings.defaultItemLabelPrinter" />}>
                <FormSelectButton
                  path="defaultItemLabelPrinter"                  
                  options={[
                    { value: -1, label: T('T.settings.noDefaultPrinter') },
                    ...possiblePrinters.map((item) => ({
                      value: item.id,
                      label: item.name,
                    })),
                  ]}
                >
                  <ProcessingButton onClick={() => testPrinter(state.defaultItemLabelPrinter)}>
                    <Translation name="T.settings.testPrinter" />
                  </ProcessingButton>
                </FormSelectButton>
              </Labeled>

              <Labeled title={<Translation name="T.settings.defaultLocationLabelPrinter" />}>
                <FormSelectButton
                  path="defaultLocationLabelPrinter"                  
                  options={[
                    { value: -1, label: T('T.settings.noDefaultPrinter') },
                    ...possiblePrinters.map((item) => ({
                      value: item.id,
                      label: item.name,
                    })),
                  ]}
                >
                  <ProcessingButton onClick={() => testPrinter(state.defaultLocationLabelPrinter)}>
                    <Translation name="T.settings.testPrinter" />
                  </ProcessingButton>
                </FormSelectButton>
              </Labeled>


              <Labeled title={<Translation name="T.settings.defaultDeliveryLabelPrinter" />}>
                <FormSelectButton
                  path="defaultDeliveryLabelPrinter"                  
                  options={[
                    { value: -1, label: T('T.settings.noDefaultPrinter') },
                    ...possiblePrinters.map((item) => ({
                      value: item.id,
                      label: item.name,
                    })),
                  ]}
                >
                  <ProcessingButton onClick={() => testPrinter(state.defaultDeliveryLabelPrinter)}>
                    <Translation name="T.settings.testPrinter" />
                  </ProcessingButton>
                </FormSelectButton>
              </Labeled>

              <hr/>

              <Labeled title={<Translation name="T.misc.ttsvoice" />}>
                <FormSelectButton
                  path="TTSVoice"
                  options={getVoices().map((voice) => {
                    const voiceId = getVoiceId(voice);
                    let voiceName = voiceId;
                    if (voice.default) {
                      voiceName += ' -- DEFAULT';
                    }
                    if (voice.localService) {
                      voiceName += ' -- OFFLINE';
                    }
                    return {
                      value: voiceId,
                      label: voiceName,
                    };
                  })}
                >
                  <ProcessingButton onClick={() => {
                    tts(getVoiceForId(state.TTSVoice), 'Hello world', state.TTSRate);
                  }}>
                    <Translation name="T.misc.test" />
                  </ProcessingButton>
                </FormSelectButton>
              </Labeled>

              <Labeled title={<Translation name="T.settings.tts.rate" params={{ rate: state.TTSRate }} />}>
                <FormRange
                  typed={FloatType}
                  path="TTSRate"
                  min={0.1}
                  max={10}
                  step={0.1}
                />
              </Labeled>

              <RoleProtected roles={['authEditProtectedDeviceSettings']}>
                <hr/>
                
                <Labeled title={<Translation name="T.settings.useEmbeddedBarcodeScanner" />}>
                  <FormToggle path="useEmbeddedBarcodeScanner"/>
                </Labeled>

                <Labeled title={<Translation name="T.settings.useVirtualKeyboard" />}>
                  <FormToggle path="useVirtualKeyboard"/>
                </Labeled>

                <Labeled title={<Translation name="T.settings.allowSelectingText" />}>
                  <FormToggle path="allowSelectingText"/>
                </Labeled>

                <Labeled title={<Translation name="T.settings.goFullscreenOnFirstInteraction" />}>
                  <FormToggle path="goFullscreenOnFirstInteraction"/>
                </Labeled>
              </RoleProtected>
            </Container>
          </FormState>
        </Tab>
        <Tab name={<Translation name="T.misc.actions"/>}>
          <ProcessingButton block onClick={() => setManifest('fullscreen')}>
            <Translation name="T.settings.setFullscreenPWA"/>
          </ProcessingButton>
          <ProcessingButton block onClick={() => setManifest('standalone')}>
            <Translation name="T.settings.setStandalonePWA"/>
          </ProcessingButton>
          <ButtonGroup className="w-100 mt-2">
            <ProcessingButton color='danger' onClick={async () => {
              await serviceWorker.unregisterAll()
            }}>
              <Translation name="T.settings.unregisterAll"/>
            </ProcessingButton>
            <ProcessingButton color="warning" onClick={async () => {
              await serviceWorker.unregister()
            }}>
              <Translation name="T.settings.unregister"/>
            </ProcessingButton>
            <ProcessingButton color='primary' onClick={async () => {
              await serviceWorker.register()
            }}>
              <Translation name="T.settings.register"/>
            </ProcessingButton>
            <ProcessingButton color="success" onClick={async () => {
              await serviceWorker.updateAll()
            }}>
              <Translation name="T.settings.update"/>
            </ProcessingButton>
          </ButtonGroup>
        </Tab>
        <Tab name={<Translation name="T.misc.permissions"/>}>
          <Table striped>
            <tbody>
              <tr>
                <td>
                <Translation name="T.permissions.notificationsEnabled"/>
                </td>
                <td>
                  <Bool type='YN' val={areNotificationsEnabled()} />
                </td>
              </tr>
              <tr>
                <td>
                  <Translation name="T.permissions.btFlagSet"/>
                  <FaQuestionCircle id='btFlagExplanation'/>
                  <UncontrolledTooltip target='btFlagExplanation' autohide={false}>
                    chrome://flags/#enable-web-bluetooth-new-permissions-backend
                  </UncontrolledTooltip>
                </td>
                <td>
                  <Bool type='YN' val={navigator.bluetooth && navigator.bluetooth.getDevices} />
                </td>
              </tr>
            </tbody>
          </Table>
        </Tab>
      </UncontrolledTabs>
    </SimpleModal>
  );
};
