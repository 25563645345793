import { FC } from 'react';
import { IKardexSettings } from 'interfaces';
import { IRouteDefinition } from 'statics/route_data';
import { OrderImporterAutoFixer } from 'routes/importers/OrderImporter';

export interface SpecializationPoints {
  identifier: string;
  routesCustomizer?: (routes: IRouteDefinition[]) => IRouteDefinition[];

  customSettingsConfigurator?: FC<{
    state: any;
    setState: React.Dispatch<React.SetStateAction<any>>
  }>

  orderImporter?: {
    orderImporterAutoFixers?: () => OrderImporterAutoFixer[]
  }
  
  kardexSettings?: FC<{
    kardexSettings: IKardexSettings;
    setKardexSettings: React.Dispatch<React.SetStateAction<IKardexSettings>>;
  }>;
}

export const CostumerIdMappings: { [k: string]: () => Promise<any> } = {
  lankhorst_taselaar: () => import(/* webpackChunkName: "specialization/lankhorst_taselaar/index" */ 'specialization/lankhorst_taselaar'),
  HCM: () => import(/* webpackChunkName: "specialization/hcm/index" */ 'specialization/hcm'),
  ILS: () => import(/* webpackChunkName: "specialization/ils/index" */ 'specialization/ils'),
};
