import React from 'react'
import SimpleModal from "components/SimpleModal";
import { useState } from "react";
import {  useApi } from "utils/API";
import Translation from "utils/Language/Translation";
import { FormGroup, Label } from 'reactstrap';
import ProcessingButton from 'components/ProcessingButton';

interface ISetup2FAProps {
  userId: number;
}
const Setup2FA = ({ userId }: ISetup2FAProps) => {
  const api = useApi()
  const [image, setImage] = useState<string>(null)
  return (
    <SimpleModal
      title={<Translation name="T.settings.setup2FA" />}
      fullscreen={false}
      trigger={(open) => (
        <FormGroup>
          <Label>
            <Translation name="T.settings.setup2FA" />
          </Label>
          <ProcessingButton onClick={async () => {
            const code = await api.users.get2FABarcode(userId, 'view')
            setImage(code['qr'])
            open()
          }} block>
            <Translation name="T.settings.setup2FA" />
          </ProcessingButton>
        </FormGroup>
      )}
      buttons={(close) => [
        <ProcessingButton
          color="primary"
          onClick={async () => {
            const code = await api.users.get2FABarcode(userId, 'reset')
            setImage(code['qr'])
          }}
          data-testid={'simple-modal-primary'}
        >
          <Translation name="T.misc.regenerate" />
        </ProcessingButton>,
        <ProcessingButton color="secondary" onClick={close} data-testid={'simple-modal-secondary'}>
          <Translation name="T.misc.cancel" />
        </ProcessingButton>,
      ]}
    >
      <div className='w-100 h-100 text-center'>
        <img alt='2fa barcode' src={image}/>
      </div>
    </SimpleModal>
  );
};

export default Setup2FA;