import {IExactOnlineConfiguration} from './erp-exactOnline';
import {ISapConfiguration} from './erp-sap';

export interface IErp {
  exactOnlineConfiguration: IExactOnlineConfiguration;
  sapConfiguration: ISapConfiguration;
  type: Type;
}

export type Type = 'NONE' | 'SAP' | 'EXACT_ONLINE';
export const TypeValues: Type[] = ['NONE' , 'SAP' , 'EXACT_ONLINE'];

