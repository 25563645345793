import {
  IWorkCenter,
  IWorkOrder,
  IProductionWorkTime,
  IWorkOrderLine,
  IWorkOrderStep,
  IIProductionDao$ISaveTimeWorkedFlushingError,
  IBinLocation,
  IProductionOrderConfirmation$IProductionConfirmationLine,
  IItem,
  IProductionOrderMaterialRegistration,
} from 'interfaces';
import { Registry } from '.';

export type IWorkOrderStepMap = { [k: string]: IWorkOrderStep[] };

export default ({ get, post, destroy }: Registry) => {
  return {
    production: {
      getWorkOrders: (): Promise<IWorkOrder[]> => get(`/api/v1/production/work_orders`),
      getWorkOrderLines: (code: string): Promise<IWorkOrderLine[]> =>
        get(`/api/v1/production/work_orders/${code}/lines`),
      getWorkOrderSteps: (code: string): Promise<IWorkOrderStep[]> =>
        get(`/api/v1/production/work_orders/${code}/steps`),
      getWorkOrderStepsForMultipleAsMap: (codes: string[]): Promise<IWorkOrderStepMap> =>
        post(`/api/v1/production/work_orders/steps`, codes),
      getWorkCenters: (): Promise<IWorkCenter[]> => get(`/api/v1/production/work_centers`),

      getWorkTimes: (): Promise<IProductionWorkTime[]> => get('/api/v1/production/work_confirmation'),
      getAllWorkTimes: (): Promise<IProductionWorkTime[]> => get('/api/v1/production/work_confirmation/all'),
      saveWorkTimes: (entry: IProductionWorkTime): Promise<void> => post('/api/v1/production/work_confirmation', entry),
      deleteWorkTimes: (id: number): Promise<void> => destroy(`/api/v1/production/work_confirmation/${id}`),

      materialRegistration: (code: string, registration: IProductionOrderMaterialRegistration): Promise<void> =>
        post(`/api/v1/production/work_orders/${code}/materials`, registration),
      flushWorkTimes: (ids: number[]): Promise<IIProductionDao$ISaveTimeWorkedFlushingError[]> =>
        post('/api/v1/production/work_confirmation/flush', ids),
      confirmWorkOrder: (
        code: string,
        producedItem: IItem,
        targetLocation: IBinLocation,
        lines: IProductionOrderConfirmation$IProductionConfirmationLine[],
      ): Promise<void> =>
        post(`/api/v1/production/work_orders/${code}/confirm`, {
          targetLocation,
          producedItem,
          lines,
        }),

      getSettingsForMaterialConfirmation: (order: IWorkOrder, step: IWorkOrderStep): Promise<any> =>
        post(`/api/v1/production/settings/materialConfirmationConfiguration`, { order, step }),  

      getSettingsForProductConfirmation: (order: IWorkOrder): Promise<any> =>
        post(`/api/v1/production/settings/productionConfirmationConfiguration`, order),  

      admin: {
        getWorkTimes: (): Promise<IProductionWorkTime[]> => get('/api/v1/production/work_confirmation/all'),
      },
    },
  };
};
