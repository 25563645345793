import {
  IWallToWallResult,
  IBinLocation,
  ICountingListHeader,
  IWtwBatchCountingDocument,
  IWtwBatchLocation,
  ICreateCountingListDto,
  IProcessSingleCountDto,
  ICountingProcessingData,
} from 'interfaces';
import { Registry } from '.';

export default ({ get, post, destroy }: Registry) => {
  return {
    getWallToWallRequests: (): Promise<Array<IBinLocation>> => get(`/api/v1/wall_to_wall/requests`),
    saveWallToWallResult: (result: IProcessSingleCountDto, allowAddingNewItems = true): Promise<void> => post(`/api/v1/wall_to_wall/save`, result, { allowAddingNewItems }),

    wallToWallBatch: {
      createCountingLists: (data: ICreateCountingListDto): Promise<void> =>
        post('/api/v1/wall_to_wall/batching/countingList', data),
      getCountingLists: (): Promise<ICountingListHeader[]> => get('/api/v1/wall_to_wall/batching/countingList'),
      getLocationsForCountingLists: (code: string): Promise<IBinLocation[]> =>
        get(`/api/v1/wall_to_wall/batching/countingList/${code}`),

      startCounting: (code: string): Promise<IWtwBatchCountingDocument> =>
        post(`/api/v1/wall_to_wall/batching/startCounting`, { listcode: code }),
      getProgress: (): Promise<IWtwBatchCountingDocument> => get('/api/v1/wall_to_wall/batching/progress'),
      saveWallToWallBatchResult: (result: IWallToWallResult): Promise<IWtwBatchLocation> =>
        post(`/api/v1/wall_to_wall/batching/save`, result),
      deleteWallToWallBatchLocation: (binCode: string): Promise<void> =>
        destroy(`/api/v1/wall_to_wall/batching/${binCode}`),
      flushWallToWallBatch: (processingData: ICountingProcessingData): Promise<void> => post(`/api/v1/wall_to_wall/batching/flush`, processingData),
    },
  };
};
