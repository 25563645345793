import { IRecountingDocument, IRecountingRequestHeader, IRecountingRow } from 'interfaces';
import { Registry } from '.';

export default ({ get, post, destroy }: Registry) => {
  return {
    recounting: {
      getRequests: (): Promise<IRecountingRequestHeader[]> => get(`/api/v1/recounting/requests`),

      claimDocument: (id: string): Promise<void> => post(`/api/v1/recounting/claim/${id}`, null),
      getDocument: (): Promise<IRecountingDocument> => get(`/api/v1/recounting/document`),
      saveRow: (row: IRecountingRow): Promise<void> => post(`/api/v1/recounting/save`, row),
      flush: (): Promise<void> => post(`/api/v1/recounting/flush`, null),
    },
  };
};
