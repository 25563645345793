import { expandUserRoles, itemFilter, run, useEffectAsync } from 'component_utils/utils';
import { Role } from 'interfaces';
import React, { FC, useState } from 'react';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, Col, Container, CustomInput, Row } from 'reactstrap';
import { useRawMenuStructure, isApp, IAppGroup, IChild, generalAuthenticationToggles } from 'statics/route_data';
import { useApi } from 'utils/API';
import { useConfig } from 'utils/Config';
import Translation from 'utils/Language/Translation';
import { RawRoleHierarchy } from 'utils/endpoints/System';

const RoleEditor: FC<{
  roles: Role[],
  setRoles: React.Dispatch<React.SetStateAction<Role[]>>
}> = ({ roles, setRoles }) => {
  const api = useApi();
  const config = useConfig();
  const [hierarchy, setHierarchy] = useState<RawRoleHierarchy>(null)
  const menu = useRawMenuStructure();
  const expandedRoles = expandUserRoles(roles, config)


  useEffectAsync(async () => {
    setHierarchy(await api.roles.getRawHierarchy())
  }, [setHierarchy, api])

  if (!hierarchy) {
    return <div className="nested-flex-container text-center"></div>
  }

  const renderMenu = (it: IChild, parents: IAppGroup[] = []): any => {
    const pathId = parents.map(it => it.id).concat([it.id]).join("-")
    if (isApp(it)) {
      return (
        <Col xs={4} className='mb-3' key={pathId}>
          <Card className='text-left'>
            <CardHeader>
              <CustomInput
                id={pathId+"-appRole"}
                label={<Translation name={it.title} />} 
                type='switch'
                disabled={!it.condition.appRole}
                checked={!it.condition.appRole || expandedRoles.includes(it.condition.appRole)}
                onChange={e => setRoles(old => {
                  if (e.target.checked) {
                    return [...old, it.condition.appRole]
                  } else {
                    return old.filter(itemFilter([it.condition.appRole]))
                  }
                })}
              />
            </CardHeader>
            <CardBody>
              {it.condition.appAuthorizations?.map((auth, index) => (
                <CustomInput
                  key={auth}
                  id={pathId+"-auth-"+index}
                  label={<Translation name={run(() => {
                    let s = auth as string
                    if (it.condition.appRole) {
                      s = s.replace(it.condition.appRole, "")
                    }
                    return s.replace(/([A-Z])/g, ' $1').trim()
                  })} />} 
                  type='switch'
                  checked={expandedRoles.includes(auth)}
                  onChange={e => setRoles(old => {
                    if (e.target.checked) {
                      return [...old, auth]
                    } else {
                      return old.filter(itemFilter([auth]))
                    }
                  })}
                />
              ))}
            </CardBody>
          </Card>
        </Col>
      )
    } else {
      // menu
      const enabledApps = it.children.filter(it => isApp(it))
      return (
        <React.Fragment key={pathId}>
          {enabledApps.length && (
            <>
              <Breadcrumb>
                <BreadcrumbItem>Apps</BreadcrumbItem>
                {parents.map((p, index) => (
                  <BreadcrumbItem key={index}>
                    <Translation name={p.title} />
                  </BreadcrumbItem>
                ))}
                <BreadcrumbItem active>
                  <Translation name={it.title} />
                </BreadcrumbItem>
              </Breadcrumb>
              <Container fluid>
                <Row>
                  {enabledApps.map(child => renderMenu(child))}
                </Row>
              </Container>
            </>
          )}
          {it.children.filter(it => !isApp(it)).map(child => renderMenu(child, [...parents, it]))}
        </React.Fragment>
      )
    }
  }

  return (
    <>
      <h1>
        <Translation name="T.misc.roles" />
      </h1>

      <div className="mt-3 nested-flex-container">
        <Breadcrumb>
          <BreadcrumbItem>General authentications</BreadcrumbItem>
        </Breadcrumb>
        <Container fluid className='mb-3'>
          <Row>
            {generalAuthenticationToggles.map(it => (
              <Col xs={4} key={it}>
                <CustomInput 
                  id={`is-${it}-toggle`}
                  label={it} 
                  type='switch' 
                  checked={expandedRoles.includes(it)}
                  onChange={e => setRoles(old => {
                    if (e.target.checked) {
                      return [...old, it]
                    } else {
                      return old.filter(itemFilter([it]))
                    }
                  })}
                />
              </Col>
            ))}
          </Row>
        </Container>

        <Breadcrumb>
          <BreadcrumbItem>Apps</BreadcrumbItem>
        </Breadcrumb>
        <Container fluid>
          <Row>
            {menu.filter(it => isApp(it)).map(child => renderMenu(child))}
          </Row>
        </Container>
        {menu.filter(it => !isApp(it)).map(child => renderMenu(child))}
      </div>
    </>
  );
}

export default RoleEditor