import {ICounting, IDelivery, IGoodsReceipt, IImporters, IPicking, IPicklistGenerator, IProduction, IStockTransfer} from './settings-group';

export interface IAppSettings {
  allowEmptyPassword: boolean;
  allowUpdatingErpUserConnectionsAfterCreate: boolean;
  allowUsersToChangePassword: boolean;
  counting: ICounting;
  delivery: IDelivery;
  enforcePickListOnlyForSelectedUser: boolean;
  errorItemCode: string;
  extraSettings: {[key: string] :Object};
  goodsReceipt: IGoodsReceipt;
  howManyDaysToRetainAnalyticsData: number;
  importers: IImporters;
  loginMethod: LoginMethod;
  maxNumberOfAutocompleteEntries: number;
  maxNumberOfDaysForHistoryForItemsAndLocations: number;
  notificationEmailAddress: string;
  numberOfHistoryRecordsForItemsAndLocation: number;
  picking: IPicking;
  picklistGenerator: IPicklistGenerator;
  production: IProduction;
  qualityControlApproach: QualityControlApproach;
  scannerItemIdentificationBatchAllowAutoSelectIfOnlyOneAllowed: boolean;
  scannerItemIdentificationBatchAllowAutoSelectIfOnlyOneOnLocation: boolean;
  scannerItemIdentificationLotAllowAutoSelectIfOnlyOneAllowed: boolean;
  scannerItemIdentificationLotAllowAutoSelectIfOnlyOneOnLocation: boolean;
  scannerItemIdentificationLotAlwaysEmpty: boolean;
  scannerItemIdentificationSerialAllowAutoSelectIfOnlyOneAllowed: boolean;
  scannerItemIdentificationSerialAllowAutoSelectIfOnlyOneOnLocation: boolean;
  scannerMinus: string;
  scannerOk: string;
  scannerOkValue: string;
  scannerParseItemBarcode: string;
  scannerPlus: string;
  scannerQtyAllowBatchSerialItemScanWithoutBatchCheck: boolean;
  scannerQtyMaxAtOnce: number;
  sessionDuration: number;
  sessionViewerRtcConfig: string;
  stockTransfers: IStockTransfer;
  systemOperationMode: SystemOperationMode;
  systemUserErpId: string;
  useUsernamePassword: boolean;
  volumeTable: IAppSettings$ICompanion$IVolumeTableEntry[];
  weightTable: IAppSettings$ICompanion$IWeightTableEntry[];
}

export interface IAppSettings$ICompanion$IVolumeTableEntry {
  name: string;
  toML: number;
}

export interface IAppSettings$ICompanion$IWeightTableEntry {
  name: string;
  toMG: number;
}

export type LoginMethod = 'NORMAL' | 'LDAP';
export const LoginMethodValues: LoginMethod[] = ['NORMAL' , 'LDAP'];

export type QualityControlApproach = 'PERFORM_VERIFY_ONE_STEP' | 'PERFORM_VERIFY_SEPARATE_STEP';
export const QualityControlApproachValues: QualityControlApproach[] = ['PERFORM_VERIFY_ONE_STEP' , 'PERFORM_VERIFY_SEPARATE_STEP'];

export type SystemOperationMode = 'NORMAL' | 'WALL_TO_WALL_BATCHING_MODE' | 'ALL';
export const SystemOperationModeValues: SystemOperationMode[] = ['NORMAL' , 'WALL_TO_WALL_BATCHING_MODE' , 'ALL'];

