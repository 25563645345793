import React, { FC } from 'react';
import Translation from 'utils/Language/Translation';
import { itemFilter } from 'component_utils/utils';
import { IApp, useFlatMenu } from 'statics/route_data';
import {useSortable} from '@dnd-kit/sortable';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import SimpleModal from 'components/SimpleModal';
import { Button, CustomInput } from 'reactstrap';


const App: FC<{ app: IApp }> = ({  app }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id:app.id});

  const style = {
    border: '1px dashed gray',
    padding: '0.5rem 1rem',
    marginBottom: '.5rem',
    backgroundColor: 'white',
    cursor: 'move',
    transform: CSS.Transform.toString(transform),
    touchAction: 'none',
    transition,
  };
  
  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <app.icon className="mr-2" />
      <Translation name={app.title} />
    </div>
  )
}

const PersonalMenuEditor: FC<{ state: string[], setState: (s: string[]) => any }> = ({
  state, setState
}) => {
  const menus = useFlatMenu()

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )
  
  return (
    <DndContext 
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={event => {
        console.log("end", event)
        const {active, over} = event;

        if (active.id !== over.id) {
          const oldIndex = state.indexOf(active.id as string);
          const newIndex = state.indexOf(over.id as string);
          const newArr = arrayMove(state, oldIndex, newIndex);
          setState(newArr)
        }
      }}
    >
      <SimpleModal
        title={<Translation name="T.user.settings.manageFavoriteApps"/>}
        trigger={open => <Button className='mb-2' block onClick={open}><Translation name="T.user.settings.manageFavoriteApps"/></Button>}
      >
        {menus.map(app => (
          <div key={app.id} style={{
            border: '1px dashed gray',
            padding: '0.5rem 1rem',
            marginBottom: '.5rem',
            backgroundColor: 'white',
          }}>
            <CustomInput type='switch' id={`toggle-${app.id}`} checked={state.includes(app.id)} label={(
              <>
                <app.icon className="mr-2" />
                <Translation name={app.title} />
              </>
            )} onChange={e => {
              if (e.target.checked) {
                setState([...state, app.id])
              } else {
                setState(state.filter(itemFilter([app.id])))
              }
            }}/>
          </div>
        ))}
      </SimpleModal>
      <div className='nested-flex-container' style={{ overflowX: 'hidden', overflowY: 'scroll' }}>
        <SortableContext 
          items={state}
          strategy={verticalListSortingStrategy}
        >
          {state.map((favorite) => menus.find(it => it.id === favorite) && <App key={'app-' + favorite} app={menus.find(it => it.id === favorite)} />)}
        </SortableContext>
      </div>

    </DndContext>
  )
}

export default PersonalMenuEditor
