import React, { FC, useRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import { InputGroup } from 'reactstrap';

const DateRangePicker: FC<{
  startDate: Date;
  endDate: Date;
  setStartDate: (d: Date) => any;
  setEndDate: (d: Date) => any;
  className?: string;
  id?: string;
}> = ({ className, id, startDate, endDate, setStartDate, setEndDate }) => {
  const startRef = useRef<ReactDatePicker>(null)
  const endRef = useRef<ReactDatePicker>(null)
  return (
    <InputGroup className={className}>
      <ReactDatePicker
        dateFormat="dd/MM/yyyy"
        ref={startRef}
        customInput={
          <MaskedInput
            data-testid="date-range-start"
            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            keepCharPositions
            guide
          />
        }
        selectsStart
        startDate={startDate}
        endDate={endDate}
        selected={startDate}
        id={id}
        onChange={(date) => {
          setStartDate(date as Date)
          startRef.current.setOpen(false)
          document.getElementById(id+"_end")?.focus()
        }}
        className="form-control input-left border-right-0"
        wrapperClassName="w-50"
      />
      <ReactDatePicker
        dateFormat="dd/MM/yyyy"
        ref={endRef}
        id={id+"_end"}
        customInput={
          <MaskedInput
            data-testid="date-range-end"
            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            keepCharPositions
            guide
          />
        }
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        selected={endDate}
        onChange={(date) => {
          setEndDate(date as Date)
          endRef.current.setOpen(false)
        }}
        className="form-control input-right"
        wrapperClassName="w-50"
      />
    </InputGroup>
  );
};

export default DateRangePicker;
