export type ColorTypes =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'success'
  | 'danger'
  | 'info'
  | 'light'
  | 'dark'
  | 'warning';

export const breakPoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const getLeftPaddingForLevel = (level: number) => `${15 * level}px`;
export const tableSuccessColor = "var(--be-table-success-color)";
export const tableErrorColor = "var(--be-table-error-color)";
export const tableHighlightColor = "var(--be-table-hightlight-color)";
export const tableHighlightColorSecondary = "var(--be-table-hightlight-color-secondary)";
