import { IStockStorageLocationLine } from 'interfaces';
import { Registry } from '.';

export default ({ get }: Registry) => {
  return {
    stockStorage: {
      getStockStorageLocationRows: (binCode: string): Promise<IStockStorageLocationLine[]> =>
        get(`/api/v1/stock_storage/locations/${binCode}/rows`),
    },
  };
};
