import React, { FC } from 'react';

const If: FC<{ cond: any }> = ({ cond, children }) => {
  if (cond) {
    return <>{children}</>;
  }
  return null;
};

export default If;
