import React from 'react'
import { PeripheralPrinter } from "utils/PeripheralManager"
import Translation from 'utils/Language/Translation'
import { JSRSASIGN, use_external_library_async } from 'component_utils/external_loader'
import qz, { PrintData } from "qz-tray";
import { choice } from 'utils/Prompts'
import { addNotification } from 'utils/NotificationManager';

declare global {
  interface Window {
    KEYUTIL: any;
    KJUR: any;
    stob64: any;
    hextorstr: any;
  }
}

const cert = `-----BEGIN CERTIFICATE-----
MIIDyTCCArGgAwIBAgIUa5MyiIZtQJtrLf9rtXHshpcgdq0wDQYJKoZIhvcNAQEL
BQAwczELMAkGA1UEBhMCTkwxEzARBgNVBAgMClNvbWUtU3RhdGUxFTATBgNVBAoM
DEJlLWVmZmljaWVudDESMBAGA1UEAwwJbG9jYWxob3N0MSQwIgYJKoZIhvcNAQkB
FhVrZXZpbkBiZS1lZmZpY2llbnQubmwwIBcNMjQwNTA2MTMxNzI3WhgPMjA1NTEw
MzAxMzE3MjdaMHMxCzAJBgNVBAYTAk5MMRMwEQYDVQQIDApTb21lLVN0YXRlMRUw
EwYDVQQKDAxCZS1lZmZpY2llbnQxEjAQBgNVBAMMCWxvY2FsaG9zdDEkMCIGCSqG
SIb3DQEJARYVa2V2aW5AYmUtZWZmaWNpZW50Lm5sMIIBIjANBgkqhkiG9w0BAQEF
AAOCAQ8AMIIBCgKCAQEApz0qEaPQTm3J0GQbDxhetxCiAdPGCgO61M8f4vKo76a3
pjPtOS3Q/uCc9JQWB5ct2SEHgRBrHdl7hH/4oCiwotq+DN2JOm3g0BF2ZLaCTPmB
pBBh5jL/ZKCO7P7jh7+XvXurFLkicMg5YiibnM1hF55fcdiTK35FkINHD5n6oZwT
eFL/I+f3d3eGuFNFugwSNgvmrv3IMzijJeV6Sq2r7UDq0PlY94zaNHGRN98mGt3H
Ds2wGCcue6wGUYn7KbWmbosuJrl0PSN/10Dm2cE8kpKjQM3P44sL9Ib5DIdpjM55
aQHzAVlbZqPXkr6aEbmAgnZ0YT3rXm7oWUrAVnIiDwIDAQABo1MwUTAdBgNVHQ4E
FgQUlmMtUjW4bMdzf5zZeXBww52LCmwwHwYDVR0jBBgwFoAUlmMtUjW4bMdzf5zZ
eXBww52LCmwwDwYDVR0TAQH/BAUwAwEB/zANBgkqhkiG9w0BAQsFAAOCAQEAY5MV
j8aqZSKyPZj3v9plm0rtTROkhPtVBR4o2moQOhILNZrDusF5bKP7jFMFLoNXYEBE
Bkf6691YbBCyc7KulkQzvtgdZaQkVXGjQxB8BhXmnHlno5ylZnXJgom+CRiNaeOz
nr0n6SNavTWo/Uk7Bqyqedubkp1u9NY3yPQEud7VcqYNXBnrHxINzsRC82m0NBEE
065C/MHk2RJe8baqjd2MUvlFL46dMnvDIl9CcyEzzF8ccoyqVq8IU8WxR4tCRkBy
lDYExrQR+5LpfdmqT9oLLHSBkteOe2bBsBo0E/XAclmKhXK2mqpV4dub6x8JBzDY
u4ZXkK/b0DB396HT1g==
-----END CERTIFICATE-----`

const key = `-----BEGIN PRIVATE KEY-----
MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCnPSoRo9BObcnQ
ZBsPGF63EKIB08YKA7rUzx/i8qjvpremM+05LdD+4Jz0lBYHly3ZIQeBEGsd2XuE
f/igKLCi2r4M3Yk6beDQEXZktoJM+YGkEGHmMv9koI7s/uOHv5e9e6sUuSJwyDli
KJuczWEXnl9x2JMrfkWQg0cPmfqhnBN4Uv8j5/d3d4a4U0W6DBI2C+au/cgzOKMl
5XpKravtQOrQ+Vj3jNo0cZE33yYa3ccOzbAYJy57rAZRifsptaZuiy4muXQ9I3/X
QObZwTySkqNAzc/jiwv0hvkMh2mMznlpAfMBWVtmo9eSvpoRuYCCdnRhPetebuhZ
SsBWciIPAgMBAAECggEASMU3mgdi682gBFqa352mERhetlbVFKR81n7uDA207trn
jJ2Jutix2UYZZd9OGq1w2MsjfciMI6fvtD8PC3nJKoPuMKDxRohrS35duwMjfaUQ
ATjTsJw+PyYSKxPaI8Z9m4jFb7YDQ3oMZibvV3KSrflO5LXti/5c/L3y3NoOkeUn
2Xcz6xcOm416DftsmH78hSmj2srcQG3ASkQigpbTW21t4WRbffz8mP7mXYp4+vpZ
RQ023NJX0y+TvILW04u6mRhu+TFSZpqNiGulP8VpUrKYuVamYG8CLM0NLdSECD5s
tBOEPiqY92ETCjZJZCnSAizIYQtMis9V8qzbBJ0lgQKBgQDoQzcj/YPn+9Pe6opc
0g4fIv4hvjZ6RyJTgCT37x518ZsBA09/Yir0jPcCFxqWB5onHXViWS8DhWLR9sR/
nNYjpOs9eZSLPSQpT6W5yESFuiWXNJbW3R7Y2hrpXGJrebNdj1gB0bHv+nWR1H0y
jRx+jTU41Frc59BbxSoscsC+QQKBgQC4VLUudrxzpNyC0GbdnR4kKO32mRVzHJu9
hJ9nVsxtG+6CIy1dkhxGP3vJJJ7WGgDFI+iaNm0EVnsgen3HjAY1us/wqWg0xsA4
Af+UM9Yrktz19vHTH34DgiHttfbjJ4rrR5YiwrNJQYVtZsVMrCWFWNAP8zqRqae+
bamCF7RsTwKBgQCKNk1ezmKrLrEyqtExURJ0NueRftZ+R0xG8i+Nlq7CssZxgN3S
yMEVC7mFdegtNVLnSeFrZ/TRHJPkq/rckaDgTyhpSas6fGTDbJELEACur0nlQ7cr
HyRXGNsA2Sf8GszAtSdC1d0UGC/UWuUxqsU1DaSJL9zuLchQg/CHyLWnwQKBgALf
JvdzWBpBHkJXdtLLCNYtYQ+vcNsbPf3k3ArjGko03jFe1O0ZQqGBia8nh+Xz/8ES
fyQv5uDjbAWkguMh9lLN90FbkkkIM6Rru4yWhialohWe5EOSwQbS9y5Z4F/HlO13
bwpZmCty/t4mdLpuECOUr6Ef+/CbAQcBdFuXfPMXAoGARyWa5RxCsjvd6jdSBgqx
ZsOc2gr9KmM1d1lCqHZJCbIsU6Lib8/YTcksEBcWkusJeNuEUxWK4njcdqz83Afy
7OjbtM9OPVAhfYGrorfiyR9OYrFuhYBfWeRrk/iyOah4relL9NeJehsqOYqcf8kZ
SBpf9+qSKj/mGjUaXAX7Yso=
-----END PRIVATE KEY-----`

async function connectToServer() {
  if (await qz.websocket.isActive()) {
    return qz
  }

  await use_external_library_async(JSRSASIGN)
  
  qz.security.setCertificatePromise((resolve, reject) => {
    resolve(cert);
  });

  qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
  qz.security.setSignaturePromise(function (toSign) {
    return function (resolve, reject) {
      try {
        var pk = window.KEYUTIL.getKey(key);
        var sig = new window.KJUR.crypto.Signature({ alg: "SHA512withRSA" });
        sig.init(pk);
        sig.updateString(toSign);
        var hex = sig.sign();
        resolve(window.stob64(window.hextorstr(hex)));
      } catch (err) {
        console.error(err);
        reject(err);
      }
    };
  });

  await qz.websocket.connect({ host: ["localhost"] })
  console.log("websocket connection", qz.websocket.getConnectionInfo());
  return qz
} 

const MaintenanceComponent = ({device}: {device: QZTray}) => {
  return (
    <>
      
    </>
  )
}

class QZTray implements PeripheralPrinter<QZTray> {
  printerName: string;

  getDriverName = () => 'QZTray'

  connect = async (data: any) => {
    await connectToServer()
    
    const printers = await qz.printers.find() as string[]
    const whatPrinterToUse = data?.name || await choice(
      <Translation name='T.peripherals.zqtray.whatPrinterToUse'/>,
      '',
      ['', ...printers]
    )

    if (!whatPrinterToUse) {
      throw addNotification('danger', <Translation name="T.peripherals.zqtray.errors.noPrinterSelected"/>)
    }

    this.printerName = whatPrinterToUse
    return { name: whatPrinterToUse }
  }

  disconnect = async () => {

  }

  isConnected = async () => {
    return qz.websocket.isActive()
  }

  print = async (mediatype: string, payload: string) => { 
    console.log("printing", mediatype, payload, this.printerName)
    if (mediatype === 'application/pdf') {
      const config = qz.configs.create(this.printerName);
      const data = [{ 
        type: 'pixel',
        format: 'pdf',
        flavor: 'base64',
        data: payload
      }] as PrintData[];
      qz.print(config, data).catch(function(e) { console.error(e); });
    } else if (mediatype === 'x-application/zpl') {
      const config = qz.configs.create(this.printerName);
      var data = [{
        type: 'raw',
        format: 'command',
        flavor: 'base64',
        data: payload
      }] as PrintData[];
      qz.print(config, data).catch(function(e) { console.error(e); });
    }
  }

  getMaintenanceComponent = () => MaintenanceComponent
}

export default QZTray