import {
  IPrinterConfig,
  ISettings,
  IPicklistGenerator$IAuto,
  ILdapTestRequest,
  IUpdateTranslations,
  ILicense,
  IIErpDescriptor$IErpTestResults,
  Role,
  ITestMail,
  ISystemScriptEntity,
  ITestScriptSource,
  ITabulaController$ICompanion$IPdfCoord,
  IRegisteredServiceInfo,
  IAnalyticsModelDefinition,
  IScriptingSecret,
  ITaskOverview,
  IScriptedTask,
  IIpFilterTestRequest,
  IpFilterRule,
  IGetContentsDto,
  IGetContentsResponseEntryDto,
  IMoveObjectDto,
  IGetFileDto,
  IGetFileResponseDto,
  IDeleteObjectDto,
  IUploadFileDto,
  ICreateDirectoryDto,
  IReportDefinition,
  INativeLibrarySettings,
  IBackupEntry,
  IDeploymentConfig,
  IUserFunctionDefinition,
} from 'interfaces';
import { Registry } from '.';
import { saveBase64UrlEncode } from 'component_utils/utils';

export type RawRoleHierarchy = Map<Role, Array<Role>>;

export type TabulaResults = {
  data: {
    text: string;
  }[][];
};

export default ({ get, post, destroy }: Registry) => {
  return {
    saveSystemConfiguration: (newSettings: any): Promise<void> => post('/api/v1/admin/save_system_config', newSettings),
    testErpConfiguration: (newSettings: any): Promise<IIErpDescriptor$IErpTestResults> =>
      post('/api/v1/admin/test_erp_connection', newSettings),
    autoFixErpErrors: (newSettings: any): Promise<void> => post('/api/v1/admin/autoFixErpTests', newSettings),
    getSystemConfiguration: (): Promise<ISettings> => get('/api/v1/admin/get_system_config'),

    directQuery: (q: string): Promise<any[]> => post('/api/v1/system/directQuery', { q }),

    notifications: {
      increase: (identifier: string): Promise<void> => post('/api/v1/notifications/increase', { identifier }),
      decrease: (identifier: string): Promise<void> => post('/api/v1/notifications/decrease', { identifier }),
    },

    fileManagement: {
      getContents: (payload: IGetContentsDto, withContents: boolean): Promise<IGetContentsResponseEntryDto[]> => post(`/api/v1/fileManagement/contents/${withContents}`, payload),
      createDirectory: (payload: ICreateDirectoryDto): Promise<ICreateDirectoryDto[]> => post('/api/v1/fileManagement/createDirectory', payload),
      uploadFile: (payload: IUploadFileDto): Promise<void> => post('/api/v1/fileManagement/uploadFile', payload),
      deleteObject: (payload: IDeleteObjectDto): Promise<void> => post('/api/v1/fileManagement/deleteObject', payload),
      getFile: (payload: IGetFileDto): Promise<IGetFileResponseDto> => post('/api/v1/fileManagement/getFile', payload),
      moveObject: (payload: IMoveObjectDto): Promise<void> => post('/api/v1/fileManagement/moveObject', payload),
      copyObject: (payload: IMoveObjectDto): Promise<void> => post('/api/v1/fileManagement/copyObject', payload),
    },

    deployment: {
      getBackups: (): Promise<IBackupEntry[]> => get('/api/v1/system_maintenance/get_backups'),
      deleteBackup: (id: string): Promise<IBackupEntry[]> => destroy(`/api/v1/system_maintenance/${saveBase64UrlEncode(id)}`),

      // these methods will take the application down
      installNewVersion: (app: File, config: IDeploymentConfig): Promise<void> => {
        const formData = new FormData();
        formData.append('file', app);
        formData.append('config', new Blob([JSON.stringify(config)], { type: 'application/json' }));
        return post('/api/v1/system_maintenance/install_new', formData, undefined, { expectNetworkError: true });
      },
      makeBackup: (config: IDeploymentConfig): Promise<void> => post('/api/v1/system_maintenance/make_backup', config, undefined, { expectNetworkError: true }),
      rollback: (id: string, config: IDeploymentConfig): Promise<IBackupEntry[]> => post(`/api/v1/system_maintenance/${saveBase64UrlEncode(id)}/rollback`, config, undefined, { expectNetworkError: true }),
    },

    license: {
      getLicense: (): Promise<ILicense> => get('/api/v1/license'),
      saveLicense: (license: File): Promise<void> => {
        const formData = new FormData();
        formData.append('file', license);
        return post('/api/v1/license', formData);
      },
    },

    certificates: {
      getCertificateState: () => get('/api/v1/certificates'),
      storeCertificate: (cert: File, config: any) => {
        const formData = new FormData();
        formData.append('cert', cert);
        formData.append('config', new Blob([JSON.stringify(config)], { type: 'application/json' }));
        return post('/api/v1/certificates', formData);
      },
    },

    serviceDiscovery: {
      getFoundServices: (): Promise<IRegisteredServiceInfo[]> => get('/api/v1/service_discovery/registered_services'),
      removeService: (id: string): Promise<any> => destroy(`/api/v1/service_discovery/${id}`)
    },

    analytics: {
      getModels: (): Promise<IAnalyticsModelDefinition[]> => get('/api/v1/analytics/models')
    },

    systemScripts: {
      getSystemScriptTypes: (): Promise<string[]> => get('/api/v1/system/script_types'),
      getSystemScripts: (): Promise<ISystemScriptEntity[]> => get('/api/v1/system/scripts'),
      saveSystemScript: (script: ISystemScriptEntity): Promise<void> => post('/api/v1/system/scripts', script),
      testSystemScript: (script: ITestScriptSource): Promise<{ output: string; results: any; }> =>
        post('/api/v1/system/scripts/test', script),

      secrets: {
        getSecrets: (): Promise<IScriptingSecret[]> => get('/api/v1/system/scripts/secrets'),
        saveSecrets: (secret: IScriptingSecret) => post('/api/v1/system/scripts/secrets', secret),
        deleteSecret: (id: number) => destroy(`/api/v1/system/scripts/secrets/${id}`)
      }
    },

    roles: {
      getRawHierarchy: (): Promise<RawRoleHierarchy> => get('/api/v1/config/rawHierarchy'),
    },

    email: {
      testEmail: (data: ITestMail): Promise<void> => post('/api/v1/admin/test_email', data),
    },

    nativeLibraries: {
      tesseractSetup: (data: INativeLibrarySettings): Promise<void> => post('/api/v1/admin/initialize_tesseract', data),
    },

    ldap: {
      testLdapLogin: (ldapTest: ILdapTestRequest): Promise<void> => post('/api/v1/auth/admin/test_ldap', ldapTest),
    },

    ip: {
      testIp: (data: IIpFilterTestRequest): Promise<{ rule: IpFilterRule }> => post('/api/v1/auth/test_ip', data),
    },

    language: {
      updateLanguage: (data: IUpdateTranslations): Promise<void> =>
        post('/api/v1/translations/translationManager/update', data),
    },

    system: {
      restart: () => post('/api/v1/admin/restartApplication', {}),

      testCron: (cron: string): Promise<{ next: any; nextXMoments: any[]; }> => post("/api/v1/system/test_cron", { expr: cron }),

      getAutoPicklistGeneratorSettings: (): Promise<IPicklistGenerator$IAuto> =>
        get('/api/v1/picklistGeneratorManager/picklist_generator_config'),
      saveAutoPicklistGeneratorSettings: (newSettings: IPicklistGenerator$IAuto): Promise<void> =>
        post('/api/v1/picklistGeneratorManager/picklist_generator_config', newSettings),
    },

    tasks: {
      scripted: {
        getAll: (): Promise<IScriptedTask[]> => get('/api/v1/tasks/scripted'),
        save: (script: IScriptedTask): Promise<any> => post('/api/v1/tasks/scripted', script),
        delete: (id: number): Promise<any> => destroy(`/api/v1/tasks/scripted/${id}`),
      },
      getTasks: (): Promise<ITaskOverview[]> => get('/api/v1/tasks/'),
      startTask: (taskId: number): Promise<void> => post(`/api/v1/tasks/${taskId}/start`, {}),
      setTaskConfig: (taskId: number, config: any): Promise<void> => post(`/api/v1/tasks/${taskId}/config`, config),
    },

    reports: {
      getAll: (): Promise<{
        report: IReportDefinition,
        nextRun: any
      }[]> => get("/api/v1/reporting/"),
      save: (def: IReportDefinition): Promise<IReportDefinition> => post("/api/v1/reporting/", def),
      delete: (id: number): Promise<void> => destroy(`/api/v1/reporting/${id}`),

      run: (id: number, params: {[key: string] :string}): Promise<void> => post(`/api/v1/reporting/${id}/run`, params),
      download: (id: number, params: {[key: string] :string}): Promise<string> => post(`/api/v1/reporting/${id}/download`, params),
    },

    userFunctions: {
      getAll: (): Promise<{
        userFunction: IUserFunctionDefinition,
        nextRun: any
      }[]> => get("/api/v1/user_functions/"),
      save: (def: IUserFunctionDefinition): Promise<IUserFunctionDefinition> => post("/api/v1/user_functions/", def),
      delete: (id: number): Promise<void> => destroy(`/api/v1/user_functions/${id}`),

      run: (id: number, params: {[key: string] :string}): Promise<{ taskId: number; }> => post(`/api/v1/user_functions/${id}/run`, params),
    },


    tabula: {
      getTables: (pdf: File, coords: ITabulaController$ICompanion$IPdfCoord[]): Promise<TabulaResults[]> => {
        const formData = new FormData();
        formData.append('file', pdf);
        formData.append('coords', new Blob([JSON.stringify(coords)], { type: 'application/json' }));
        return post('/api/v1/tabula/tables', formData);
      },
    },

    printers: {
      getPrinter: (id: number): Promise<IPrinterConfig> => get(`/api/v1/printer/${id}`),
      getAvailablePrinters: (): Promise<Array<IPrinterConfig>> => get('/api/v1/printer/list'),
      testPrinter: (printerConfig: IPrinterConfig): Promise<void> => post('/api/v1/printer/test', printerConfig),
      savePrinter: (printerConfig: IPrinterConfig): Promise<void> => post(`/api/v1/printer/admin/save`, printerConfig),
      deletePrinter: (id: number): Promise<void> => destroy(`/api/v1/printer/admin/${id}`),
    
      getNativePrinters: (): Promise<{
        name: string,
        supportedDocs: {
          fullName: string,
          descriptor: {
            mediaType: string,
            mimeType: string,
            mediaSubtype: string,
          },
          mediaType: string,
          mimeType: string
        }[]
      }[]> => get(`/api/v1/printer/native/list`),

      printFiles: async (printer: number, files: File[]) => {
        const formData = new FormData();
        files.forEach(file => formData.append("files", file))
        await post(`/api/v1/printer/${printer}/batch_print`, formData, null)
      }
    },
  };
};
