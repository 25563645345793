import React from 'react'
import { InputPeripheral } from "utils/PeripheralManager"
import ProcessingButton from 'components/ProcessingButton'
import Translation from 'utils/Language/Translation'
import { addNotification } from 'utils/NotificationManager'
import createDeferred from 'component_utils/Deferred'
import { run } from 'component_utils/utils'

declare global {
  interface BluetoothDevice {
    watchAdvertisements(args: { signal: AbortSignal }): Promise<void>
    addEventListener(name: "advertisementreceived", listener: (ev: BluetoothAdvertisementEvent) => any, options: { once: boolean }): void
  }
}

const READ_VALUE = run(() => {
  const instruction = 'S'
  const hex = Buffer.from(instruction).toString('hex')
  const prefix = ("00" + (instruction.length + 2).toString(16)).slice(-2)
  const msg = prefix + hex + "0d0a"
  return msg
})

function hexStringToByteArray(hexString: string) {
  if (hexString.length % 2 !== 0) {
      throw new Error("Must have an even number of hex digits to convert to bytes");
  }
  var numBytes = hexString.length / 2;
  var byteArray = new Uint8Array(numBytes);
  for (var i=0; i<numBytes; i++) {
      byteArray[i] = parseInt(hexString.substr(i*2, 2), 16);
  }
  return byteArray;
}

const notifyCharacteristicUuid: any = 'f000a031-0451-4000-b000-000000000000';
const writeCharacteristicUuid: any = 'f000a032-0451-4000-b000-000000000000';

const MaintenanceComponent = ({device}: {device: StimagScale}) => {
  return (
    <>
      <ProcessingButton onClick={async () => {
        const v = await device.getValue()
        alert(v)
      }}>
        <Translation name="T.misc.test"/>
      </ProcessingButton>
    </>
  )
}

class StimagScale implements InputPeripheral<StimagScale> {
  device: BluetoothDevice
  serviceUuid: any = 'f000a00f-0451-4000-b000-000000000000';

  setConfig(config: any) {
    this.serviceUuid = config.serviceUuid
  }

  getDriverName = () => 'STIMAG'

  connect = async (data: any) => {
    if (data && navigator.bluetooth.getDevices) {
      // https://googlechrome.github.io/samples/web-bluetooth/watch-advertisements-and-connect-async-await.html
      this.device = (await navigator.bluetooth.getDevices()).find(it => it.id === data.id)

      const abortController = new AbortController();
      const advertisementScanner = new Promise((resolve, reject) => {
        const onAdvertisement = () => {
          // Stop watching advertisements to conserve battery life.
          abortController.abort();
          this.device.removeEventListener('advertisementreceived', onAdvertisement)
          clearTimeout(timeout)
          resolve(null)
        }

        const timeout = setTimeout(() => {
          // Stop watching advertisements to conserve battery life.
          abortController.abort();
          this.device.removeEventListener('advertisementreceived', onAdvertisement)
          addNotification('danger', <Translation name="T.errors.peripherals.couldNotConnectToDevice"/>)
          reject()
        }, 5000)
        
        this.device.addEventListener('advertisementreceived', onAdvertisement);
      })

      await this.device.watchAdvertisements({ signal: abortController.signal });
      await advertisementScanner;
      await this.device.gatt.connect()
      console.log("found device with id: ", data.id, this.device)
    } else {
      this.device = await navigator.bluetooth.requestDevice({
        filters: [
          { services: [this.serviceUuid] },
        ],
      });

      await this.device.gatt.connect()
      return { id: this.device.id, name: this.device.name }  
    }
  }

  disconnect = async () => {
    this.device?.gatt.disconnect()
    this.device = null
  }

  isConnected = async () => {
    return !!this.device
  }

  getValue = async () => { 
    if (!this.device) {
      throw addNotification('danger', 'NOT CONNECTED')
    }
    
    const deferred = createDeferred<string>()
    const service = await this.device.gatt.getPrimaryService(this.serviceUuid);
    const notifyCharacteristic = await service.getCharacteristic(notifyCharacteristicUuid);
    await notifyCharacteristic.startNotifications();

    const listener = (event: any) => {
      const text = new TextDecoder().decode(event.target.value)
      const parts = text.trim().split(/\s+/)
      let weightPart = parseFloat(parts[2])
      const unit = parts[3]
      if (unit.trim().toLowerCase() === "g") {
        weightPart = weightPart / 1000.0
      }
      deferred.resolve(`${weightPart} kg`)
      notifyCharacteristic.removeEventListener('characteristicvaluechanged', listener);
    }
    notifyCharacteristic.addEventListener('characteristicvaluechanged', listener);

    const writeCharacteristic = await service.getCharacteristic(writeCharacteristicUuid);

    const characteristic = writeCharacteristic as BluetoothRemoteGATTCharacteristic
    
    await characteristic.writeValue(hexStringToByteArray(READ_VALUE));

    return await deferred.promise
  }

  getMaintenanceComponent = () => MaintenanceComponent
}

export default StimagScale