const timed = <T extends Array<any>, U>(label: string, fn: (...args: T) => U) => {
  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    return (...args: T): U => {
      console.time(label);
      const res = fn(...args);
      console.timeEnd(label);
      return res;
    };
  }
  return (...args: T): U => fn(...args);
};

export default timed;
