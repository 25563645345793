import {
  IItemBinLocation,
  IBinLocation,
  IBinLocationAttributes,
  PhysicalLocationType,
  LocationType,
  IBinLocationContentSummary,
  IAutocompleteEntry,
  IBinLocationPrintJob,
  ISetCountingInstruction,
  IItem,
} from 'interfaces';
import { Registry } from '.';
import { saveBase64UrlEncode } from 'component_utils/utils';
import { IItemMovementHistoryEntry } from 'interfaces';

export default ({ get, post }: Registry) => {
  return {
    binLocation: {
      findBinLocation: (code: string): Promise<IBinLocation> => get(`/api/v1/bin_location/find/${saveBase64UrlEncode(code)}`),
      findMultipleBinLocations: (codes: string[]): Promise<IBinLocation[]> => post(`/api/v1/bin_location/find`, codes),

      findAutocomplete: (code: string): Promise<IAutocompleteEntry[]> =>
        get(`/api/v1/bin_location/autocomplete/${saveBase64UrlEncode(code)}`),

      getBinLocation: (code: string): Promise<IBinLocation> => get(`/api/v1/bin_location/${code}`),
      getMultipleBinLocations: (codes: string[]): Promise<IBinLocation[]> => post(`/api/v1/bin_location/get`, codes),
      getBinLocationAttributes: (code: string): Promise<IBinLocationAttributes> =>
        get(`/api/v1/bin_location/${code}/attributes`),
      getBinLocationContents: (code: string): Promise<Array<IItemBinLocation>> =>
        get(`/api/v1/bin_location/${code}/contents`),
      getHistory: (code: string): Promise<IItemMovementHistoryEntry[]> => get(`/api/v1/bin_location/${code}/history`),
      getBinLocationsByPhysicalType: (type: PhysicalLocationType): Promise<Array<IBinLocation>> =>
        get(`/api/v1/bin_location/physicalLocationType/${type}`),
      getBinLocationContentSummaryByLocationType: (type: LocationType): Promise<IBinLocationContentSummary[]> =>
        get(`/api/v1/bin_location/locationType/${type}/contentSummary`),
      getBinLocationsByOrderCode: (orderCode: string): Promise<Array<IBinLocation>> =>
        get(`/api/v1/bin_location/preferredOrder/${orderCode}`),
      setPreferredOrderCode: (code: string, orderCode?: string): Promise<void> =>
        post(`/api/v1/bin_location/${code}/setPreferredOrder`, { orderCode }),
      setCountingState: (code: string, setCountingInstruction: ISetCountingInstruction): Promise<void> =>
        post(`/api/v1/bin_location/${code}/set_counting`, setCountingInstruction),
      getItemsToCount: (code: string): Promise<IItem[]> =>
        get(`/api/v1/bin_location/${code}/counting/items`),
      setReadyForProcessing: (code: string, value: boolean): Promise<void> =>
        post(`/api/v1/bin_location/${code}/readyForProcessing/${value.toString()}`, null),
      setStockMovedToKardex: (code: string, targetBinCode: string): Promise<void> => 
        post(`/api/v1/bin_location/${code}/setStockMovedToKardex`, { targetBinCode }),
      setBlockStockMovements: (code: string, block: boolean, reason: string): Promise<void> => 
        post(`/api/v1/bin_location/${code}/setBlockStockMovements/${block.toString()}`, { reason }),
      setBarcode: (code: string, barcode: string): Promise<void> => post(`/api/v1/bin_location/${code}/barcode`, { barcode }),
      setLocationType: (code: string, type: LocationType): Promise<void> => post(`/api/v1/bin_location/${code}/type`, { type }),
      setPhysicalLocationType: (code: string, type: PhysicalLocationType): Promise<void> => post(`/api/v1/bin_location/${code}/physical_type`, { type }),
      printLabel: (
        printerId: number,
        jobs: IBinLocationPrintJob,
      ): Promise<any> =>
        post(`/api/v1/bin_location/label/${printerId}`, jobs),
      printLargeLabel: (
        printerId: number,
        jobs: IBinLocationPrintJob,
      ): Promise<any> =>
        post(`/api/v1/bin_location/large_label/${printerId}`, jobs),
      },
  };
};
