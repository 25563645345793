import React, { useState, useEffect, useMemo, useCallback, ReactNode, useRef, FC, Suspense } from 'react';
import { Switch, Route, NavLink as RRNavLink, Redirect, useLocation, useHistory } from 'react-router-dom';
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavbarText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import classNames from 'classnames';
import { useFlatMenu, getRouteNamesFromFlatMenu, IApp, RouteNames } from 'statics/route_data';
import { Socket, useWebsocketIsConnected } from 'utils/Socket';
import { useApi, useApiLoader, useAuth, useDeviceConfig, useOpenRequests } from 'utils/API';
import { addNotification, useNotifications } from 'utils/NotificationManager';
import { distinct, getKeyboardEventAbstract, indexFilter, itemFilter, useLocalStorage, useWindowSize } from 'component_utils/utils';
import Menu from 'components/menu';
import './App.scss';
import logo from 'images/beelogo.png';
import Translation from 'utils/Language/Translation';
import TranslationEditorToggle from 'components/TranslationEditorToggle';
import WatermarkOverlay from 'utils/WatermarkOverlay';
import OnRuntimeType from 'components/OnRuntimeType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import ErrorHistoryOverview from 'components/ErrorHistoryOverview';
import { IAttachment, IWebSocketMessage } from 'interfaces';
import SimpleModal, { ModalRef } from 'components/SimpleModal';
import NetworkImage from './network.svg';
import TimerImage from './stopwatch.gif';
import { isEqual } from 'lodash';
import bellSfx from 'audio/notification.mp3';
import Raw from 'components/Raw';
import { openPeripheralManager } from 'utils/PeripheralManager';
import { alert, confirm } from 'utils/Prompts';
import PictureBox, { PictureBoxRef } from 'components/PictureBox';
import SessionViewerListener from 'routes/tools/EmployeeManagement/SessionViewerListener';
import { sessionID } from 'statics/session_identification';
import { FaQrcode } from 'react-icons/fa';
import QRCode from 'react-qr-code';
import { MdCloseFullscreen, MdError, MdOutlineAppSettingsAlt, MdPermMedia } from 'react-icons/md';
import { IoReload } from 'react-icons/io5';
import { BiSolidLogOut } from 'react-icons/bi';
import { BsArrowsFullscreen, BsUsbPlugFill } from 'react-icons/bs';
import { RiUserSettingsFill } from 'react-icons/ri';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const isPWA = window.matchMedia('(display-mode: fullscreen)').matches ||
                     window.matchMedia('(display-mode: standalone)').matches

const findAppName = (currentPath: string, routeNames: RouteNames): [boolean, RouteNames[keyof RouteNames]] => {
  if (routeNames[currentPath]) {
    return [true, routeNames[currentPath]];
  }

  for (const key of Object.keys(routeNames)) {
    if (currentPath.startsWith(key)) {
      return [false, routeNames[key]];
    }
  }

  return null;
};

const DropDownMenuToggle = () => {
  const isConnected = useWebsocketIsConnected();
  return <FontAwesomeIcon icon={faUser} className={isConnected ? 'text-success' : 'text-danger'} />;
};

const NetworkState = () => {
  const openRequests = useOpenRequests();
  if (openRequests === 0) {
    return null;
  }
  return (
    <span>
      <img data-testid="network-request-indicator" data-test-num-of-requests={openRequests} alt="network indicator" className={classNames('react-icons')} src={NetworkImage} />
    </span>
  );
};

const TimerState = () => {
  const [hasRunningTimer, setHasRunningTimer] = useState(false);
  return (
    <>
      <Socket
        topics={['/topic/production/timer', '/user/queue/production/timer']}
        onMessage={(topic: any, msg: IWebSocketMessage<boolean>) => {
          if (msg.what === 'HAS_OPEN_TIMER') {
            setHasRunningTimer(msg.payload);
          }
        }}
      />
      {hasRunningTimer && (
        <span className="mr-1">
          <img alt="timer indicator" className={classNames('react-icons')} src={TimerImage} />
        </span>
      )}
    </>
  );
};

let hasTriggeredFullscreenAlready = false
const FirstInteractionFullScreenListener = (): any => {
  const fullscreenOnFirstInteraction = useDeviceConfig().deviceConfig.goFullscreenOnFirstInteraction
  useEffect(() => {
    const listener = () => {
      console.log("visibility changed")
      hasTriggeredFullscreenAlready = false
    }
    document.addEventListener('visibilitychange', listener)
    return () => {
      document.removeEventListener('visibilitychange', listener)
    }
  }, [])
  useEffect(() => {
    if (fullscreenOnFirstInteraction) {
      const listener = () => {
        if (!hasTriggeredFullscreenAlready) {
          console.log("going fullscreen")
          hasTriggeredFullscreenAlready = true
          document.body.requestFullscreen()
        }
      }

      const events = ['click', 'keydown']

      events.forEach(it => document.addEventListener(it, listener))
      return () => {
        events.forEach(it => document.removeEventListener(it, listener))
      }
    }
  }, [fullscreenOnFirstInteraction])
  return null
}

let onHeaderClicked: () => any = null;
export const useCustomHeaderNavigation = (handler: () => any) => {
  useEffect(() => {
    onHeaderClicked = handler;
    return () => {
      onHeaderClicked = null;
    };
  }, [handler]);
};
const ApplicationHeader: FC<{ availableApps: IApp[] }> = ({ availableApps }) => {
  const location = useLocation();
  const history = useHistory();
  const routeNames = useMemo(() => getRouteNamesFromFlatMenu(availableApps), [availableApps]);

  const getCurrentAppName = useCallback(() => {
    const enhance = (toDashboard: boolean, s: ReactNode) => {
      const defaultHeaderClickHandler = () => (toDashboard ? history.push('/dashboard') : history.goBack());
      return (
        <>
          <u
            id="current-app-name"
            data-testid="current-app-name"
            onClick={() => {
              const handler = onHeaderClicked || defaultHeaderClickHandler;
              handler();
            }}
          >
            {s}
          </u>{' '}
          <span id="titleExtraInformation"></span>
        </>
      );
    };

    const appName = findAppName(location.pathname, routeNames);
    if (appName) {
      const [perfectMatch, name] = appName;
      return enhance(perfectMatch, <Translation name={name.title} />);
    } else {
      return enhance(true, <Translation name="T.errors.unknownAppName" />);
    }
  }, [history, location.pathname, routeNames]);

  return getCurrentAppName();
};

const MediaLibraryBox = React.forwardRef<PictureBoxRef, {}>((_, ref) => {
  const api = useApi()
  const [group, setGroup] = useLocalStorage<string>('mediaLibraryCurrentEntry', '')
  const { data: groups, setData: setGroups } = useApiLoader(
    useCallback(async (api) => {
      const groups = (await api.fileManagement.getContents({ path: ['temp', 'albums'] }, false))
        .filter(it => it.isDirectory && !it.isHidden)
      return distinct([group, ...groups.map(it => it.name)])
    }, [group]),
    [],
    true
  )
  const { data: files, setData: setFiles, reload: reloadFiles } = useApiLoader(
    useCallback(async (api) => {
      if (!group) return []
      const files = (await api.fileManagement.getContents({ path: ['temp', 'albums', group] }, true))
        .filter(it => !it.isDirectory && !it.isHidden)
      return files.map(it => ({
        name: it.name,
        extension: it.name.split('.').pop().toLowerCase(),
        blob: it.contents as any
      }) as IAttachment)
    }, [group]),
    [],
    true
  )
  return (
    <PictureBox
      ref={ref}
      files={files}
      onOpen={reloadFiles}
      onAddFiles={async (files) => {
        for (const { blob, name, extension } of files) {
          if (!group) {
            throw addNotification('danger', <Translation name="T.misc.noGroupSelectedForLibrary"/>)
          }
          await api.fileManagement.uploadFile({
            path: ['temp', 'albums', group, name],
            contents: blob
          })
          setFiles(old => [...old, {
            name: name,
            extension: extension,
            blob: blob as any
          }])
        }
      }}
      onRemoveFile={(index) => {
        setFiles(old => {
          api.fileManagement.deleteObject({
            path: ['temp', 'albums', group, old[index].name],
          })  
          return old.filter(indexFilter([index]))
        })
      }}

      groupControls={{
        currentGroup: group,
        options: groups,
        setGroup,
        deleteGroup: async () => {
          await api.fileManagement.deleteObject({
            path: ['temp', 'albums', group],
          })
          setGroups(old => old.filter(itemFilter([group])))
          setGroup(null)
        }
      }}
    />
  );
});

export type SubApp = { Component: React.ComponentType, title: ReactNode }
const SubAppView: FC<{ 
  subApp: SubApp, 
  closeSubApp: any 
}> = ({ 
  subApp, closeSubApp 
}) => {
  const close = async () => {
    if (!await confirm(<Translation name="T.warnings.areYouSureYouWantToCloseTheSubApp"/>)) {
      return
    }
    closeSubApp()
  }
  return (
    <Modal
      onClick={(e) => {
        e.stopPropagation();
      }}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
      onMouseMove={(e) => {
        e.stopPropagation();
      }}
      isOpen={!!subApp}
      className={'custom-modal custom-modal-full-width flex-container'}
      data-prevent-auto-focus="true"
      toggle={close}
    >
      <ModalHeader toggle={close}>{subApp?.title} <NetworkState /></ModalHeader>

      <ModalBody
        className={'flex-fill-height custom-modal-body'}
      >
        <Suspense
          fallback={
            <div
              style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
              }}
            >
              <Spinner style={{ width: '4rem', height: '4rem' }} color="secondary" />
              <br />
              <Translation name="T.misc.loadingModule" />
            </div>
          }
        >
          {subApp && <subApp.Component/>}
        </Suspense>
      </ModalBody>
    </Modal>
  )
}

const AppRoot = () => {
  useWindowSize();
  const api = useApi();
  const deviceConfig = useDeviceConfig();
  const user = useAuth();
  const location = useLocation()
  const notifications = useNotifications();
  const [menuOpen, setMenuOpen] = useState<boolean>(true);
  const [subApp, setSubApp] = useState<SubApp>(null);
  const availableApps = useFlatMenu();
  const [routeNotifications, setRouteNotifications] = useState<{ [k: string]: number }>({});
  const errorLogRef = useRef<ModalRef>();
  const deviceIdentificationCodeRef = useRef<ModalRef>();
  const mediaLibraryBoxRef = useRef<PictureBoxRef>();

  const toggleMenu = useCallback(() => setMenuOpen((menuOpen) => !menuOpen), [setMenuOpen]);
  const logout = useCallback(() => api.logout(), [api]);
  const deviceSettings = useCallback(() => deviceConfig.openDeviceSettingsModal(), [deviceConfig]);
  const onMenuClick = useCallback(() => {
    toggleMenu();
  }, [toggleMenu]);

  const onMessageReceive = useCallback(
    (topic: any, msg: IWebSocketMessage<any>) => {
      if (msg.what === 'RELOAD') {
        api.reload();
      } else if (msg.what === 'ERROR') {
        notifications.add('danger', <Translation name={msg.payload.key} params={msg.payload.args} />);
      } else if (msg.what === 'WARNING') {
        notifications.add('warning', <Translation name={msg.payload.key} params={msg.payload.args} />);
      } else if (msg.what === 'INFO') {
        notifications.add('info', <Translation name={msg.payload.key} params={msg.payload.args} />);
      } else if (msg.what === 'SUCCESS') {
        notifications.add('success', <Translation name={msg.payload.key} params={msg.payload.args} />);
      } else if (msg.what === 'ALERT') {
        if (msg.payload.withSound) {
          const audio = new Audio(bellSfx);
          audio.play();
        }
        alert(<Raw>{msg.payload.text}</Raw>)
      } else if (msg.what === 'NOTIFICATION_COUNTERS') {
        setRouteNotifications(msg.payload);
      } else if (msg.what === 'BROADCAST') {
        if (msg.payload.targetAll || msg.payload.targets.includes(''+user.userId)) {
          const audio = new Audio(bellSfx);
          audio.play();
          notifications.add(
            'info', 
            <Raw>{msg.payload.title}</Raw>, 
            <Raw>{msg.payload.message}</Raw>, 
            { centered: true, persistent: true }
          );
        }
      } else if (msg.what === 'SERVER_RESTARTING') {
        const audio = new Audio(bellSfx);
        audio.play();
        notifications.add(
          'info', 
          <Translation name={msg.payload.title}/>, 
          <Translation name={msg.payload.message}/>, 
          { centered: true, persistent: true }
        );
      } else if (msg.what === 'REQUEST_SESSION_TAKEOVER') {
        const otherDeviceName = msg.payload.deviceName
        const doTakeOver = async () => {
          if (await confirm(<Translation name="T.warnings.session.areYouSureYouWantToContinueOnDevice" params={{ deviceName: <b>{otherDeviceName}</b> }}/>)) {
            await api.post(`/api/v1/auth/transfer_token/create/${otherDeviceName}`, {
              path: location.pathname
            })
          }  
        }
        doTakeOver()
      }
    },
    [user.userId, api, notifications, setRouteNotifications, location.pathname],
  );

  const menuToggleKeyAbstract = user.userSettings.menuToggleShortcutKey;
  useEffect(() => {
    let sx: number, sy: number, st: Date;

    const touchStart = (e: any) => {
      sx = e.touches[0].clientX;
      sy = e.touches[0].clientY;
      st = new Date();
    };
    const touchEnd = (e: any) => {
      const ex = e.changedTouches[0].clientX;
      const ey = e.changedTouches[0].clientY;
      const dx = ex - sx,
        dy = ey - sy;
      const totalWidth = window.screen.width;
      const seconds = (new Date().getTime() - st.getTime()) / 1000;

      if (seconds > 0.2) {
        return;
      }

      if (Math.abs(dx) > Math.abs(dy) && Math.abs(dx) > totalWidth / 3) {
        if (dx > 0 && sx < 100) {
          setMenuOpen(true);
        } else if (dx < 0 && sx > window.screen.width - 100) {
          setMenuOpen(false);
        }
      }
    };

    const onMouseDown = (e: MouseEvent) => {
      if (
        !document.getElementById('be-main-menu-sidebar').contains(e.target as any) &&
        !document.getElementById('be-main-menu-sidebar-toggler').contains(e.target as any)
      ) {
        setMenuOpen(false);
      }
    };

    const keyListener = (e: KeyboardEvent) => {
      if (isEqual(menuToggleKeyAbstract, getKeyboardEventAbstract(e as any)) && !e.repeat) {
        toggleMenu();
      }
    };

    document.addEventListener('touchstart', touchStart, false);
    document.addEventListener('touchend', touchEnd, false);
    document.addEventListener('mousedown', onMouseDown, false);
    document.addEventListener('keydown', keyListener);
    return () => {
      document.removeEventListener('touchstart', touchStart);
      document.removeEventListener('touchend', touchEnd);
      document.removeEventListener('mousedown', onMouseDown);
      document.removeEventListener('keydown', keyListener);
    };
  }, [toggleMenu, menuToggleKeyAbstract]);

  const topics = [
    '/user/queue/errors',
    '/user/queue/commands',
    '/topic/errors',
    '/topic/commands',
    '/topic/notifications',
    '/topic/broadcasts',
    '/topic/sessions/' + sessionID,
  ]

  if (deviceConfig.deviceConfig.deviceName) {
    topics.push(`/device_listener/${deviceConfig.deviceConfig.deviceName}`)
  }

  const showDeviceIdKeyListener = useCallback((e: KeyboardEvent) => e.shiftKey && e.altKey && e.code === 'KeyI', []);

  return (
    <div className="app">
      {deviceConfig.deviceConfig.deviceName && (
        <SimpleModal 
          ref={deviceIdentificationCodeRef} 
          title={<Translation name="T.menu.deviceIdentification" />} 
          onKeyListener={showDeviceIdKeyListener}
        >
          <div className="flex-container text-center">
            <QRCode
              size={256}
              className='mx-auto'
              style={{ height: "auto", maxWidth: "100%", width: "5cm" }}
              value={'_be_device_identification_' + deviceConfig.deviceConfig.deviceName}
              viewBox={`0 0 256 256`}          
            />
            <h3>
              {deviceConfig.deviceConfig.deviceName}
            </h3>
          </div>
        </SimpleModal>
      )}
      <SubAppView subApp={subApp} closeSubApp={() => setSubApp(null)}/>
      <SessionViewerListener/>
      <FirstInteractionFullScreenListener/>
      <ErrorHistoryOverview ref={errorLogRef} />
      <MediaLibraryBox ref={mediaLibraryBoxRef}/>
      <OnRuntimeType type="TEST">
        <WatermarkOverlay
          xOffset={'1em'}
          yOffset={'0.5em'}
          style={{
            fontSize: '4em',
          }}
        >
          <i>
            <Translation name="T.misc.test" />
          </i>
        </WatermarkOverlay>
      </OnRuntimeType>
      <Socket
        topics={topics}
        onMessage={onMessageReceive}
      />

      <Navbar color="light" expand="md">
        <NavbarBrand onClick={toggleMenu} id="be-main-menu-sidebar-toggler">
          <img src={logo} className="brandLogo" alt="BeEfficient logo" />
          <OnRuntimeType type="TEST">
            {' '}
            <Badge
              color="danger"
              style={{
                fontSize: '1.0em',
              }}
            >
              <Translation name="T.misc.test" />
            </Badge>
          </OnRuntimeType>
        </NavbarBrand>
        <NavbarText style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', textAlign: 'center' }}>
          <ApplicationHeader availableApps={availableApps} />
        </NavbarText>

        <Nav className="ml-auto" navbar>
          <UncontrolledDropdown nav>
            <DropdownToggle nav data-testid="userSettingsDropDownToggle">
              <NetworkState />
              <TimerState />
              <DropDownMenuToggle />
            </DropdownToggle>
            <DropdownMenu right className="userMenu">
              <DropdownItem data-testid="nav-username" toggle={false}>
                {user ? user.username : <Translation name="T.misc.notLoggedIn" />}
              </DropdownItem>
              <DropdownItem tag={RRNavLink} to={'/user/settings'} data-testid="userSettingsLink">
                <RiUserSettingsFill/> <Translation name="T.menu.userSettings" />
              </DropdownItem>
              <DropdownItem onClick={deviceSettings}>
                <MdOutlineAppSettingsAlt/> <Translation name="T.menu.deviceSettings" />
              </DropdownItem>
              <DropdownItem onClick={openPeripheralManager}>
                <BsUsbPlugFill/> <Translation name="T.menu.peripherals" />
              </DropdownItem>
              <DropdownItem onClick={logout}>
                <BiSolidLogOut/> <Translation name="T.menu.logout" />
              </DropdownItem>
              <DropdownItem onClick={api.reload}>
                <IoReload/> <Translation name="T.menu.reload" />
              </DropdownItem>
              <DropdownItem onClick={() => errorLogRef.current.toggleOpen()}>
                <MdError/> <Translation name="T.menu.errorHistory" />
              </DropdownItem>
              {deviceConfig.deviceConfig.deviceName && (
                <DropdownItem onClick={() => deviceIdentificationCodeRef.current.toggleOpen()}>
                  <FaQrcode/> <Translation name="T.menu.deviceIdentification" />
                </DropdownItem>
              )}
              <DropdownItem onClick={() => {
                if (document.fullscreenElement) {
                  document.exitFullscreen();
                } else {
                  document.body.requestFullscreen()
                }
              }}>
                {document.fullscreenElement ? (
                  <>
                    <MdCloseFullscreen/> <Translation name="T.menu.forceExitFullScreen"/>
                  </>
                 ) : (
                  <>
                    <BsArrowsFullscreen/> <Translation name="T.menu.forceFullScreen"/> 
                  </>
                )}
              </DropdownItem>
              <TranslationEditorToggle />
              <DropdownItem onClick={() => mediaLibraryBoxRef.current.open()}>
                <MdPermMedia/> <Translation name="T.menu.mediaLibrary" />
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Navbar>

      <div
        className={classNames('menu-content-wrapper', {
          'is-open': menuOpen,
        })}
      >
        <Menu onMenuClick={onMenuClick} onOpenAsSubApp={(app: SubApp) => setSubApp(app)} routeNotifications={routeNotifications} />

        <div className="content" id="application-content">
          <Suspense
            fallback={
              <div
                style={{
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)',
                  textAlign: 'center',
                }}
              >
                <Spinner style={{ width: '4rem', height: '4rem' }} color="secondary" />
                <br />
                <Translation name="T.misc.loadingModule" />
              </div>
            }
          >
            <Switch>
              {availableApps
                .map((app) => {
                  return [
                    <Route key={app.path} exact path={app.path} component={app.component} />,
                    ...Object.keys(app.subComponents).map((subPath) => {
                      const component = app.subComponents[subPath];
                      const path = app.path + subPath;
                      return <Route key={path} exact path={path} component={component} />;
                    }),
                  ];
                })
                .flat()}

              <Redirect to="/dashboard" />
            </Switch>
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default AppRoot;
