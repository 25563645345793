import {IModel} from './external-models';
import {IBinLocation} from './models-binLocation';
import {IBatch, IItem, ISerialNumber} from './models-item';
import {IPickListHeaderProgress, IPickListRowProgress} from './picking-entities';

export type BaseObject = 'SALES_ORDER' | 'INVOICE' | 'PROD_ORDR' | 'STOCK_TRANSFER' | 'UNKNOWN';
export const BaseObjectValues: BaseObject[] = ['SALES_ORDER' , 'INVOICE' , 'PROD_ORDR' , 'STOCK_TRANSFER' , 'UNKNOWN'];

export interface IBrokenPicklistDescriptor extends  IModel{
  erpId: IPickListHeaderId;
  message: string;
}

export interface IPickListHeader extends  IModel{
  date: any;
  description: string;
  erpId: IPickListHeaderId;
  hasPriority: boolean;
  id: number;
  kardexDate: any;
  printDate: any;
  progress: IPickListHeaderProgress;
  status: Status;
  targetCode: string;
  userCode: string;
}

export interface IPickListHeaderId extends  IModel{
  code: string;
  type: PickListType;
}

export interface IPickListRow extends  IModel{
  baseDocumentCode: string;
  baseDocumentLineNum: number;
  baseDocumentVisualCode: string;
  baseObject: BaseObject;
  batch: IBatch;
  batchSerialId: string;
  binCode: string;
  erpId: IPickListRowId;
  id: number;
  item: IItem;
  itemCode: string;
  location: IBinLocation;
  picked: number;
  progress: IPickListRowProgress;
  released: number;
  serialNumber: ISerialNumber;
  status: Status;
}

export interface IPickListRowId extends  IModel{
  allocationIndex: number;
  code: string;
  lineNumber: number;
  type: PickListType;
}

export type PickListType = 'PICK_LIST';
export const PickListTypeValues: PickListType[] = ['PICK_LIST'];

export type Status = 'RELEASED' | 'PARTLY_PICKED' | 'PICKED' | 'CLOSED' | 'UNKNOWN';
export const StatusValues: Status[] = ['RELEASED' , 'PARTLY_PICKED' , 'PICKED' , 'CLOSED' , 'UNKNOWN'];

