import classNames from 'classnames';
import React from 'react';

import './style.scss';

interface Props {
  isExpanded: boolean;
  disabled?: boolean;
  onClick?: () => any;
}

export default ({ isExpanded, disabled, onClick }: Props) => {
  return (
    <div
      onClick={onClick}
      className={classNames('be-expander', !disabled && isExpanded && '-open', disabled && '-disabled')}
      data-testid="Expander"
      data-test-is-expanded={isExpanded ? 'true' : 'false'}
    >
      &bull;
    </div>
  );
};
