import React from 'react';
import { IBatch, IBinLocation, IBusinessPartner, IItem, ISerialNumber } from 'interfaces';
import { INotificationContext } from 'utils/NotificationManager';
import Translation from 'utils/Language/Translation';
import Location from 'components/objects/location';
import Item from 'components/objects/item';

const padSize = 64

export function matchBusinessPartner(bp: IBusinessPartner, _input: string) {
  if (!_input) {
    return false;
  }
  const input = _input.toUpperCase();
  return bp.code.toUpperCase().padStart(padSize, '0') === input.padStart(padSize, '0') || bp.visualCode.toUpperCase() === input;
}

export function matchBatch(b: IBatch, _input: string) {
  if (!_input) {
    return false;
  }
  const input = _input.toUpperCase();
  return b.code.toUpperCase().padStart(padSize, '0') === input.padStart(padSize, '0') || b.visualCode.toUpperCase() === input;
}

export function matchSerial(s: ISerialNumber, _input: string) {
  if (!_input) {
    return false;
  }
  const input = _input.toUpperCase();
  return s.code.toUpperCase().padStart(padSize, '0') === input.padStart(padSize, '0') || s.visualCode.toUpperCase() === input;
}

export function matchItem(item: IItem, _input: string) {
  if (!_input) {
    return false;
  }
  const input = _input.toUpperCase();
  return (
    item.code === input ||
    item.visualCode.toUpperCase().padStart(padSize, '0') === input.padStart(padSize, '0') ||
    item.barcode.toUpperCase().padStart(padSize, '0') === input.padStart(padSize, '0') ||
    item.barcode2.toUpperCase().padStart(padSize, '0') === input.padStart(padSize, '0') ||
    item.barcode3.toUpperCase().padStart(padSize, '0') === input.padStart(padSize, '0')
  );
}

export function matchLocation(location: IBinLocation, _input: string) {
  if (!_input) {
    return false;
  }
  const input = _input.toUpperCase();
  return (
    location.code.toUpperCase() === input ||
    location.visualCode.toUpperCase() === input ||
    location.barcode.toUpperCase().padStart(padSize, '0') === input.padStart(padSize, '0')
  );
}

export function simpleAutocompleteOptions(l: string[], _input: string) {
  const input = _input.toUpperCase();
  return l
    .filter((it) => it.toUpperCase().includes(input))
    .map((it) => ({
      label: it,
      value: it,
    }))
    .slice(0, 20);
}

export function itemAutocompleteOptions(items: IItem[], _input: string) {
  const input = _input.toUpperCase();
  return items
    .filter(
      (it) =>
        (it.visualCode || '').toUpperCase().includes(input) ||
        (it.barcode || '').toUpperCase().includes(input) ||
        (it.barcode2 || '').toUpperCase().includes(input) ||
        (it.barcode3 || '').toUpperCase().includes(input) ||
        (it.name || '').toUpperCase().includes(input),
    )
    .map((it) => ({
      label: `${it.visualCode} - ${it.name}`,
      value: it.code,
    }))
    .slice(0, 20);
}

export function batchAutocompleteOptions(batches: IBatch[], _input: string) {
  const input = _input.toUpperCase();
  return batches
    .filter((it) => (it.visualCode || '').toUpperCase().includes(input))
    .map((it) => ({
      label: `${it.visualCode}`,
      value: it.code,
    }))
    .slice(0, 20);
}

export function serialAutocompleteOptions(serials: ISerialNumber[], _input: string) {
  const input = _input.toUpperCase();
  return serials
    .filter((it) => (it.visualCode || '').toUpperCase().includes(input))
    .map((it) => ({
      label: `${it.lotCode} - ${it.visualCode}`,
      value: it.code,
    }))
    .slice(0, 20);
}

export function locationAutocompleteOptions(locations: IBinLocation[], _input: string) {
  const input = _input.toUpperCase();
  return locations
    .filter(
      (it) =>
        (it.visualCode || '').toUpperCase().includes(input) ||
        (it.barcode || '').toUpperCase().includes(input)
    )
    .map((it) => ({
      label: `${it.visualCode}`,
      value: it.code,
    }))
    .slice(0, 20);
}


export function ynToBool(val: string) {
  return val === 'Y';
}

export function stockTransferNotification(
  notifications: INotificationContext,
  quantity: number,
  item: IItem,
  from: IBinLocation,
  to: IBinLocation,
) {
  notifications.add(
    'success',
    <Translation name="T.misc.success" />,
    <Translation
      name="T.misc.successfullyMoved"
      params={{
        quantity,
        item: <Item inline obj={item} />,
        from: <Location inline obj={from} />,
        to: <Location inline obj={to} />,
      }}
    />,
  );
}

export function locationTransferNotification(
  notifications: INotificationContext,
  quantity: number,
  from: IBinLocation,
  to: IBinLocation,
) {
  notifications.add(
    'success',
    <Translation name="T.misc.success" />,
    <Translation
      name="T.misc.successfullyMovedLocation"
      params={{
        quantity,
        from: <Location inline obj={from} />,
        to: <Location inline obj={to} />,
      }}
    />,
  );
}
