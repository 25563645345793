type SetBusy = (busy: boolean) => void;
type BufferedExecutorMethod = () => Promise<any>;
type BufferedExecutorEntry = {
  fun: BufferedExecutorMethod;
  resolve: (value: any) => void;
  reject: (value: any) => void;
};

export default class {
  private setBusy: SetBusy;
  isProcessingCommands: boolean;
  commands: Array<BufferedExecutorEntry>;

  constructor(setBusy: SetBusy = () => {}) {
    this.isProcessingCommands = false;
    this.commands = [];
    this.setBusy = setBusy;
  }

  private _doExecute = async () => {
    if (!this.isProcessingCommands) {
      this.isProcessingCommands = true;
      this.setBusy(true);
      while (this.commands.length > 0) {
        // get the next command
        const cmd = this.commands.shift();
        try {
          const res = await cmd.fun();
          cmd.resolve(res);
        } catch (err) {
          // the previous command threw an exception, ignore the next commands
          this.clearQueue();
          console.error(err);
        }
      }
      this.isProcessingCommands = false;
      this.setBusy(false);
    }
  };

  clearQueue = async () => {
    this.commands.forEach((it) => it.reject('Queue cleared'));
    this.commands = [];
    await this.execute(async () => {});
  };

  executeAwaitable = (cmd: BufferedExecutorMethod) => {
    if (!cmd) {
      return Promise.reject('No promise supplied');
    }
    return new Promise((resolve, reject) => {
      this.commands.push({
        fun: cmd,
        resolve,
        reject,
      });
      this._doExecute();
    });
  };

  execute = (cmd: BufferedExecutorMethod, onProcessed = (value: any) => {}, onCleared = onProcessed) => {
    if (!cmd) {
      return console.error('No promise supplied');
    }
    this.commands.push({
      fun: cmd,
      resolve: onProcessed,
      reject: onCleared,
    });
    this._doExecute();
  };

  executeFirst = (cmd: BufferedExecutorMethod, onProcessed = (value: any) => {}, onCleared = onProcessed) => {
    if (!cmd) {
      return console.error('No promise supplied');
    }
    this.commands.unshift({
      fun: cmd,
      resolve: onProcessed,
      reject: onCleared,
    });
    this._doExecute();
  };
}
