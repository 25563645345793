import {
  ISystemMetrics,
  IWhsPerformanceUserDefinition,
  IWhsPerformanceUserWidget,
  IWhsPerformanceUserWidgetSummary,
} from 'interfaces';
import { Registry } from '.';

export default ({ get, post, destroy }: Registry) => {
  return {
    performance: {
      system: (): Promise<ISystemMetrics> => get('/api/v1/performance/system'),
      getWidgets: (): Promise<IWhsPerformanceUserWidgetSummary[]> => get('/api/v1/performance/user_widgets'),
      getWidgetResults: (id: number): Promise<any[]> => get(`/api/v1/performance/user_widget/perform/${id}`),

      admin: {
        add: (def: IWhsPerformanceUserDefinition): Promise<void> => post('/api/v1/performance/admin/user_widget', def),
        update: (id: number, def: IWhsPerformanceUserDefinition): Promise<void> =>
          post(`/api/v1/performance/admin/user_widget/${id}`, def),
        test: (query: string, params: any): Promise<any[]> => 
          post('/api/v1/performance/admin/test_user_widget', { query, params }),
        remove: (id: number): Promise<void> => destroy(`/api/v1/performance/admin/user_widget/${id}`),
        getWidget: (id: number): Promise<IWhsPerformanceUserWidget> =>
          get(`/api/v1/performance/admin/user_widget/${id}`),
      },
    },
  };
};
