import moment from 'moment';
import React from 'react';
import * as Reactstrap from 'reactstrap';
const parser = require("gs1-barcode-parser-mod")

export default () => {
  const w = window as any;
  w.moment = moment
  w.React = React;
  w.Reactstrap = Reactstrap;
  w.GS1BarcodeParser = parser;
};
