import React, { Component, ReactNode, useContext } from 'react';
import SockJsClient from './SocketUtils/SockJsClient';
import difference from 'lodash/difference';
import omit from 'lodash/omit';
import get from 'lodash/get';
import { useConfigVersion } from 'utils/Config';
import { IApi, useApi, useAuth } from 'utils/API';
import { MaybePromise, RHooksHoc } from 'component_utils/utils';
import { IAuthenticatedUser, IWebSocketMessage } from 'interfaces';
import { sessionID } from 'statics/session_identification';

interface ISocketContext {
  subscribe: any;
  subscriptionsUpdate: any;
  unsubscribe: any;
  sendMessage: (
    topic: string,
    msg: IWebSocketMessage<any>,
    callback?: (response: IWebSocketMessage<any>) => MaybePromise<any>,
  ) => any;
  connected: boolean;
}

interface ISocketSubcriberProps {
  topics: Array<string>;
  socket: ISocketContext;
  onMessage: (topic: string, msg: any) => void;
  onConnect?: () => void;
  onDisconnect?: () => void;
  onCouldNotConnect?: () => void;
  onSubscribed?: (topic: string) => void;
}
type IPublicSocketSubscriberProps = Omit<ISocketSubcriberProps, 'socket'>;

interface ISocketManagerState {
  topicSubscriptions: { [x: string]: any };
  subscribers: Array<any>;
  connected: boolean;
}

const SocketContext = React.createContext<ISocketContext>({} as ISocketContext);

export function useWebsocket() {
  const ctx = useContext(SocketContext);
  return ctx.sendMessage;
}

export function useWebsocketIsConnected() {
  const ctx = useContext(SocketContext);
  return ctx.connected;
}

export class SocketSubscriber extends Component<ISocketSubcriberProps, {}> {
  constructor(props: ISocketSubcriberProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.socket.subscribe(this, this.props.topics);
  }

  componentDidUpdate(prevProps: ISocketSubcriberProps) {
    // the subscribed topics changed, request an update from the manager
    const newTopics = difference(this.props.topics, prevProps.topics);
    const removedTopics = difference(prevProps.topics, this.props.topics);
    if (newTopics.length > 0 || removedTopics.length > 0) {
      this.props.socket.subscriptionsUpdate(this, newTopics, removedTopics);
    }
  }

  componentWillUnmount() {
    this.props.socket.unsubscribe(this);
  }

  onMessage = (topic: string, msg: any) => {
    if (this.props.onMessage) {
      this.props.onMessage(topic, msg);
    }
  };

  onConnect = () => {
    if (this.props.onConnect) {
      this.props.onConnect();
    }
  };

  onCouldNotConnect = () => {
    if (this.props.onCouldNotConnect) {
      this.props.onCouldNotConnect();
    }
  };

  onDisconnect = () => {
    if (this.props.onDisconnect) {
      this.props.onDisconnect();
    }
  };

  onSubscribed = (topic: string) => {
    if (this.props.onSubscribed) {
      this.props.onSubscribed(topic);
    }
  };

  sendMessage = function <T>(topic: string, msg: IWebSocketMessage<T>) {
    this.props.socket.sendMessage(topic, msg);
  };

  render(): ReactNode {
    return null;
  }
}

export const Socket = React.forwardRef<SocketSubscriber, IPublicSocketSubscriberProps>((props, ref) => {
  return (
    <SocketContext.Consumer>
      {(state) => <SocketSubscriber {...props} ref={ref} socket={state} />}
    </SocketContext.Consumer>
  );
});

export const SocketManager = RHooksHoc(
  () => {
    return {
      api: useApi(),
      user: useAuth(),
      appVersion: useConfigVersion(),
    };
  },
  class extends Component<{ api: IApi; user: IAuthenticatedUser; appVersion: string }, ISocketManagerState> {
    clientRef: any;

    constructor(props: any) {
      super(props);
      this.state = {
        topicSubscriptions: {},
        subscribers: [],
        connected: false,
      };
    }

    subscribe = (subscriber: any, topics: Array<string>) => {
      // make sure this is a new subscriber and
      // none of the topics are already subscribed to
      if (this.state.subscribers.includes(subscriber)) throw new Error(`Subscriber has already subscribed`);
      topics.forEach((topic) => {
        if (this.state.topicSubscriptions[topic]) {
          throw new Error(`Double subscription for topic ${topic}`);
        }
      });

      // store the new subscriber
      const subscriptionMap: { [x: string]: any } = {};
      topics.forEach((topic) => {
        subscriptionMap[topic] = subscriber;
      });

      this.setState(
        (old) => ({
          topicSubscriptions: { ...old.topicSubscriptions, ...subscriptionMap },
          subscribers: [...old.subscribers, subscriber],
        }),
        () => {
          // if the socket is already connected call the connect method
          // on the subscriber to act as if it is a private
          // socket that was only just created
          if (this.state.connected) {
            subscriber.onConnect();
          }
        },
      );
    };

    unsubscribe = (subscriber: any) => {
      const subscriberTopics = this.getTopicsForSubscriber(subscriber);
      this.setState((old) => ({
        topicSubscriptions: omit(old.topicSubscriptions, subscriberTopics),
        subscribers: old.subscribers.filter((value) => value !== subscriber),
      }));
    };

    subscriptionsUpdate = (subscriber: any, newTopics: string[], removedTopics: string[]) => {
      newTopics.forEach((topic) => {
        if (this.state.topicSubscriptions[topic]) {
          throw new Error(`Double subscription for topic ${topic}`);
        }
      });

      // store the new subscriber
      const subscriptionMap: { [x: string]: any } = {};
      newTopics.forEach((topic) => {
        subscriptionMap[topic] = subscriber;
      });

      // next we unsubscribe from old topics and subscribe to the new topics
      this.setState((old) => ({
        topicSubscriptions: omit({ ...old.topicSubscriptions, ...subscriptionMap }, removedTopics),
      }));
    };

    getTopicsForSubscriber = (subscriber: any) => {
      return Object.keys(this.state.topicSubscriptions).reduce((total, topic) => {
        if (this.state.topicSubscriptions[topic] === subscriber) {
          total.push(topic);
        }
        return total;
      }, []);
    };

    getAllTopics = () => {
      return Object.keys(this.state.topicSubscriptions);
    };

    getAllSubscribed = () => {
      return this.state.subscribers;
    };

    sendMessage = (topic: string, msg: any) => {
      this.clientRef.sendMessage(topic, JSON.stringify(msg));
    };

    onMessage = (msg: any, topic: string) => {
      this.state.topicSubscriptions[topic].onMessage(topic, msg);
    };

    onSubscribed = (topic: string) => {
      this.state.topicSubscriptions[topic].onSubscribed(topic);
    };

    onConnect = () => {
      this.state.subscribers.forEach((subscriber) => {
        subscriber.onConnect();
      });

      this.setState({
        connected: true,
      });
    };

    onCouldNotConnect = (error: any | null) => {
      const errorMessage = get(error || {}, 'headers.message', '');
      if (
        errorMessage.includes('InvalidTokenAuthenticationException') ||
        errorMessage.includes('UsernameNotFoundException') ||
        errorMessage.includes('AccessDeniedException')
      ) {
        console.log('User logged out');
        this.props.api.logout();
      }

      if (errorMessage.includes('OldVersionException')) {
        window.location.reload();
      }

      this.state.subscribers.forEach((subscriber) => {
        subscriber.onCouldNotConnect();
      });
    };

    onDisconnect = () => {
      this.state.subscribers.forEach((subscriber) => {
        subscriber.onDisconnect();
      });

      this.setState({
        connected: false,
      });
    };

    render() {
      const { children } = this.props;

      // when testing/developing it might run in the yarn dev server.
      // this means that we need to go to localhost:8080 instead of localhost:3000
      // otherwise we just use the port of the actual application
      let wsSourceUrl =
        process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
          ? 'wss://' + window.location.hostname + ':8080/api/v1/ws'
          : 'wss://' + window.location.host + '/api/v1/ws';

      return (
        <SocketContext.Provider
          value={{
            subscribe: this.subscribe,
            subscriptionsUpdate: this.subscriptionsUpdate,
            unsubscribe: this.unsubscribe,
            sendMessage: this.sendMessage,
            connected: this.state.connected,
          }}
        >
          <SockJsClient
            url={wsSourceUrl}
            subscribeHeaders={null}
            debug={false}
            headers={{
              AppVersion: this.props.appVersion,
              UniqueSession: sessionID
            }}
            onMessage={this.onMessage}
            onConnect={this.onConnect}
            onDisconnect={this.onDisconnect}
            onConnectFailure={this.onCouldNotConnect}
            onSubscribed={this.onSubscribed}
            topics={this.getAllTopics()}
            ref={(client: any) => {
              this.clientRef = client;
            }}
          />
          {children}
        </SocketContext.Provider>
      );
    }
  },
);
