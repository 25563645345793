import { IIBusinessPartnerDao$IRestApiClientConfiguration, IRestApiSettings, IScriptedRestApiEndpoint, ISimpleRestApiEndpointDto } from 'interfaces';
import { IRestApiClientAccess } from 'interfaces/models-businessPartner';
import { Registry } from '.';

export default ({ get, post, destroy }: Registry) => {
  return {
    restApi: {
      certificate: {
        fowardCertificate: () => post('/api/v1/rest_api/certificates/forward/', null),
        storeCertificate: (cert: File, config: any) => {
          const formData = new FormData();
          formData.append('cert', cert);
          formData.append('config', new Blob([JSON.stringify(config)], { type: 'application/json' }));
          return post('/api/v1/rest_api/certificates/load/', formData);
        },  
      },
      settings: {
        get: (): Promise<IRestApiSettings> => get('/api/v1/rest_api/settings/'),
        save: (settings: IRestApiSettings): Promise<void> => post('/api/v1/rest_api/settings/', settings),
      },
      clients: {
        get: (): Promise<IRestApiClientAccess[]> => get('/api/v1/rest_api/access/'),
        add: (
          cardcode: string,
          config: IIBusinessPartnerDao$IRestApiClientConfiguration,
        ): Promise<IRestApiClientAccess[]> => post(`/api/v1/rest_api/access/${cardcode}`, config),
        revoke: (cardcode: string): Promise<void> => destroy(`/api/v1/rest_api/access/${cardcode}`),
      },
      endpoints: {
        getAll: (): Promise<ISimpleRestApiEndpointDto[]> => get('/api/v1/rest_api/endpoints'),
        scripting: {
          getAll: (): Promise<IScriptedRestApiEndpoint[]> => get('/api/v1/rest_api/scripts'),
          save: (script: IScriptedRestApiEndpoint) => post('/api/v1/rest_api/scripts', script),
          delete: (id: number) => destroy(`/api/v1/rest_api/scripts/${id}`),
        }  
      }
    },
  };
};
